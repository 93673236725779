import { type ReactNode } from 'react'

import logo from '@fv/client-assets/freightview.svg'

type SharedShipmentHeaderProps = {
  children: ReactNode
}

const SharedShipmentHeader = ({ children }: SharedShipmentHeaderProps) => {
  return (
    <div className="px-6 py-4 border-fv-gray border-b justify-center bg-fv-blue-50 col-span-full flex items-center">
      <img src={logo} alt="Freightview" />
      <span className="mb-0">
        <p className="mb-0 ml-2">{children}</p>
      </span>
    </div>
  )
}

export default SharedShipmentHeader
