import clsx from 'clsx'
import { useState } from 'react'

import {
  FvButton,
  FvLinkButton,
  useFvNavigate,
  ValidatedForm,
} from '@fv/client-components'
import { type ListShipment, type PropsWithClassName } from '@fv/client-types'

import { RadioField } from '../../components/inputs/RadioField'
import { type QuoteRequestResponse } from '../../hooks/shipments/useQuoteRequestResponses'
import {
  type AwardChangeReason,
  useRetenderLoadToNewCarrier,
} from '../../hooks/shipments/useRetenderLoad'
import { routes } from '../../routes'
import {
  ListSubActions,
  ListSubContainer,
  ListSubHeader,
} from './ListSubContainer'

type CarrierSelectFormProps = PropsWithClassName<{
  load: ListShipment
  quote: QuoteRequestResponse
  onCancel: () => void
  retender: boolean
}>

export const CarrierSelectForm = ({
  load,
  onCancel,
  quote,
}: CarrierSelectFormProps) => {
  const [awardChangeReason, setAwardChangeReason] =
    useState<AwardChangeReason | null>(null)

  const navigate = useFvNavigate()
  const { status, items, loadId, quoteRequestId, selectedQuote, workflow } =
    load
  const { id: quoteId, carrierId } = quote

  const isLoadConfirmed = status === 'confirmed'
  const isLoadAwarded = status === 'awarded'
  const isRetender =
    (isLoadAwarded || isLoadConfirmed) && quote.id !== selectedQuote?._id

  const retender = useRetenderLoadToNewCarrier({
    hasBOLDetails: items.length > 0,
    loadId,
    quoteRequestId,
  })

  function awardLoad() {
    if (isRetender) {
      retender.mutate({
        carrierId,
        quoteId,
        reason: awardChangeReason,
      })

      return
    }

    if (workflow !== 'truckload') {
      navigate(
        routes.book({
          loadId,
          quoteId,
        }),
      )

      return
    }

    navigate(
      routes.bookTruckload({
        quoteRequestId,
        selectedQuotes: { [loadId]: quoteId },
      }),
    )
  }

  return (
    <ListSubContainer>
      <ValidatedForm onValidSubmit={() => awardLoad()}>
        {isRetender && (
          <div className="flex flex-col">
            <ListSubHeader>
              I want to change my award to this carrier because
            </ListSubHeader>

            <RadioField
              name={'withdraw-reason'}
              orientation="vertical"
              onChange={e => setAwardChangeReason(e.target.value)}
              options={[
                {
                  label:
                    'The currently awarded carrier does not have capacity.',
                  value: 'carrier-withdraw',
                },
                {
                  label: 'I prefer this carrier and rate for this load.',
                  value: 'shipper-retract',
                },
              ]}
            />
          </div>
        )}

        <ListSubActions
          justify="start"
          className={clsx('flex gap-x-2', isRetender && 'mt-3')}
        >
          {isRetender ? (
            <FvButton
              icon="trophy"
              transform="up-1"
              loading={retender.isLoading}
              type="submit"
              theme="primary"
              disabled={retender.requireReason(load) && !awardChangeReason}
            >
              Select new carrier
            </FvButton>
          ) : (
            <FvLinkButton
              theme="primary"
              icon="trophy"
              transform="up-1"
              to={routes.book({ loadId, quoteId })}
            >
              Select this carrier
            </FvLinkButton>
          )}
          <FvButton
            icon="times"
            onClick={onCancel}
            theme="default"
            className="ml-0"
          >
            Cancel
          </FvButton>
        </ListSubActions>
      </ValidatedForm>
    </ListSubContainer>
  )
}
