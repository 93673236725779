import { FvButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { MiniQuoteList } from '../../features/mini-quote-list/MiniQuoteList'

type QuoteHistoryPanelProps = {
  closePanel: () => void
  load: FullShipment
}

const QuoteHistoryPanel = ({ closePanel, load }: QuoteHistoryPanelProps) => {
  return (
    <div className="shipment-list-ancillary-details-ctn">
      <nav className="shipment-list-ancillary-details__nav divided-content divided-content--start">
        <FvButton icon="arrow-to-left" onClick={closePanel}>
          Back
        </FvButton>
        <div>
          <Icon icon="list" className="text-fv-gray-dark" />
          <span>Quote history</span>
        </div>
      </nav>
      <div className="shipment-list-ancillary-details overflow-auto">
        <MiniQuoteList
          filter="active"
          readOnly
          load={load}
          canViewMessages={false}
        />
      </div>
    </div>
  )
}

export default QuoteHistoryPanel
