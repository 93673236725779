import dayjs from 'dayjs'
import { type PropsWithChildren, useState } from 'react'
import { type LinkProps } from 'react-router-dom'

import {
  FvLinkButton,
  type IconProps,
  useFvNavigate,
} from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { usePermissions } from '../../features/auth'
import { useShipmentLabels } from '../../features/labels/hooks'
import { MissedPickupSlider } from '../../features/missed-pickup/MissedPickupSlider'
import { type ShipmentContentProps } from '../../features/shipment-details-panel/types'
import { useEditBookingPage } from '../../hooks/shipments'
import { useBolLinkState } from '../../hooks/shipments/useBolLinkState'
import { useRetractTender } from '../../hooks/shipments/useRetractTender'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { routes } from '../../routes'
import { canConfirmShipment } from '../../utils/shipmentFuncs'
import { List, ListItem } from '../List'
import { useCreateReturnShipment } from './useCreateReturnShipment'

type ActionProps = PropsWithChildren<{
  href?: string
  icon: IconProps['icon']
  onClick?: () => void
  to?: LinkProps['to']
}>

const Action = ({ children, href, icon, onClick, to }: ActionProps) => {
  return (
    <ListItem
      custom
      className="standard-list__action !border-fv-blue-200 first:border-t-0"
    >
      <FvLinkButton
        fw
        href={href ?? '#'}
        icon={icon}
        onClick={onClick}
        rel="noreferrer"
        target={href && '_blank'}
        to={to}
      >
        {children}
      </FvLinkButton>
    </ListItem>
  )
}

const DispatchedLoadAction = ({
  load,
  ...props
}: ActionProps & { load: FullShipment }) => {
  if (
    load.bol?.status !== 'requesting' &&
    load.pickup?.status !== 'requesting'
  ) {
    return <Action {...props} />
  }
  return null
}

export const ActionsPanel = ({
  load,
  setActiveSlider,
}: ShipmentContentProps) => {
  const navigate = useFvNavigate()
  const [reportMissedPickup, setReportMissedPickup] = useState(false)
  const { canBook } = usePermissions()
  const { canEdit, composeEditLink } = useEditBookingPage(load)
  const { pickup, pickupDate, status, workflow } = load
  const createReturnShipment = useCreateReturnShipment()
  const { carrierPublicSettings } = useCarrierPublicSettings(
    load.selectedQuote?.carrierId,
  )

  const retractTender = useRetractTender()
  const labels = useShipmentLabels(load)
  const isProcessingPickup = pickup?.status === 'requesting'
  const canReportMissedPickup =
    workflow !== 'truckload' && dayjs.utc(pickupDate).isBefore(dayjs(), 'day')
  const canCreateReturnShipment = workflow === 'ltl' && !load.isFreightCollect

  const canSelectNewRate = () => {
    if (load.isFreightCollect) {
      return false
    }

    if (load.workflow === 'parcel' && load.pickup?.status === 'ok') {
      return false
    }

    if (load.status === 'canceled') {
      return false
    }

    if (load.workflow === 'truckload') {
      return false
    }

    if (load.pickup?.method === 'internal') {
      return false
    }

    if (
      ['in-transit', 'delivered'].includes(load.tracking?.currentStatus ?? '')
    ) {
      return false
    }

    if (
      carrierPublicSettings?.allowChangeBOL === false &&
      load.pickup?.status === 'ok'
    ) {
      return false
    }

    return true
  }

  const {
    canFinishBol,
    carrierBolUrl,
    fvBolUrl,
    hideFvBolFromSpotQuote,
    isProcessing: isProcessingBol,
  } = useBolLinkState(load)

  return (
    <nav className="shipment-details-nav b1350:pt-2 b1350:row-start-3">
      <List fcp0>
        {canConfirmShipment(load) && canBook && (
          <Action
            icon={isProcessingPickup ? 'sync' : 'user'}
            onClick={() => setActiveSlider('confirm')}
          >
            {isProcessingPickup ? 'Processing pickup' : 'Confirm this myself'}
          </Action>
        )}

        {isProcessingBol && <Action icon="sync">Processing BOL</Action>}

        {fvBolUrl && !hideFvBolFromSpotQuote && load.workflow !== 'parcel' && (
          <Action href={fvBolUrl} icon="truck">
            Print Freightview BOL
          </Action>
        )}

        {carrierBolUrl && (
          <Action href={carrierBolUrl} icon="truck">
            Print Carrier BOL
          </Action>
        )}

        {canFinishBol && (
          <Action
            icon="file-minus"
            to={composeEditLink('details', 'finishBOL')}
          >
            Finish Freightview BOL
          </Action>
        )}

        {status !== 'canceled' && canBook && load.workflow !== 'parcel' && (
          <Action
            icon="upload"
            onClick={() =>
              setActiveSlider('documents', { uploadFileType: 'bol' })
            }
          >
            Upload your own BOL
          </Action>
        )}

        {labels.hasLabels && (
          <Action
            href={labels.href}
            icon={labels.isLoading ? 'sync' : 'tags'}
            onClick={() => {
              if (labels.href || labels.isLoading) return
              setActiveSlider('labels')
            }}
          >
            Print labels
          </Action>
        )}

        <DispatchedLoadAction
          load={load}
          icon="location-arrow"
          onClick={() => setActiveSlider('track')}
        >
          Tracking detail / options
        </DispatchedLoadAction>

        <DispatchedLoadAction
          load={load}
          icon="file"
          onClick={() => setActiveSlider('documents')}
        >
          Documents
        </DispatchedLoadAction>

        <DispatchedLoadAction
          load={load}
          icon="sticky-note"
          onClick={() => setActiveSlider('messages')}
        >
          Messages / Notes / Tags
        </DispatchedLoadAction>

        <Action icon="share" onClick={() => setActiveSlider('share')}>
          Share this shipment
        </Action>

        {canSelectNewRate() && (
          <DispatchedLoadAction
            load={load}
            icon="truck"
            to={routes.rates(load, 'change')}
          >
            Select new rate
          </DispatchedLoadAction>
        )}

        {canCreateReturnShipment && (
          <Action
            icon="exchange"
            onClick={() => {
              if (createReturnShipment.isLoading) return
              createReturnShipment.mutate(load, {
                onSuccess: data => navigate(routes.rates(data[0])),
              })
            }}
          >
            Create return shipment
          </Action>
        )}

        {canEdit && (
          <DispatchedLoadAction
            load={load}
            icon="pen-alt"
            to={composeEditLink('details')}
          >
            Edit shipment
          </DispatchedLoadAction>
        )}

        {canReportMissedPickup && (
          <>
            <DispatchedLoadAction
              load={load}
              onClick={() => setReportMissedPickup(true)}
              icon="exclamation-triangle"
            >
              Report a missed pickup
            </DispatchedLoadAction>
            <MissedPickupSlider
              isOpen={reportMissedPickup}
              closePanel={() => setReportMissedPickup(false)}
              load={load}
            />
          </>
        )}

        {retractTender.canRetract(load) && (
          <DispatchedLoadAction
            load={load}
            icon={retractTender.isLoading ? 'spinner' : 'undo'}
            onClick={() => {
              if (retractTender.isLoading || !retractTender.confirm()) return
              retractTender.mutate(load.loadId)
            }}
          >
            Retract tender
          </DispatchedLoadAction>
        )}

        {status !== 'canceled' && (
          <DispatchedLoadAction
            load={load}
            icon="times"
            onClick={() => setActiveSlider('cancel')}
          >
            Cancel shipment
          </DispatchedLoadAction>
        )}

        <DispatchedLoadAction
          load={load}
          icon="list"
          to={workflow !== 'truckload' ? routes.rates(load, 'view') : undefined}
          onClick={() =>
            load.workflow === 'truckload' && setActiveSlider('quotes')
          }
        >
          View other quotes
        </DispatchedLoadAction>
      </List>
    </nav>
  )
}
