import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { Icon } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

export const ListSubContainer = ({
  children,
  className,
}: PropsWithChildren<PropsWithClassName>) => {
  return (
    <div
      className={clsx(
        'border-fv-gray mt-3 border-t border-dashed pb-2 pt-4 w-full',
        className,
      )}
    >
      {children}
    </div>
  )
}

type SubActionsProps = PropsWithChildren<
  PropsWithClassName<{
    justify?: 'start' | 'end'
  }>
>

export const ListSubActions = ({
  children,
  className,
  justify,
}: SubActionsProps) => {
  return (
    <div
      className={clsx('flex', className, {
        'justify-start': justify === 'start',
        'justify-end': justify === 'end' || !justify,
      })}
    >
      {children}
    </div>
  )
}

export const ListSubHeader = ({ children }: PropsWithChildren) => {
  return (
    <div className="border-fv-gray mb-4 flex items-start border-b border-dashed pb-2">
      <Icon icon="arrow-down" transform="down-2" />
      <p className="mb-0">{children}</p>
    </div>
  )
}
