import { createColumnHelper } from '@tanstack/react-table'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { useToggle } from 'usehooks-ts'

import {
  FvButton,
  FvLinkButton,
  Icon,
  LoadMore,
  SliderPanel,
} from '@fv/client-components'
import {
  flattenPages,
  useInfiniteQueryTable,
  useTableSortState,
} from '@fv/client-core'
import { type ProvisioningEntry } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { SearchField } from '../../components/inputs/SearchField'
import { Loading } from '../../components/Loading'
import { ShipperTable } from '../../components/ShipperTable'
import { TabLink, TabLinkList } from '../../components/TabLink'
import { useAppContext } from '../../hooks/settings'
import { ProvisionerDisclaimer } from './components/Disclaimer'
import {
  ProvisionerContents,
  ProvisionerLayout,
} from './components/ProvisionerLayout'
import { useProvisionerStatus } from './hooks'
import { useProvisionerList } from './queries'

export const ProvisionerPage = () => {
  const [search, setSearch] = useState('')
  const status = useProvisionerStatus()
  const sortState = useTableSortState([{ id: 'modified', desc: false }])
  const scrollElement = useRef<HTMLDivElement>(null)
  const [isTermsOpen, toggleTerms] = useToggle(false)
  const { data } = useAppContext()
  const [sort] = sortState
  const query = useProvisionerList({
    status,
    search,
    sort: sort[0].id,
    sortDir: sort[0].desc ? -1 : 1,
  })
  const now = dayjs.utc()
  const isEmpty =
    !query.isLoading && !search && !flattenPages(query.data?.pages).length
  const colHelper = createColumnHelper<ProvisioningEntry>()
  const table = useInfiniteQueryTable({
    query,
    sortState,
    scrollElement,
    estimateSize: () => 45,
    cols: [
      {
        id: 'company',
        header: 'Account',
        accessorFn: d => d.company,
        meta: {
          className:
            'w-1/4 text-left whitespace-nowrap text-ellipsis overflow-hidden',
        },
      },
      {
        id: 'location',
        header: 'Location',
        accessorFn: d => d.location,
        meta: {
          className:
            'w-1/2 text-left whitespace-nowrap text-ellipsis overflow-hidden',
        },
      },
      {
        id: 'contact',
        header: 'Contact',
        accessorFn: d => d.contact,
        meta: { className: 'w-1/6 text-left' },
      },
      {
        id: 'modified',
        header: 'Requested',
        cell: props => {
          const mod = dayjs.utc(props.row.original.modified)
          const hours = now.diff(mod, 'hours')
          return (
            <div className="flex gap-2">
              {status === 'requested' && (
                <Icon
                  icon="circle"
                  className={clsx({
                    'text-fv-beer-dark': hours < 24 && hours > 12,
                    'text-fv-red': hours > 24,
                  })}
                />
              )}
              {mod.fromNow()}
            </div>
          )
        },
        meta: { className: 'w-1/6 text-left' },
        enableSorting: true,
      },
      colHelper.display({
        id: 'view',
        meta: { className: 'w-1/12 text-right' },
        cell: props => (
          <FvLinkButton
            to={`/provisioning/account/${props.row.original.accountId}?tab=${status}`}
            theme="underlined"
          >
            View
          </FvLinkButton>
        ),
      }),
    ],
  })
  return (
    <ProvisionerLayout>
      <div
        className="overflow-auto"
        ref={scrollElement}
        style={{ maxHeight: 'calc(100vh - 75px)' }}
      >
        <ProvisionerContents>
          <h2 className="mb-4 text-xl">
            {status === 'active'
              ? `You have already provisioned these shippers`
              : `Please provision these shippers who want to see their rates from ${
                  data?.user?.company ?? 'your company'
                }`}
          </h2>
          <div className=" mb-2 flex justify-between">
            <TabLinkList className="">
              <TabLink to="/provisioning/requested" icon="clock">
                Requested
              </TabLink>
              <TabLink to="/provisioning/active" icon="check">
                Active
              </TabLink>
            </TabLinkList>
            <div className="flex items-center gap-x-3">
              <FvButton
                theme="underlined"
                onClick={toggleTerms}
                icon="file-contract"
              >
                Terms
              </FvButton>
              {!isEmpty && (
                <SearchField
                  className="mb-0"
                  initialValue={search}
                  onChange={setSearch}
                />
              )}
            </div>
          </div>
          {isEmpty && (
            <InfoBox className="mt-4">There are no pending requests</InfoBox>
          )}
          {query.isLoading && <Loading />}
          {!query.isLoading && !isEmpty && (
            <div className="border-fv-gray-300 border-y">
              <ShipperTable
                table={table}
                loadMore={
                  <LoadMore
                    hasNextPage={query.hasNextPage}
                    isLoading={query.isFetchingNextPage}
                    fetchNextPage={query.fetchNextPage}
                  />
                }
              />
            </div>
          )}
        </ProvisionerContents>
      </div>
      <SliderPanel isOpen={isTermsOpen} closePanel={toggleTerms}>
        <ProvisionerDisclaimer />
      </SliderPanel>
    </ProvisionerLayout>
  )
}
