import pluralize from 'pluralize'

import { DelimitedContent, HazardDetails, Icon } from '@fv/client-components'
import { formatNumber } from '@fv/client-core'
import {
  type DeepPartial,
  isHandlingUnit,
  type LoadItem,
  type Workflow,
} from '@fv/client-types'

import { useTypeNames } from '../../hooks/settings'
import { displayNmfc } from './commodityUtils'

type Props = {
  item: DeepPartial<LoadItem>
  workflow: Workflow
  includeDescription?: boolean
  includeContains?: boolean
}
// TODO - LoadLeg has this same logic with a duplicate type for the item.  We should change it over to LoadHandlingUnit
export const LoadItemSummary = (props: Props) => {
  const { item, includeDescription, includeContains, workflow } = props
  const { packageName } = useTypeNames()
  const {
    description,
    quantity,
    type,
    dimensionsUOM,
    length,
    width,
    height,
    weight,
    weightUOM,
    freightClass,
  } = item

  const hasDims = !!(height && length && width)
  return (
    <DelimitedContent delimiter={<>&nbsp;/&nbsp;</>}>
      {includeDescription && description && <span>{description}</span>}
      {pluralize(type ? packageName(type) : 'item', quantity, true)}
      {hasDims && (
        <span>
          {`Dimensions ${length} x ${width} x ${height} ${
            dimensionsUOM ?? 'in'
          }.`}
        </span>
      )}
      {item.nmfcNumber && workflow === 'ltl' && (
        <span>NMFC {displayNmfc(item)}</span>
      )}
      {freightClass && <span>Freight class {freightClass}</span>}
      {!!weight && (
        <span>
          Total weight {formatNumber(weight)} {weightUOM ?? 'lbs'}.
        </span>
      )}
      {includeContains && isHandlingUnit(item) && item.contains?.length && (
        <ContainsSummary {...props} />
      )}
      {item.hazardous?.length && <HazardSummary {...props} />}
    </DelimitedContent>
  )
}

const ContainsSummary = ({ item, includeContains }: Props) => {
  const { packageName } = useTypeNames()
  const contains = isHandlingUnit(item) ? (item.contains ?? []) : []
  if (!includeContains || !contains.length) return null

  const containStrings = contains.map(c =>
    pluralize(c.type ? packageName(c.type) : 'item', c.quantity, true),
  )
  return <span>Contains {containStrings.join(', ')}</span>
}
const HazardSummary = ({ item }: Props) => {
  const hazardous = item.hazardous?.[0]
  if (!hazardous) return null

  return (
    <>
      <Icon className="fa-fw text-fv-orange" icon="radiation-alt" />
      <HazardDetails hazardDetails={[hazardous]} />
    </>
  )
}
