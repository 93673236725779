import { useQueryClient } from '@tanstack/react-query'
import { lazy, Suspense } from 'react'

import { useFvNavigate } from '@fv/client-components'

import InfoBox from '../../../components/InfoBox'
import { routes } from '../../../routes'
import { ContractedRateForm } from './ContractedRateForm'
import { contractedRatesKeys } from './queries'

const UploadForm = lazy(() => import('./UploadForm'))

export const NewContractedRate = () => {
  const navigate = useFvNavigate()
  const queryClient = useQueryClient()
  return (
    <>
      <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
        <UploadForm />
      </Suspense>

      <div className="mb-4 flex items-center">
        <div className="flex-pinline flex-pinline--no-margin flex-pinline--alternate" />
        <span className="ml-2 mr-2">
          <span className="round-cue round-cue--callout">or</span>
        </span>
        <div className="flex-pinline flex-pinline--no-margin flex-pinline--alternate" />
      </div>

      <ContractedRateForm
        onCancel={() => navigate(routes.contractedRates('list'))}
        onSubmit={carrierId => {
          queryClient.invalidateQueries(contractedRatesKeys.carriers())
          navigate(routes.contractedRates('list', { carrierId }))
        }}
      />
    </>
  )
}
