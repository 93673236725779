import { useMemo } from 'react'

import { type FullShipment } from '@fv/client-types'

export const useHasUnreadMessages = (
  messageSummary: FullShipment['messageSummary'],
) => {
  return useMemo(() => {
    const { lastCarrierMessageDate, lastReadDate } = messageSummary ?? {}

    if (!lastCarrierMessageDate) return false

    if (
      lastReadDate &&
      new Date(lastReadDate) > new Date(lastCarrierMessageDate)
    ) {
      return false
    }

    return true
  }, [messageSummary])
}
