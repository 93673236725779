import { useToggle } from 'usehooks-ts'

import {
  FvButton,
  Icon,
  SliderPanel,
  ValidatedForm,
} from '@fv/client-components'
import { useFormModelState } from '@fv/client-core'
import { type AccountLocationUpsertModel } from '@fv/client-types'

import { ActionListItem } from '../../../components/ActionList'
import { FormActions } from '../../../components/forms/FormActions'
import { List, ListItem, ListItemActions } from '../../../components/List'
import { Loading } from '../../../components/Loading'
import { type AccountLocationDto } from '../../addresses/types'
import { useAccountLocations } from '../../addresses/useAddress'
import { HasPermission } from '../../auth'
import { CompanyLocationForm } from '../../company-locations/CompanyLocationForm'
import {
  useAddAccountLocation,
  useRemoveAccountLocation,
} from '../../company-locations/mutations'
import { SettingsPageLayout } from '../SettingsPageLayout'
import { SettingsSection } from '../SettingsSection'

export const CompanyLocationsPage = () => {
  const { isLoading, data: locations } = useAccountLocations()
  const [showSlider, toggleSlider] = useToggle()

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <SettingsPageLayout>
        <SettingsSection title="Company locations">
          <HasPermission name="manage">
            <div className="mt-2 border-b border-dotted pb-2">
              <FvButton icon="plus" onClick={toggleSlider}>
                Add Location
              </FvButton>
            </div>
          </HasPermission>
          <List className="mt-3">
            {locations?.map((l, ix) => (
              <ListItem
                className="gap-x-2"
                key={ix}
                actions={<LocationActions loc={l} />}
              >
                <Icon
                  icon="industry-alt"
                  className="text-fv-gray-dark"
                  transform="up-1.5"
                />
                {l.formatted}
              </ListItem>
            ))}
          </List>
        </SettingsSection>
      </SettingsPageLayout>
      <SliderPanel isOpen={showSlider} closePanel={toggleSlider}>
        <Form onCancel={toggleSlider} onSaved={toggleSlider} />
      </SliderPanel>
    </>
  )
}

type LocationActionProps = {
  loc: AccountLocationDto
}
const LocationActions = ({ loc }: LocationActionProps) => {
  const removeLocation = useRemoveAccountLocation()
  const handleRemoveClick = async () => {
    if (
      !window.confirm(
        `Are you sure you want to remove this location from your account?`,
      )
    ) {
      return
    }

    await removeLocation.mutateAsync({ locationId: loc._id })
  }
  return (
    <HasPermission name="manage">
      <ListItemActions>
        <ActionListItem
          icon="trash"
          theme="plain"
          iconClass="text-fv-orange-bright"
          loading={removeLocation.isLoading}
          onClick={handleRemoveClick}
        />
      </ListItemActions>
    </HasPermission>
  )
}

type FormProps = {
  onSaved: () => void
  onCancel: () => void
}
const Form = ({ onSaved, onCancel }: FormProps) => {
  const { register, setValue, value } =
    useFormModelState<AccountLocationUpsertModel>({
      initialValue: {
        state: '',
        city: '',
        postalCode: '',
        street: '',
        country: 'us',
      },
    })
  const addLocation = useAddAccountLocation()
  const handleSubmit = async () => {
    await addLocation.mutateAsync({ body: value })
    onSaved()
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <CompanyLocationForm
        register={register}
        value={value}
        onChange={setValue}
      />
      <FormActions
        className="mt-8"
        onCancel={onCancel}
        submitText="Add location"
        loading={addLocation.isLoading}
      />
    </ValidatedForm>
  )
}
