import clsx from 'clsx'
import pluralize from 'pluralize'
import { type PropsWithChildren } from 'react'

import { ContainsDisplay, Icon, type IconProps } from '@fv/client-components'
import { formatNumber } from '@fv/client-core'
import { type LoadHandlingUnit, type LoadItem } from '@fv/client-types'

import { ListItem } from '../../components/List'
import { useLocale } from '../../hooks/settings'
import { getDimensions } from './commodityUtils'
import { type FormCommodity } from './FormCommodity'

type ItemDisplayProps = {
  className?: string
  icon?: IconProps['icon']
  iconClassName?: string
  containsDisplay?: LoadItem[]
}

const ItemDisplay = ({
  children,
  className,
  icon = 'dot-circle',
  iconClassName,
  containsDisplay,
}: PropsWithChildren<ItemDisplayProps>) => {
  return (
    <ListItem dashed itemsStart className={clsx('flex-nowrap', className)}>
      {containsDisplay && containsDisplay.length > 0 ? (
        <div>
          <div className="nowrap flex">
            <Icon
              className={clsx('text-fv-gray', iconClassName)}
              icon={icon}
              transform="down-2.5"
            />
            <p className="mb-3">{children}</p>
          </div>

          {containsDisplay.map(c => (
            <ContainsDisplay
              item={c}
              iconClassName={iconClassName}
              key={c._id}
              border={false}
              textMargin="ml-2"
              bottomMargin="mb-1"
              containsIcon={
                <div>
                  <Icon
                    className={clsx('text-fv-gray', iconClassName)}
                    icon={'arrow-turn-down-right'}
                    transform="down-2.5 shrink-3"
                  />
                </div>
              }
            />
          ))}
        </div>
      ) : (
        <>
          <Icon
            className={clsx('text-fv-gray', iconClassName)}
            icon={icon}
            transform="down-2.5"
          />
          <p className="mb-0">{children}</p>
        </>
      )}
    </ListItem>
  )
}

interface ItemSummaryProps extends ItemDisplayProps {
  item: FormCommodity | LoadHandlingUnit
  label: string
  contains?: LoadItem[]
}

export const ItemSummary = ({
  item,
  label,
  contains,
  ...itemDisplayProps
}: ItemSummaryProps) => {
  const { description, quantity, type, weight, weightUOM } = item
  const dimensions = getDimensions(item)
  const locale = useLocale()

  return (
    <ItemDisplay
      {...itemDisplayProps}
      containsDisplay={contains && contains?.length > 0 ? contains : []}
    >
      {label}: {description}, {pluralize(type, Number(quantity), true)},
      {dimensions && ` ${dimensions},`} {formatNumber(weight, locale)}{' '}
      {weightUOM}
    </ItemDisplay>
  )
}

export const MissingItemSummary = ({
  className,
  iconClassName,
}: ItemDisplayProps) => {
  return (
    <ItemDisplay
      className={className}
      icon="exclamation-triangle"
      iconClassName={clsx(iconClassName, 'text-fv-orange')}
    >
      No items have been assigned to this stop.
    </ItemDisplay>
  )
}
