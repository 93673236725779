import { useState } from 'react'

import {
  FvButton,
  FvLinkButton,
  Icon,
  ValidatedForm,
} from '@fv/client-components'
import {
  createCredentailDetails,
  marketingUri,
  showApiError,
  useFormModelState,
} from '@fv/client-core'
import {
  type CarrierMode,
  carrierModeTypes,
  type CarrierProvisioningDetails,
} from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import CheckboxField from '../../components/inputs/CheckboxField'
import { InputGroupWrapper } from '../../components/inputs/InputGroup'
import { AccountCarrierCredentialForm } from './AccountCarrierCredentialForm'
import {
  useAccountCarrierFromState,
  useAccountCarrierState,
  useCarrierDetails,
} from './AccountCarrierFormProvider'
import {
  useCarrierCredComponent,
  useCarrierProvisioningComponent,
} from './CarrierComponentProvider'
import { useUpsertCarrier } from './mutations'
import { SalesRepForm } from './SalesRepForm'
import {
  type AccountCarrierUpsertModel,
  type BaseProvisionFormProps,
} from './types'
import { fillModeDefaults, getCarrierModeDisplay, toFormModel } from './utils'

type AccountCarrierFormProps = BaseProvisionFormProps
export const AccountCarrierForm = (props: AccountCarrierFormProps) => {
  const carrierDetails = useCarrierDetails()
  const accountCarrier = useAccountCarrierFromState()
  const { isAdding, carrierOverride } = useAccountCarrierState()
  const { onCancel, onSuccess } = props
  const [hasFinishedBasicDetails, setHasFinishedBasicDetails] = useState(false)
  const CarrierComponent = useCarrierProvisioningComponent()
  const hasCredForm = !!useCarrierCredComponent()
  const hasAdditionalSteps = isAdding && !!CarrierComponent

  const formModel = fillModeDefaults(
    toFormModel(accountCarrier),
    carrierDetails,
  )

  if (isAdding && carrierDetails.betaProvisioning) {
    formModel.isBeta = true
  }

  const handleFormSuccess = () => {
    if (hasAdditionalSteps) {
      setHasFinishedBasicDetails(true)
      return
    }
    onSuccess()
  }

  if (CarrierComponent && (hasFinishedBasicDetails || carrierOverride)) {
    return <CarrierComponent onCancel={onCancel} onSuccess={onSuccess} />
  }

  return (
    <div>
      <h5 className="mb-4 border-b pb-2">
        <Icon icon="truck-fast" className="text-fv-gray-dark" />
        <span>{carrierDetails.name}</span>
      </h5>

      {!hasCredForm &&
        !hasAdditionalSteps &&
        carrierDetails.code !== 'fxfe' && (
          <CarrierNotice carrier={carrierDetails} exists={!!accountCarrier} />
        )}

      <Form
        hasAdditionalSteps={hasAdditionalSteps}
        model={formModel}
        onCancel={onCancel}
        onSuccess={handleFormSuccess}
      />
    </div>
  )
}

const getPropFromMode = (
  mode: CarrierMode,
): keyof AccountCarrierUpsertModel => {
  if (mode === 'parcel') return 'enableParcel'
  if (mode === 'tl') return 'enableTL'
  return 'enableLTL'
}

type FormProps = BaseProvisionFormProps & {
  model: AccountCarrierUpsertModel
  hasAdditionalSteps?: boolean
}
const Form = ({
  model: initialModel,
  onSuccess,
  onCancel,
  hasAdditionalSteps,
}: FormProps) => {
  const carrierDetails = useCarrierDetails()
  const upsertCarrier = useUpsertCarrier()

  const { register, value, setValue } = useFormModelState({
    initialValue: {
      ...initialModel,
      details: createCredentailDetails(
        carrierDetails.credentialTypes,
        initialModel?.details ?? [],
      ),
    },
  })
  const carrierModes = carrierModeTypes
    .map(mode => ({ mode, ...carrierDetails[mode] }))
    .filter(c => c.enabled)

  const buttonText = hasAdditionalSteps
    ? 'Save and continue'
    : carrierDetails.status === 'pending'
      ? 'Add to my list'
      : initialModel
        ? 'Update'
        : 'Authorize'

  const handleSubmit = async () => {
    try {
      await upsertCarrier.mutateAsync({
        ...value,
        isOnboarding: false,
        carrierId: carrierDetails.carrierId,
      })
      onSuccess?.()
    } catch (e) {
      showApiError('', e)
    }
  }
  return (
    <ValidatedForm className="pt-2" onValidSubmit={handleSubmit}>
      <AccountCarrierCredentialForm
        value={value.details}
        onChange={details => setValue(v => ({ ...v, details }))}
      />
      <SalesRepForm register={register} carrier={carrierDetails} />
      {carrierModes.length > 1 && (
        <InputGroupWrapper
          label="Use the following services with Freightview"
          name="modes"
          className="mt-6"
        >
          <div className=" flex w-full gap-x-6 border-b border-t border-dotted border-[#ccc] py-3">
            {carrierModes.map(m => (
              <CheckboxField
                name={m.mode}
                key={m.mode}
                label={getCarrierModeDisplay(m.carrierNameOverride, m.mode)}
                checked={value[getPropFromMode(m.mode)] as boolean}
                onChange={e =>
                  setValue(prev => ({
                    ...prev,
                    [getPropFromMode(m.mode)]: e.target.checked,
                  }))
                }
              />
            ))}
          </div>
        </InputGroupWrapper>
      )}
      <div className="flex pt-4">
        <FvButton
          theme="primary"
          icon="check"
          type="submit"
          loading={upsertCarrier.isLoading}
        >
          {buttonText}
        </FvButton>
        <FvButton theme="plain" onClick={onCancel} icon="times">
          Cancel
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

const CarrierNotice = ({
  carrier,
  exists,
}: {
  carrier: CarrierProvisioningDetails
  exists?: boolean
}) => {
  if (carrier.status === 'pending') {
    return (
      <InfoBox>
        {carrier.name} doesn't currently participate in Freightview. We'd love
        to have them join us. Go ahead and put them on your list of carriers and
        brokers so we can record your vote and notify you if they become
        available.
      </InfoBox>
    )
  }

  return (
    <InfoBox>
      For {carrier.name}, we just need your permission to connect, then we'll
      handle the rest. By clicking below, you authorize us to work with{' '}
      {carrier.name} to retrieve your rates. (This is covered in more detail in
      the{' '}
      <FvLinkButton
        href={`${marketingUri}/terms`}
        target="_blank"
        theme="underlined"
        rel="noreferrer"
      >
        Terms & Conditions
      </FvLinkButton>
      .)
    </InfoBox>
  )
}
