import clsx from 'clsx'
import {
  type Dispatch,
  Fragment,
  type PropsWithChildren,
  type SetStateAction,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { FvDate, FvLinkButton, Icon, Tooltip } from '@fv/client-components'
import {
  flattenPages,
  parseQueryString,
  stringifyQueryString,
  toggleArrayItem,
} from '@fv/client-core'
import {
  type ListShipment,
  type PropsWithClassName,
  type ShipmentLocation,
} from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import CheckboxField from '../../components/inputs/CheckboxField'
import { Loading } from '../../components/Loading'
import { supportMessage } from '../../constants'
import { routes } from '../../routes'
import {
  getCarrierDetails,
  getDestination,
  getOrigin,
  modeNames,
} from '../../utils/shipmentFuncs'
import { useShipmentLabels } from '../labels/hooks'
import {
  ShipmentDetailsSliderPanel,
  type ShipmentSliderState,
} from '../shipment-details-panel/ShipmentDetailsSliderPanel'
import { type SliderName } from '../shipment-details-panel/types'
import {
  type SortBy,
  type SortDirection,
  useConfirmedListParams,
} from './hooks/useShipmentListParams'
import { getTransitStatus } from './ActiveShipmentCardHeader'
import { type ShipmentListProps } from './types'

type Props = ShipmentListProps & {
  setSelected: Dispatch<SetStateAction<string[]>>
  selected: string[]
}
export const ShipmentTable = (props: Props) => {
  const [, setSearchParams] = useSearchParams()
  const { query, setSelected, selected } = props
  const [sliderState, setSliderState] = useState<ShipmentSliderState>()
  const { activeLoadId, qs } = useConfirmedListParams()
  const shipments = flattenPages(query.data?.pages)
  const isInitialLoading =
    query.isLoading || (query.isFetching && !shipments.length)

  const handleSortClick = (sort: SortBy, dir: SortDirection) => {
    setSearchParams(
      stringifyQueryString({
        ...parseQueryString(qs),
        sortBy: sort,
        sortDirection: dir,
      }),
    )
  }
  if (isInitialLoading) {
    return <Loading />
  }
  if (query.isError) {
    return (
      <InfoBox icon="triangle">
        Unable to load shipments, {supportMessage}
      </InfoBox>
    )
  }
  if (!shipments.length) {
    return <InfoBox>No shipments available.</InfoBox>
  }
  return (
    <div>
      <table className="w-full table-auto border-collapse">
        <thead>
          <tr className="">
            <Th className="text-center">
              <FvLinkButton
                icon={selected.length ? 'dash' : 'check-double'}
                onClick={() =>
                  selected.length
                    ? setSelected([])
                    : setSelected(shipments.map(l => l.loadId))
                }
              />
            </Th>
            <Th className="text-left">BOL#</Th>
            <Th className="text-left">Tracking#</Th>
            <Th className="text-left">
              <SortLink sort="status" onClick={handleSortClick} initialSort="1">
                Status
              </SortLink>
            </Th>

            <Th className="text-left">
              <SortLink
                sort="carrier"
                onClick={handleSortClick}
                initialSort="1"
              >
                Carrier
              </SortLink>
            </Th>
            <Th className="text-left">Origin</Th>
            <Th className="text-left">
              <SortLink sort="pickupDate" onClick={handleSortClick}>
                Pickup
              </SortLink>
            </Th>
            <Th className="text-left">Destination</Th>
            <Th className="text-left">
              <SortLink sort="deliveryDate" onClick={handleSortClick}>
                Deliver
              </SortLink>
            </Th>
            <Th className="text-left">Mode</Th>
            <Th className="text-left">
              <Icon icon="ellipsis-h" className="text-fv-gray-dark" />
            </Th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {shipments.map(load => (
            <LoadRow
              key={load.loadId}
              load={load}
              isActive={activeLoadId === load.loadId}
              setDetailTab={tab => setSliderState({ tab })}
              {...props}
            />
          ))}
        </tbody>
      </table>
      {activeLoadId && (
        <ShipmentDetailsSliderPanel
          loadId={activeLoadId}
          state={sliderState}
          onChange={setSliderState}
        />
      )}
    </div>
  )
}

type SortLinkProps = {
  sort: SortBy
  initialSort?: SortDirection
  onClick: (sort: SortBy, sortDir: SortDirection) => void
}
const SortLink = ({
  sort,
  children,
  initialSort = '-1',
  onClick,
}: PropsWithChildren<SortLinkProps>) => {
  const { sortBy, sortDirection } = useConfirmedListParams()
  return (
    <FvLinkButton
      theme="underlined"
      icon={
        sortBy === sort
          ? sortDirection === '1'
            ? 'arrow-up'
            : 'arrow-down'
          : undefined
      }
      iconClass="text-fv-orange"
      onClick={() =>
        onClick(
          sort,
          sort === sortBy ? (sortDirection === '1' ? '-1' : '1') : initialSort,
        )
      }
    >
      {children}
    </FvLinkButton>
  )
}

type RowProps = Props & {
  load: ListShipment
  isActive?: boolean
  setDetailTab: (tab: SliderName) => void
}
const LoadRow = ({
  setDetailTab,
  load,
  isActive,
  onActivate,
  setSelected,
  selected,
}: RowProps) => {
  const labels = useShipmentLabels(load)
  const labelLink =
    load.workflow === 'parcel' && labels.href ? labels.href : undefined
  return (
    <Fragment key={load.loadId}>
      <tr
        className={clsx(
          'border-fv-gray-300 hover:bg-fv-beer-light border-b hover:cursor-pointer',
          { 'bg-fv-beer-light': isActive },
        )}
        data-loadid={load.loadId} // for scroll to on list type toggle
        onClick={e =>
          !(e.target as HTMLElement).closest('a,input,label') &&
          onActivate?.(load)
        }
      >
        <Td className="pl-4">
          <CheckboxField
            name={load.loadId}
            checked={selected.includes(load.loadId)}
            onChange={e => {
              setSelected(p =>
                toggleArrayItem(p, load.loadId, e.target.checked),
              )
            }}
          />
        </Td>
        <Td>{load.bol?.bolNumber}</Td>
        <Td>{load.tracking?.trackingNumber}</Td>
        <Td className="whitespace-nowrap">
          <ShipmentStatusIndicator load={load} />
        </Td>
        <Td className="max-w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap">
          {getCarrierDetails(load, false)}
        </Td>
        <Td>
          <ShipmentLoc loc={getOrigin(load)} />
        </Td>
        <Td>
          <FvDate val={load.pickupDate} />
        </Td>
        <Td>
          <ShipmentLoc loc={getDestination(load)} />
        </Td>
        <Td>
          <FvDate val={load.estimatedDeliveryDate} />
        </Td>
        <Td>{modeNames[load.equipment.mode]}</Td>
        <Td>
          <Tooltip label="More details...">
            <FvLinkButton to={routes.details(load.loadId)} icon="ellipsis-h" />
          </Tooltip>
        </Td>
      </tr>
      {isActive && (
        <tr className="bg-fv-blue-50 border-b">
          <td />
          <td colSpan={11} className="p-4 pl-2">
            <div className="divided-content divided-content--start">
              <FvLinkButton
                onClick={() => setDetailTab('documents')}
                icon="file"
              >
                Documents
              </FvLinkButton>
              <FvLinkButton
                onClick={() => setDetailTab('track')}
                icon="map-marker"
              >
                Tracking
              </FvLinkButton>
              <FvLinkButton
                onClick={() => setDetailTab('messages')}
                icon="comment-alt-lines"
              >
                Messages
              </FvLinkButton>
              {labels.hasLabels && (
                <FvLinkButton
                  to={labelLink}
                  target={labelLink ? '_blank' : undefined}
                  onClick={() => {
                    if (!labelLink) {
                      setDetailTab('labels')
                    }
                  }}
                  icon="tags"
                >
                  Labels
                </FvLinkButton>
              )}
              <FvLinkButton to={routes.details(load.loadId)} icon="ellipsis-h">
                More
              </FvLinkButton>
            </div>
          </td>
        </tr>
      )}
    </Fragment>
  )
}

const Th = ({ children, className }: PropsWithChildren<PropsWithClassName>) => {
  return (
    <th
      className={clsx(
        ' bg-fv-gray-100 sticky top-[-2rem] z-10 whitespace-nowrap px-2 py-3 font-normal ',
        className,
      )}
    >
      {children}
      <div className="border-fv-gray-300 absolute bottom-0 left-0 w-full border-b" />
    </th>
  )
}

const Td = ({ children, className }: PropsWithChildren<PropsWithClassName>) => {
  return <td className={clsx('p-2', className)}>{children}</td>
}

const ShipmentStatusIndicator = ({
  load: { status, pickup, workflow },
}: {
  load: Pick<ListShipment, 'pickup' | 'status' | 'workflow'>
}) => {
  const transitStatus = getTransitStatus(pickup?.status, workflow)

  const isDelivered = status === 'delivered'
  const isInTransit = status === 'picked-up'
  const isScheduled = status === 'confirmed'
  const isCanceled = status === 'canceled'
  return (
    <span>
      <Icon
        icon="dot-circle"
        className={clsx({
          'text-fv-orange': transitStatus === 'Scheduled',
          '!text-fv-beer': isInTransit,
          '!text-fv-green': isDelivered,
          '!text-fv-red': isCanceled,
        })}
      />
      <span>
        {isDelivered && 'Delivered'}
        {isInTransit && 'In Transit'}
        {isScheduled && transitStatus}
        {isCanceled && 'Canceled'}
      </span>
    </span>
  )
}

const ShipmentLoc = ({ loc }: { loc?: ShipmentLocation }) => {
  if (!loc) return
  return (
    <div className="flex gap-1.5">
      <span className="max-w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap">
        {loc.city}
      </span>
      <span>{loc.state}</span>
      <span>{loc.postalCode}</span>
    </div>
  )
}
