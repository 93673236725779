import clsx from 'clsx'
import { type ChangeEvent, useRef } from 'react'
import { type SelectInstance } from 'react-select'

import { FvButton, Icon } from '@fv/client-components'
import {
  type ProductCatalogEntry,
  type ShipmentLocation,
} from '@fv/client-types'

import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { SelectField } from '../../components/inputs/SelectField'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import { useAppSettings } from '../../hooks/settings'
import { useSecondaryPackageOptions } from '../../hooks/settings/useEnumOptions'
import {
  buildHazardousDetails,
  validateHazmatIdChange,
} from '../commodities/commodityUtils'
import { PackagingSelector } from '../commodities/PackagingSelector'
import { ProductCatalogButton } from '../commodities/ProductCatalog'
import { ProductCatalogTypeahead } from '../commodities/ProductCatalogTypeahead'

const hazardousOptions = ['Yes', 'No']

type ItemFieldsLocation = Pick<
  ShipmentLocation,
  'company' | 'sequence' | 'stopType'
> & {
  city?: string
  postalCode?: string
  state?: string
}

type Option =
  | string
  | {
      isValidSubClass?: boolean
      key?: string
      text: string
      value: string
    }

type Props = {
  autoFocus: boolean
  canRemove: boolean
  className?: string
  locations: ItemFieldsLocation[]
  requiresDeclaredValue: boolean
  setValues: (c: FormCommodity | null) => void
  values: FormCommodity
  isInSlider?: boolean
}

export const LoadItemFields = ({
  autoFocus,
  canRemove,
  className,
  locations,
  requiresDeclaredValue,
  setValues,
  values,
  isInSlider,
}: Props) => {
  const appSettingsQuery = useAppSettings()
  const containsOptions = useSecondaryPackageOptions('truckload', values.type)
  const isContainable = containsOptions.length > 1
  const searchRef = useRef<SelectInstance>(null)

  const fieldId = (s: string) => `${values.id}_${s}`

  const { hazardClasses = [], packingGroups = [] } =
    appSettingsQuery.data?.enums ?? {}

  const hazardClassOptions = ['' as Option].concat(
    hazardClasses.map(c => ({
      ...c,
      text: c.key,
      value: c.key,
    })),
  )

  const packingGroupOptions = ['' as Option].concat(
    packingGroups.map(g => ({
      text: g.name,
      value: g.key,
    })),
  )

  const subClassOptions = ['' as Option].concat(
    // @ts-expect-error can't find isValidSubClass
    hazardClassOptions.filter(o => o.isValidSubClass),
  )
  const handleProductSelect = (entry: ProductCatalogEntry) => {
    setValues({
      ...values,
      description: entry.description,
      declaredValue: entry.declaredValue,
      weight: entry.weight,
      length: entry.length,
      width: entry.width,
      height: entry.height,
      quantity: entry.pieces,
      readOnly: entry.readOnly,
      ...(entry.package && { type: entry.package }),
      ...(entry.hazardous === 'Yes'
        ? {
            hazardous: 'Yes',
            hazmatId: entry.hazmatId,
            packingGroup: entry.packingGroup,
            permitNumber: entry.specialPermit,
            primaryClass: entry.hazardClass,
            secondaryClass: entry.secondaryHazardClass,
            tertiaryClass: entry.tertiaryHazardClass,
          }
        : {
            hazardous: 'No',
            ...buildHazardousDetails(),
          }),
      ...(entry.saidToContain && { saidToContain: entry.saidToContain }),
      ...(entry.saidToContainPackagingType && {
        saidToContainPackagingType: entry.saidToContainPackagingType,
      }),
    })
  }

  return (
    <div
      className={clsx(
        'box bg-skin-2 load-item-fields @container relative pb-0 ',
        className,
        canRemove && 'remove',
      )}
      data-cy="handling-unit"
    >
      <div className="form-row items-center">
        <InputGroupWrapper
          className="flex-grow basis-5/12 flex-shrink-0"
          label="Commodity"
          name={fieldId('search')}
        >
          <ProductCatalogTypeahead
            filter={{
              mode: 'ltl',
            }}
            hasDropdownIcon={false}
            name={fieldId('search')}
            onInputChange={v => setValues({ ...values, description: v })}
            value={values.description}
            searchRef={searchRef}
            setProduct={handleProductSelect}
          />
          <div className="input-group__append">
            <ProductCatalogButton
              label="Catalog"
              // disabled={disabled}
              init={{
                useSlider: !isInSlider,
                filter: {
                  mode: 'ltl',
                },
                setProduct: handleProductSelect,
              }}
            />
          </div>
        </InputGroupWrapper>

        <InputGroupWrapper
          required
          label="Handling unit type"
          className="basis-3/12"
          name={fieldId('type')}
          locked={values.readOnly?.package}
        >
          <PackagingSelector
            workflow="truckload"
            name={fieldId('type')}
            disabled={values.readOnly?.package}
            width={values.width ? Number(values.width) : undefined}
            length={values.length ? Number(values.length) : undefined}
            required
            value={values.type}
            onChange={({ type, width, length }) =>
              setValues({
                ...values,
                type,
                width: width || values.width,
                length: length || values.length,
              })
            }
          />
        </InputGroupWrapper>

        <InputGroup
          className="basis-1/12"
          inputType="amount"
          label="Qty"
          required
          locked={values.readOnly?.pieces}
          inputProps={{
            name: fieldId('quantity'),
            autoFocus: autoFocus,
            value: values.quantity,
            min: 1,
            onChange: e => {
              setValues({ ...values, quantity: e.target.value })
            },
          }}
        />

        <InputGroup
          className="basis-2/12"
          inputType="amount"
          required={requiresDeclaredValue}
          label="Value"
          startContent={
            <InputAdornment
              icon="dollar-sign"
              position="start"
              className="text-fv-gray-dark"
            />
          }
          locked={values.readOnly?.declaredValue}
          inputProps={{
            isPrice: true,
            name: fieldId('declaredValue'),
            value: values.declaredValue,
            min: 1,
            onChange: e =>
              setValues({ ...values, declaredValue: e.target.value }),
          }}
        />

        <InputGroup
          inputType="amount"
          label="Contains"
          className="basis-3/12"
          locked={values.readOnly?.saidToContain}
          inputProps={{
            name: fieldId('saidToContain'),
            value: values.saidToContain,
            onChange: e =>
              setValues({ ...values, saidToContain: e.target.value }),
          }}
        />

        <InputGroupWrapper
          name={fieldId('saidToContainPackagingType')}
          label="Units"
          required={!!values.saidToContain}
          className="basis-2/12"
          locked={values.readOnly?.saidToContainPackagingType}
        >
          <PackagingSelector
            value={values.saidToContainPackagingType}
            name={fieldId('saidToContainPackagingType')}
            workflow={'truckload'}
            isContained
            parentPackageType={values.type}
            required={!!values.saidToContain}
            disabled={
              !isContainable || values.readOnly?.saidToContainPackagingType
            }
            onChange={({ type }) =>
              setValues({ ...values, saidToContainPackagingType: type })
            }
          />
        </InputGroupWrapper>

        <InputGroup
          className="basis-2/12"
          inputType="amount"
          label="Total weight"
          endContent={
            <InputAdornment position="end">
              <span>{values.weightUOM}</span>
            </InputAdornment>
          }
          required
          locked={values.readOnly?.weight}
          inputProps={{
            name: fieldId('weight'),
            value: values.weight,
            min: 1,
            onChange: e => setValues({ ...values, weight: e.target.value }),
          }}
        />

        <InputGroup
          className="basis-1/12"
          inputType="amount"
          label="L (in)"
          required={!!(values.length || values.width || values.height)}
          locked={values.readOnly?.length}
          inputProps={{
            name: fieldId('length'),
            min: 1,
            value: values.length,
            onChange: e => setValues({ ...values, length: e.target.value }),
          }}
        />
        <InputGroup
          className="basis-1/12"
          inputType="amount"
          label="W (in)"
          locked={values.readOnly?.width}
          required={!!(values.length || values.width || values.height)}
          inputProps={{
            name: fieldId('width'),
            min: 1,
            value: values.width,
            onChange: e => setValues({ ...values, width: e.target.value }),
          }}
        />
        <InputGroup
          className="basis-1/12"
          inputType="amount"
          label="H (in)"
          required={!!(values.length || values.width || values.height)}
          locked={values.readOnly?.height}
          inputProps={{
            name: fieldId('height'),
            min: 1,
            value: values.height,
            onChange: e => setValues({ ...values, height: e.target.value }),
          }}
        />

        <InputGroup
          label="HM?"
          className="basis-2/12"
          inputType="select"
          locked={values.readOnly?.hazardous}
          inputProps={{
            name: fieldId('hazardous'),
            options: hazardousOptions,
            onChange: e =>
              setValues({
                ...values,
                ...(e.target.value === 'No'
                  ? {
                      hazardous: 'No',
                      ...buildHazardousDetails(),
                    }
                  : {
                      hazardous: 'Yes',
                    }),
              }),
            value: values.hazardous,
          }}
        />

        {canRemove && (
          <div className="border-fv-blue absolute -right-2 -top-2 rounded-full border-2 bg-white p-2">
            <FvButton
              icon="trash"
              iconClass="fa-fw text-fv-orange"
              onClick={() => setValues(null)}
            />
          </div>
        )}
      </div>

      {values.hazardous === 'Yes' && (
        <div className="form-row">
          <InputGroup
            className="col-3 mb-0"
            label={
              <>
                <Icon icon="triangle" className="text-fv-orange" />
                <span>Hazmat ID</span>
              </>
            }
            inputType="text"
            required
            locked={values.readOnly?.hazmatId}
            inputProps={{
              name: fieldId('hazmatId'),
              value: values.hazmatId ?? '',
              pattern: `(UN|NA)\\d{4}`,
              onInvalid: (e: ChangeEvent<HTMLInputElement>) => {
                e.target.setCustomValidity('Use UN#### or NA#### format.')
              },
              onChange: e => {
                setValues({ ...values, hazmatId: e.target.value })
                validateHazmatIdChange(e)
              },
            }}
          />
          <InputGroup
            className="mb-0"
            inputType="select"
            label="PG"
            locked={values.readOnly?.packingGroup}
            inputProps={{
              options: packingGroupOptions,
              value: values.packingGroup ?? '',
              name: fieldId('packingGroup'),
              onChange: e =>
                setValues({ ...values, packingGroup: e.target.value }),
            }}
          />
          <InputGroup
            className="col-3"
            inputType="text"
            label="Special permit No."
            locked={values.readOnly?.specialPermit}
            inputProps={{
              name: fieldId('permitNumber'),
              value: values.permitNumber ?? '',
              onChange: e =>
                setValues({ ...values, permitNumber: e.target.value }),
            }}
          />

          <InputGroup
            className="flex-1"
            label="Haz class"
            inputType="select"
            required
            locked={values.readOnly?.hazardClass}
            inputProps={{
              options: hazardClassOptions,
              name: fieldId('primaryClass'),
              value: values.primaryClass ?? '',
              onChange: e =>
                setValues({ ...values, primaryClass: e.target.value }),
            }}
          />
          <InputGroup
            className="flex-1"
            label="Sub risk 1"
            inputType="select"
            locked={values.readOnly?.secondaryHazardClass}
            inputProps={{
              options: subClassOptions,
              name: fieldId('secondaryClass'),
              value: values.secondaryClass ?? '',
              onChange: e =>
                setValues({ ...values, secondaryClass: e.target.value }),
            }}
          />
          <InputGroup
            className="flex-1"
            label="Sub risk 2"
            inputType="select"
            locked={values.readOnly?.tertiaryHazardClass}
            inputProps={{
              options: subClassOptions,
              name: fieldId('tertiaryClass'),
              value: values.tertiaryClass ?? '',
              onChange: e =>
                setValues({ ...values, tertiaryClass: e.target.value }),
            }}
          />
        </div>
      )}

      {locations.length > 2 && (
        <div className="multi-stop-location-selection-ctn bg-skin-3 flex items-center">
          <div>This product is being picked up at</div>
          <SelectField
            className="form-control form-control--select ml-2 mr-2 flex-1"
            name={`${values.id}_pickSequence`}
            onChange={e => {
              const pickSequence = Number(e.target.value)
              let dropSequence = values.dropSequence

              if (pickSequence >= dropSequence) {
                const nextDropOptions = getDropOptions(locations, pickSequence)

                dropSequence = Number(nextDropOptions[0].value)
              }

              setValues({
                ...values,
                pickSequence,
                dropSequence,
              })
            }}
            options={getPickOptions(locations)}
            value={values.pickSequence.toString()}
          />
          <div>and delivered to</div>
          <SelectField
            className="form-control form-control--select ml-2 mr-2 flex-1"
            name={`${values.id}_dropSequence`}
            onChange={e => {
              const dropSequence = Number(e.target.value)

              setValues({
                ...values,
                dropSequence,
              })
            }}
            options={getDropOptions(locations, values.pickSequence)}
            value={values.dropSequence.toString()}
          />
        </div>
      )}
    </div>
  )
}

function getOptionLabel(
  { company, city, state, postalCode, sequence }: ItemFieldsLocation,
  lastSequence: number,
) {
  if (postalCode) {
    let label = `${city} ${state}, ${postalCode}`
    if (company) label = `${company}, ${label}`
    return label
  }

  if (sequence === 0) return 'Origin'
  if (sequence === lastSequence) return 'Destination'
  return `Stop ${sequence}`
}

function getPickOptions(locations: ItemFieldsLocation[]) {
  const lastSequence = locations.length - 1

  return locations
    .filter(x => x.stopType !== 'delivery')
    .map(x => ({
      text: getOptionLabel(x, lastSequence),
      value: x.sequence.toString(),
    }))
}

function getDropOptions(locations: ItemFieldsLocation[], pickSequence: number) {
  const lastSequence = locations.length - 1

  return locations
    .filter(x => x.stopType !== 'pickup' && x.sequence > pickSequence)
    .map(x => ({
      text: getOptionLabel(x, lastSequence),
      value: x.sequence.toString(),
    }))
}
