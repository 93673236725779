import { useMutation, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { type Path } from 'react-hook-form'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  getPaths,
  getPathValues,
  setPathValues,
} from '@fv/client-core'
import { type ShipperAccountSettingsModel } from '@fv/models'

import { type Flatten } from '../../../../../../../packages/client-types/src/lib/Flatten'
import { apiUri, supportMessage } from '../../../constants'
import {
  type UserSettings,
  userSettingsKey,
} from '../../../hooks/settings/useUserSettings'
import { type ApiError } from '../../../types/ApiError'
import { type DistanceProviderConfig, findProvider } from './types'

type AccountSettingsMutation = {
  model: ShipperAccountSettingsModel
  updates: Partial<Flatten<ShipperAccountSettingsModel>>
}

const executeMutation = (mutation: AccountSettingsMutation) => {
  const model = cloneDeep(mutation.model)
  setPathValues(model, mutation.updates)
  return model
}

const getCurrentValues = (
  model: ShipperAccountSettingsModel | undefined,
  updates: Partial<Flatten<ShipperAccountSettingsModel>>,
): Partial<Flatten<ShipperAccountSettingsModel>> | undefined =>
  model && getPathValues(model, getPaths(updates))

const accountSettingsPut = async (
  mutation: AccountSettingsMutation,
): Promise<ShipperAccountSettingsModel> => {
  const reqModel = executeMutation(mutation)
  const endpoint = `${apiUri}/accounts/settings`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(reqModel),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export const useAccountSettingMutation = (
  model: ShipperAccountSettingsModel,
  notify?: boolean,
) => {
  const queryClient = useQueryClient()
  const mutation = useMutation(accountSettingsPut, {
    onMutate(mutation) {
      const previousValue =
        queryClient.getQueryData<UserSettings>(userSettingsKey)

      queryClient.setQueryData<UserSettings>(userSettingsKey, prev => {
        //optimistic update
        return (
          prev && {
            ...prev,
            settings: executeMutation({ ...mutation, model: prev.settings }),
          }
        )
      })
      return getCurrentValues(previousValue?.settings, mutation.updates)
    },
    onError(e, data, context) {
      if (context)
        queryClient.setQueryData<UserSettings>( //revert optimistic update
          userSettingsKey,
          prev =>
            prev && {
              ...prev,
              settings: executeMutation({
                model: prev.settings,
                updates: context,
              }),
            },
        )
    },
    onSettled(_data, error: ApiError, _variables, _context) {
      if (error && notify) {
        toast.error(
          'Unable to update setting' +
            (error.message ? `: ${error.message}` : `, ${supportMessage}`),
        )
      } else if (notify) {
        toast.success('Setting updated successfully.')
      }
    },
  })

  const updateAccountSetting = <TValue>(
    key: Path<ShipperAccountSettingsModel>,
    value: TValue,
  ) =>
    mutation.mutateAsync({
      model,
      updates: {
        [key]: value,
      },
    })
  const updateAccountSettings = (
    updates: Partial<Flatten<ShipperAccountSettingsModel>>,
  ) => mutation.mutateAsync({ model, updates })

  const updateRouteSettings = (
    mutation: Partial<Flatten<ShipperAccountSettingsModel>>,
    providers: DistanceProviderConfig[],
  ) => {
    if (mutation['routing.distanceProvider']) {
      const selectedProvider = findProvider(
        providers,
        mutation['routing.distanceProvider'],
      )
      mutation = {
        ...mutation,
        'routing.distanceProviderVersion': selectedProvider?.versions[0],
      }
    }
    updateAccountSettings(mutation)
  }

  return {
    isBusy: mutation.isLoading,
    updateAccountSetting,
    updateAccountSettings,
    updateRouteSettings,
  }
}
