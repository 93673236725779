import clsx from 'clsx'
import { type ChangeEvent, Fragment, type MouseEvent, useState } from 'react'
import toast from 'react-hot-toast'

import { Icon, SelectField } from '@fv/client-components'
import { type EquipmentType } from '@fv/models'

import { countLoads } from '../../features/quote-request/quoteRequestUtils'
import {
  useQuoteFormActions,
  useQuoteFormLoads,
} from '../../features/quote-request/useQuoteFormState'
import { useFavoriteEquipmentTypes } from '../../features/settings/user-preferences/hooks'
import { useQuoteSettings } from '../../hooks/settings'
import arrayOfLength from '../../utils/arrayOfLength'
import InfoBox from '../InfoBox'
import CheckboxField from '../inputs/CheckboxField'
import TruckSelector from '../shared/TruckSelector'
import { EquipmentConfig } from './EquipmentConfig'

const truckQuantityOptions = arrayOfLength(6).map(String)

type Props = {
  isEditing: boolean
}

export const EquipmentList = ({ isEditing }: Props) => {
  const { activateLoad, addLoads, removeLoads } = useQuoteFormActions()
  const [isShowingAll, setShowingAll] = useState(false)
  const buttonLabel = isShowingAll ? 'Favorite equipment' : 'More equipment'
  const quoteSettingsQuery = useQuoteSettings('truckload')
  const equipmentOptions = quoteSettingsQuery.data?.equipmentOptions ?? []
  const loads = useQuoteFormLoads()
  const loadCount = countLoads(loads)
  const favoriteTypes = useFavoriteEquipmentTypes()
  const hasNonFavoriteEquipment = Array.from(loads.keys()).some(
    t => !favoriteTypes.includes(t),
  )

  // List always expanded if non-favorite selected to allow form validation
  const filteredOptions = equipmentOptions.filter(
    o =>
      isShowingAll || hasNonFavoriteEquipment || favoriteTypes.includes(o.key),
  )

  if (!equipmentOptions.length || (isEditing && !loadCount)) {
    return (
      <div className="bg-fv-blue-50 px-6 b1350:row-start-3 pt-8 pb-12 overflow-auto border-fv-blue-100 border-r ">
        <InfoBox icon="spinner">Loading...</InfoBox>
      </div>
    )
  }

  function toggleShowingAll(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    const equipmentToRemove: EquipmentType[] = []

    if (isShowingAll) {
      for (const type of loads.keys()) {
        const equipment = equipmentOptions.find(o => o.key === type)
        if (!equipment?.favorite) equipmentToRemove.push(type)
      }
    }

    setShowingAll(!isShowingAll)
    equipmentToRemove.forEach(type => removeLoads(type))
  }

  return (
    <div className="bg-fv-blue-50 b1350:row-start-3 px-6 pt-8 pb-12 overflow-auto border-fv-blue-150 border-r">
      <header className="flex">
        <h6 className="required">Equipment type</h6>
        <h6 className="required ml-auto">Trucks</h6>
      </header>

      <div className="equipment-types">
        {filteredOptions.map(equipmentType => {
          const equipmentKey = equipmentType.key
          const equipmentName = equipmentType.name
          const equipmentOfType = loads.get(equipmentKey)
          const equipmentCount = equipmentOfType?.length ?? 0
          const isChecked = equipmentCount > 0
          const activeIndex =
            equipmentOfType?.findIndex(e => e.isActiveInUI) ?? 0

          return (
            <Fragment key={equipmentKey}>
              <div
                className={clsx('quote-equipment-type-group', {
                  active: isChecked,
                })}
                data-cy="equipment-form"
              >
                <div
                  className="quote-equipment-type items-center"
                  title={equipmentName}
                >
                  <CheckboxField
                    checked={isChecked}
                    className="checkbox"
                    label={equipmentName}
                    name={equipmentKey}
                    labelClassName="limit-characters"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        addLoads(equipmentKey)
                      } else if (isEditing && loadCount <= 1) {
                        toast.error('Must select at least one equipment type.')
                      } else {
                        removeLoads(equipmentKey)
                      }
                    }}
                  />

                  <div className="ml-2 h-0 flex-1 border-t border-dashed border-t-gray-300" />

                  <SelectField
                    className="form-control form-control--select form-control--small ml-3 w-auto"
                    name={`${equipmentName}_quantity`}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      const quantity = Number(e.target.value)

                      if (quantity > equipmentCount) {
                        addLoads(equipmentKey, quantity - equipmentCount)
                      }

                      if (quantity < equipmentCount) {
                        removeLoads(
                          equipmentKey,
                          quantity,
                          equipmentCount - quantity,
                        )
                      }
                    }}
                    options={truckQuantityOptions}
                    value={String(equipmentCount)}
                  />
                </div>

                {equipmentCount > 0 && (
                  <div className="flex items-center gap-x-1">
                    <span className="mr-1">Truck</span>

                    {equipmentOfType?.map((e, i) => (
                      <TruckSelector
                        active={e.isActiveInUI}
                        key={e.loadId}
                        selectTruck={() => activateLoad(e.type, e.loadId)}
                        truckNumber={i + 1}
                      />
                    ))}

                    <div className="flex-pinline flex-1" />
                    <a
                      className="col-auto"
                      href="#"
                      onClick={e => {
                        e.preventDefault()

                        const startIndex = equipmentOfType?.findIndex(
                          e => e.isActiveInUI,
                        )

                        removeLoads(equipmentKey, startIndex, 1)
                      }}
                    >
                      <Icon icon="times" />
                    </a>
                  </div>
                )}

                {equipmentOfType?.map(e => (
                  <EquipmentConfig
                    accessorialOptions={equipmentType.accessorialOptions}
                    altEquipmentOptions={equipmentOptions
                      .filter(o => o.key !== equipmentKey)
                      .map(o => ({
                        label: o.name,
                        value: o.key,
                      }))}
                    key={e.loadId}
                    load={e}
                  />
                ))}
              </div>

              {equipmentCount > 1 && (
                <a
                  className="next-truck-action"
                  href="#"
                  onClick={e => {
                    e.preventDefault()

                    const nextTruck =
                      equipmentOfType?.[activeIndex + 1] ?? equipmentOfType?.[0]

                    nextTruck && activateLoad(nextTruck.type, nextTruck.loadId)
                  }}
                >
                  <Icon icon="truck-moving" />
                  <span>Next truck</span>
                  <Icon icon="arrow-right" className="ml-2" />
                </a>
              )}
            </Fragment>
          )
        })}
      </div>

      {!hasNonFavoriteEquipment && (
        <div className="border-fv-blue-400 border-t border-dashed pt-4">
          <a href="#" className="icon-text-link" onClick={toggleShowingAll}>
            <Icon icon="arrows-alt-v" />
            <span>{buttonLabel}</span>
          </a>
        </div>
      )}
    </div>
  )
}
