import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import { type PropsWithChildren, useState } from 'react'

import { FvButton, type IconName } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import {
  type FilterType,
  MiniQuoteList,
} from '../../features/mini-quote-list/MiniQuoteList'
import { NoteList } from './NoteList'
import { ShipmentTags } from './ShipmentTags'

type MessageFilterBtnProps = PropsWithChildren<{
  active?: boolean
  onClick: () => void
  icon: IconName
}>
const MessageFilterBtn = ({
  icon,
  active,
  onClick,
  children,
}: MessageFilterBtnProps) => {
  return (
    <FvButton
      icon={icon}
      transform="down-1"
      onClick={onClick}
      className={clsx('px-3 py-1 rounded-full', {
        'bg-fv-blue-100 border border-fv-blue-500': active,
        'border border-fv-gray': !active,
      })}
    >
      {children}
    </FvButton>
  )
}

type PanelName = 'messages' | 'notes' | 'tags'
type MessagesNotesTagsPanelProps = {
  closePanel?: () => void
  load: FullShipment
  className?: string
  navClass?: string
  contentClass?: string
}

export const MessagesNotesTagsControls = ({
  closePanel,
  load,
  className,
  navClass,
  contentClass,
}: MessagesNotesTagsPanelProps) => {
  const [activePanel, setActivePanel] = useState<PanelName>('messages')
  const [messageFilter, setMessageFilter] = useState<FilterType>('active')
  const { loadId, selectedQuote } = load

  return (
    <div className={className}>
      <nav
        className={clsx(
          'divided-content',
          'divided-content--start [&>button]:ml-0',
          navClass,
        )}
      >
        {closePanel && (
          <FvButton icon="arrow-to-left" onClick={closePanel}>
            Back
          </FvButton>
        )}
        <FvButton
          className={clsx({
            'active-hint-indicator': activePanel === 'messages',
          })}
          onClick={() => setActivePanel('messages')}
          icon="comment-alt"
          transform="down-1"
        >
          Messages
        </FvButton>
        <FvButton
          className={clsx({
            'active-hint-indicator': activePanel === 'notes',
          })}
          onClick={() => setActivePanel('notes')}
          icon="sticky-note"
        >
          Notes
        </FvButton>
        <FvButton
          className={clsx({
            'active-hint-indicator': activePanel === 'tags',
          })}
          onClick={() => setActivePanel('tags')}
          icon="tags"
        >
          Tags
        </FvButton>
      </nav>
      <div className={clsx('overflow-auto', contentClass)}>
        {activePanel === 'messages' && (
          <>
            <div className="border gap-x-3 border-fv-gray p-2 rounded-full mb-4 flex [&>button]:ml-0 bg-fv-blue-25">
              <MessageFilterBtn
                icon="comment-alt-dots"
                onClick={() => setMessageFilter('active')}
                active={messageFilter === 'active'}
              >
                Active
              </MessageFilterBtn>
              <MessageFilterBtn
                icon="comment-alt-minus"
                onClick={() => setMessageFilter('pending')}
                active={messageFilter === 'pending'}
              >
                Inactive
              </MessageFilterBtn>
              <MessageFilterBtn
                icon="comment-alt-slash"
                onClick={() => setMessageFilter('declined')}
                active={messageFilter === 'declined'}
              >
                Declined
              </MessageFilterBtn>
            </div>

            <MiniQuoteList
              filter={messageFilter}
              key={selectedQuote?._id}
              load={load}
              readOnly
              initialActiveId={selectedQuote?._id}
              sort={r =>
                orderBy(
                  r,
                  [r => r.status === 'awarded', r => r.amount, r => r.name],
                  ['desc', 'desc', 'asc'],
                )
              }
            />
          </>
        )}

        {activePanel === 'notes' && <NoteList loadId={loadId} />}

        {activePanel === 'tags' && <ShipmentTags loadId={loadId} />}
      </div>
    </div>
  )
}
