export function migrateParams(
  key: string,
  source: URLSearchParams,
  target: URLSearchParams,
) {
  if (key.slice(-2) === '[]') {
    source.getAll(key).forEach(v => {
      target.append(key, v)
    })
  } else {
    const value = source.get(key)
    value && target.set(key, value)
  }
}
