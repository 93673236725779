import {
  FvButton,
  FvLinkButton,
  Icon,
  SliderPanel,
} from '@fv/client-components'
import { getEquipmentLabel } from '@fv/client-core'
import { type FullShipment, type Workflow } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { useTypeNames } from '../../hooks/settings'
import { useSpotQuoteEmailPreview } from '../../hooks/spotQuotes'
import { routes } from '../../routes'
import { SentQuoteRequestsHeader } from './SentQuoteRequestsHeader'
import { type SpotQuoteFormData } from './types'

type SpotQuoteEmailPreviewProps = {
  closePanel: () => void
  isOpen: boolean
  loadId: string
  shipperMessage?: string
  systemMessage: string
}

export const SpotQuoteEmailPreview = ({
  closePanel,
  isOpen,
  loadId,
  shipperMessage,
  systemMessage,
}: SpotQuoteEmailPreviewProps) => {
  const previewQuery = useSpotQuoteEmailPreview({
    isOpen,
    loadId,
    shipperMessage,
    systemMessage,
  })

  const isLoading = previewQuery.isLoading && previewQuery.isFetching

  return (
    <SliderPanel closePanel={closePanel} isOpen={isOpen}>
      <div className="mx-0 -mt-4 mb-4 flex items-center">
        <h6 className="mb-0">
          <Icon
            icon="envelope"
            className="text-fv-gray-dark"
            transform="down-1"
          />
          <span>Email preview</span>
        </h6>

        <FvButton
          className="ml-auto"
          icon="times"
          onClick={closePanel}
          theme="plain"
        >
          Close
        </FvButton>
      </div>

      {isLoading && (
        <div className="flex justify-center">
          <Icon icon="sync" size="2x" />
        </div>
      )}

      {!isLoading && previewQuery.data && (
        <div
          className="spot-quote-email-preview-ctn mb-8"
          dangerouslySetInnerHTML={{ __html: previewQuery.data }}
        />
      )}
    </SliderPanel>
  )
}

type SpotQuoteEmailConfirmationProps = {
  closePanel: () => void
  emails?: string[]
  isOpen: boolean
  workflow: Workflow
}

export const SpotQuoteEmailConfirmation = ({
  closePanel,
  emails,
  isOpen,
  workflow,
}: SpotQuoteEmailConfirmationProps) => {
  return (
    <SliderPanel
      className="flex flex-col items-stretch"
      closePanel={closePanel}
      isOpen={isOpen}
    >
      <SentQuoteRequestsHeader emails={emails} />

      <div>
        <hr />
        <div className="help-box">
          <Icon
            icon="hand-paper"
            transform="down-2"
            className="fa-fw text-fv-orange"
          />
          <p className="mb-0">
            You will be able to see quotes and messages for each truck as they
            come in from your 'In process' page. You can also turn on
            notifications to be notified each time a new message or quote comes
            in.
          </p>
        </div>

        <hr className="mb-8" />
        <label className="label">Where would you like to go now?</label>
        <hr />

        <nav className="divided-content divided-content--start">
          <FvLinkButton icon="usd-square" to={routes.quote(workflow)}>
            Quote again
          </FvLinkButton>

          <FvLinkButton icon="warehouse" to="/pending">
            Go to In process
          </FvLinkButton>

          <FvLinkButton icon="times" onClick={closePanel}>
            Stay here, close this panel
          </FvLinkButton>
        </nav>
      </div>
    </SliderPanel>
  )
}

export function useSpotQuoteEmailMessage(
  loads?: FullShipment[],
  altEquipmentTypes?: EquipmentType[],
  formData?: SpotQuoteFormData[],
) {
  const { equipmentName } = useTypeNames()

  if (!loads?.length || !formData?.length) return ''
  const equipmentTypes = loads.map(s => s.equipment.type) ?? []
  const equipmentNames = equipmentTypes.map(t => equipmentName(t))

  if (equipmentNames.length === 1) {
    const altTypes =
      altEquipmentTypes?.filter(t => t !== loads[0].equipment.type) ??
      loads[0].equipment.alternateTypes

    const equipmentLabel = getEquipmentLabel({
      altEquipmentNames: altTypes?.map(equipmentName),
      equipmentName: equipmentNames[0],
    })

    let targetPriceMessage = ''

    if (formData[0].targetPrice) {
      targetPriceMessage = ` Target price: $${formData[0].targetPrice}.`
    }

    let bidExpireDateMessage = ''

    if (formData[0].bidExpiration?.local) {
      bidExpireDateMessage = ` Bidding ends on ${formData[0].bidExpiration.local}.`
    }

    let quoteMessage = `I need a spot quote for this shipment using a ${equipmentLabel}.`
    quoteMessage += `${bidExpireDateMessage}`
    quoteMessage += `${targetPriceMessage}`

    return quoteMessage
  }

  let multiTruckTargetPriceMsg = ''
  const loadsWithTargets = formData
    .map((load, i) => ({ targetPrice: load.targetPrice, truckNumber: i + 1 }))
    .filter(({ targetPrice }) => !!targetPrice)

  if (loadsWithTargets.length) {
    multiTruckTargetPriceMsg = loadsWithTargets
      .map(
        ({ truckNumber, targetPrice }) =>
          `Target Price for Truck ${truckNumber}: $${targetPrice}.`,
      )
      .join('\n')
  }

  let multiTruckBidExpireMsg = ''
  const loadsWithBidExpireDates = formData
    .map((load, i) => ({
      bidExpirationDate: load.bidExpiration?.local,
      truckNumber: i + 1,
    }))
    .filter(({ bidExpirationDate }) => !!bidExpirationDate)

  if (loadsWithBidExpireDates.length) {
    multiTruckBidExpireMsg = loadsWithBidExpireDates
      .map(
        ({ truckNumber, bidExpirationDate }) =>
          `Bid for Truck ${truckNumber} ends on: ${bidExpirationDate}.`,
      )
      .join('\n')
  }

  const truckList = equipmentNames
    .map((name, i) => `Truck ${i + 1} / ${name}`)
    .join('. ')

  return [
    `I need spot quotes for this shipment that contains multiple trucks. ${truckList}. You can quote for individual trucks or all of them.`,
    multiTruckBidExpireMsg,
    multiTruckTargetPriceMsg,
  ]
    .filter(t => !!t)
    .join('\n')
}
