import pluralize from 'pluralize'
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import { FvButton, OlarkHelper, useFvNavigate } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { supportMessage } from '../../constants'
import { HasFeature, HasPermission } from '../../features/auth'
import { AddRateButton } from '../../features/quote/ManualRatePanel'
import { useQuoteRequest } from '../../features/quote-request/useQuoteRequest'
import { SpotQuotePanel } from '../../features/spot-quote/SpotQuotePanel'
import {
  type QuoteRequestResponses,
  useQuoteRequestResponses,
} from '../../hooks/shipments/useQuoteRequestResponses'
import { useSpotQuoteRecipients } from '../../hooks/spotQuotes'
import { routes, useHomePage } from '../../routes'
import InfoBox from '../InfoBox'
import { LoadSummary } from '../LoadSummary/LoadSummary'
import MessageField from '../messaging/MessageField'
import MessageList from '../messaging/MessageList'
import { ResponseList } from '../ResponseList'
import { TruckloadAwardConfirmation } from '../TruckloadAwardConfirmation'

export type ListStatus = keyof QuoteRequestResponses

type Props = {
  loads: FullShipment[]
  quoteRequestId?: string
}

const RequestDetail = ({ loads, quoteRequestId }: Props) => {
  const navigate = useFvNavigate()
  const [isAwardingCarrier, setAwardingCarrier] = useState(false)
  const [truckIndex, setTruckIndex] = useState(0)
  const selectTruck = useCallback((index: number) => setTruckIndex(index), [])
  const homePage = useHomePage()

  const activeLoad = loads?.[truckIndex]
  const loadId = activeLoad?.loadId
  const recipientsQuery = useSpotQuoteRecipients(loadId)
  const responses = useQuoteRequestResponses({
    includePendingApiRates: true,
    load: activeLoad,
  })

  const [filter, setFilter] = useState<ListStatus>('active')
  const [listState, setListState] = useListState({
    filter,
    loadId,
    loads,
    responses,
  })

  const activeId = loadId ? listState[loadId]?.[filter] : ''
  const activeData = responses[filter].find(r => r.id === activeId)
  const hasAwardedEquipment =
    loads?.some(
      load => load.status === 'awarded' || load.status === 'confirmed',
    ) ?? false

  const recipientUserId = recipientsQuery.data?.find(
    r => r.carrierId === activeData?.carrierId,
  )?.userId
  const loadsToBook = loads.filter(l => l.status === 'pending')

  // Don't count quotes that have already been awarded
  const numberOfSelectedCarriers = Object.keys(listState).filter(loadId => {
    const currentLoad = loads?.find(load => load.loadId === loadId)

    return (
      currentLoad?.status !== 'awarded' &&
      currentLoad?.status !== 'confirmed' &&
      listState[loadId]?.selected
    )
  }).length

  function onManualRateError(loadId: string) {
    setListState(prev => ({
      ...prev,
      [loadId]: {
        ...prev[loadId],
        active: '',
      },
    }))
  }

  function onManualRateSuccess(loadId: string, quoteId: string) {
    setListState(prev => ({
      ...prev,
      [loadId]: {
        ...prev[loadId],
        active: quoteId,
      },
    }))

    setFilter('active')
  }

  return (
    <HasFeature name="truckLoad" redirect={homePage}>
      <LoadSummary
        activeIndex={truckIndex}
        hasAwardedEquipment={hasAwardedEquipment}
        load={activeLoad}
        numberOfTrucks={loads?.length ?? 0}
        selectTruck={selectTruck}
      />

      <div className="flex flex-col overflow-auto b1450:col-span-2">
        <div className="flex flex-shrink flex-grow flex-col overflow-auto bg-fv-gray-50">
          {loadId && (
            <ResponseList
              filter={filter}
              listState={listState}
              loadId={loadId}
              loads={loads ?? []}
              responses={responses}
              selectTruck={selectTruck}
              setFilter={setFilter}
              setListState={setListState}
            />
          )}

          <div className="flex flex-initial flex-nowrap items-center bg-fv-gray-50 px-8 py-7">
            <AddRateButton
              className="standard-link"
              icon="pen"
              load={activeLoad}
              onError={onManualRateError}
              onSuccess={onManualRateSuccess}
            >
              Manually enter rate
            </AddRateButton>

            <HasPermission name="book">
              <FvButton
                className="ml-auto"
                theme="primary"
                fwd
                disabled={numberOfSelectedCarriers < 1}
                onClick={() => setAwardingCarrier(true)}
                icon="trophy"
                transform="up-1"
              >
                <span>
                  Award selected{' '}
                  {pluralize(
                    'carrier',
                    numberOfSelectedCarriers > 0
                      ? numberOfSelectedCarriers
                      : (loads?.length ?? 0),
                  )}
                </span>
              </FvButton>
            </HasPermission>
          </div>
        </div>

        <SpotQuotePanel
          activeLoadIndex={truckIndex}
          defaultOpen
          label="Send out for more quotes via email"
          loads={loads}
          onSuccess={() => setFilter('pending')}
          selectTruck={selectTruck}
        />
      </div>

      <div className="quote-request-messages-ctn">
        {activeData && loadId ? (
          <>
            {activeData.status === 'error' && (
              <InfoBox icon="exclamation-triangle">
                {activeData.errors?.map((e: any) => e.message).join(', ') ??
                  'Unknown error, please contact support.'}
              </InfoBox>
            )}

            <h6>Messages with {activeData.name}</h6>

            <MessageField
              carrierId={activeData.carrierId}
              loadId={loadId}
              userId={activeData.userId || recipientUserId}
            />

            <MessageList
              carrierId={activeData.carrierId}
              isApiMessages={activeData.method === 'api'}
              loadId={loadId}
              userId={activeData.userId || recipientUserId}
            />
          </>
        ) : (
          <InfoBox>
            We don't have any messages to show right now. Once a carrier starts
            bidding or communicating, their messages will appear here.
          </InfoBox>
        )}
      </div>

      {quoteRequestId && (
        <TruckloadAwardConfirmation
          loadsToBook={loads.map(l => ({
            ...l,
            quoteId: listState[l.loadId]?.selected,
          }))}
          isOpen={isAwardingCarrier}
          closePanel={() => setAwardingCarrier(false)}
          onSubmit={() => {
            const selectedQuotes = Object.fromEntries(
              loadsToBook
                .filter(l => listState[l.loadId]?.selected)
                .map(l => [l.loadId, listState[l.loadId]?.selected]),
            )
            navigate(
              routes.bookTruckload({
                quoteRequestId,
                selectedQuotes,
              }),
            )
          }}
          showUnawarded={true}
        />
      )}
    </HasFeature>
  )
}

const QuoteRequestDetail = () => {
  const { quoteRequestId } = useParams<{ quoteRequestId: string }>()
  const quoteRequestQuery = useQuoteRequest(quoteRequestId)
  const homePage = useHomePage()
  const navigate = useFvNavigate()
  const isError = quoteRequestQuery.isError
  const isLoading = quoteRequestQuery.isLoading
  const loads = quoteRequestQuery.data

  useEffect(() => {
    if (isLoading || loads?.length) return
    toast.error('No shipments found on quote request.')
    navigate('/pending')
  }, [isLoading, loads?.length, navigate])

  useEffect(() => {
    if (!loads?.length) return
    // Clicking "Start over" in `LoadSummary` cancels the
    // quote request and removes all shipments from the DB.
    // Setting the quote request in localStorage to access
    // from the quote page instead of trying to refetch it.
    localStorage.setItem('quoteRequestTemplate', JSON.stringify(loads))
  }, [loads])

  return (
    <HasFeature name="truckLoad" redirect={homePage}>
      {!loads?.length ? (
        <>
          <LoadSummary
            activeIndex={0}
            numberOfTrucks={0}
            selectTruck={() => null}
          />
          <div className="pending-detail-rates-sq-ctn">
            {isError ? (
              <InfoBox icon="ban">
                Unable to load quote request, {supportMessage}
              </InfoBox>
            ) : (
              <InfoBox icon="spinner">Loading...</InfoBox>
            )}
          </div>
          <div className="messages-ctn" />
        </>
      ) : (
        <>
          <RequestDetail loads={loads} quoteRequestId={quoteRequestId} />
          <OlarkHelper loadId={loads?.[0]?.loadId} />
        </>
      )}
    </HasFeature>
  )
}

export type ListState = {
  [key: string]: {
    active: string
    declined: string
    pending: string
    selected: string
  }
}

function useListState(opts: {
  filter: ListStatus
  loadId?: string
  loads?: FullShipment[]
  responses: QuoteRequestResponses
}): [ListState, Dispatch<SetStateAction<ListState>>] {
  const [listState, setListState] = useState<ListState>({})
  const { filter, loadId, loads, responses } = opts

  // Initialize state after fetching loads
  useEffect(() => {
    if (Object.keys(listState).length > 0 || !loads) return

    const initialState: ListState = {}

    loads.forEach(load => {
      initialState[load.loadId] = {
        active: '',
        declined: '',
        pending: '',
        selected: load.selectedQuote?._id ?? '',
      }
    })

    setListState(initialState)
  }, [listState, loads])

  // Ensure active row in each filtered view
  useEffect(() => {
    if (!loadId) return

    setListState(prev => {
      const activeId = prev[loadId]?.[filter]

      if (activeId) {
        // Check that `activeId` is in the list
        const activeData = responses[filter].find(d => d.id === activeId)
        if (activeData) return prev
      }

      const firstData = responses[filter][0]

      return {
        ...prev,
        [loadId]: {
          ...prev[loadId],
          [filter]: firstData?.id ?? '',
        },
      }
    })
  }, [loadId, filter, responses])

  return [listState, setListState]
}

export default QuoteRequestDetail
