import { type Dispatch, Fragment, type SetStateAction } from 'react'

import { Icon } from '@fv/client-components'
import { type ShipmentLocation } from '@fv/client-types'

type Props = {
  currentSequence: number
  locations: ShipmentLocation[]
  setSequence: Dispatch<SetStateAction<string>>
}

export const UpdateTrackingSequenceForm = ({
  currentSequence,
  locations,
  setSequence,
}: Props) => {
  const sequenceOptions = buildSequenceOptions(locations)

  return (
    <div>
      <div className="update-tracking-locations">
        {sequenceOptions.map(o => {
          const id = `stop-${o.value}`

          return (
            <Fragment key={id}>
              <div className="flex items-center">
                <div className="update-tracking-location limit-characters flex-1">
                  <Icon icon="building" className="text-fv-gray-dark fa-fw" />
                  <span>{o.location}</span>
                </div>
                <div className="radio update-tracking-locations__radio flex items-center">
                  <input
                    checked={o.value === currentSequence}
                    className="radio__input"
                    id={id}
                    name="trackingSequence"
                    onChange={() => setSequence(o.value.toString())}
                    type="radio"
                  />
                  <label className="radio__display" htmlFor={id} />
                  <label className="radio__label" htmlFor={id}>
                    {o.status}
                  </label>
                </div>
              </div>

              {o.value < locations.length - 1 && (
                <div className="mx-0 my-2 flex items-center">
                  <div className="flex-pinline" />
                  <Icon icon="arrow-down" className="text-fv-green" />
                  <div className="flex-pinline" />
                </div>
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

function buildSequenceOptions(locations: ShipmentLocation[]) {
  const options = [
    {
      location: 'Ready for pickup',
      status: 'Pending',
      value: -1,
    },
  ]

  locations.forEach(x => {
    const isOrigin = x.sequence === 0
    const isDest = x.sequence === locations.length - 1

    options.push({
      location: `${x.company}, ${x.city} ${x.state} ${x.postalCode}`,
      status: isOrigin ? 'Picked Up' : isDest ? 'Delivered' : 'Completed',
      value: x.sequence,
    })
  })

  return options
}
