import { useQuery } from '@tanstack/react-query'

import { type ShipperUserSettings } from '@fv/models'
import { type DTO } from '@fv/models/core'

import { shipperFetch } from '../../utils/shipperFetch'

export const userSettingsKey = ['user-settings']

export type UserSettings = DTO<ShipperUserSettings>

const fetchUserSettings = async (): Promise<UserSettings> =>
  shipperFetch<UserSettings>(`/context/user-settings`)

export function useUserSettings(enabled = true) {
  return useQuery(userSettingsKey, fetchUserSettings, {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
