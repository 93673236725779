import { all } from '@freightview/holidays'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { FvButton, Icon, type IconName } from '@fv/client-components'
import {
  type SystemGeneratedNotification,
  type SystemNotification,
  type SystemNotificationType,
} from '@fv/models'
import { type DTO } from '@fv/models/core'

import { useSystemNotifications } from './queries'
import { getHolidayMessage } from './utils'

export const SystemNotifications = () => {
  const { isLoading, data: notifications } = useSystemNotifications()
  const [seenNotifications, setSeenNotifications] = useLocalStorage<string[]>(
    'system-notifications',
    [],
    {
      deserializer: v => {
        if (!v) return []
        try {
          return JSON.parse(v)
        } catch {
          return []
        }
      },
    },
  )
  useEffect(() => {
    // make sure we don't fill up local storage ... 500 seems like a reasonable limit
    if (seenNotifications.length > 500) {
      setSeenNotifications([])
    }
  }, [seenNotifications, setSeenNotifications])

  if (isLoading) return null

  const notificationsToShow =
    notifications?.filter(n => !seenNotifications.includes(n._id)) ?? []
  const today = dayjs()
  const holidaysToShow = all()
    .filter(h =>
      h.observed.some(d => {
        const day = dayjs.utc(d)
        return (
          today.isAfter(day.subtract(7, 'days'), 'day') &&
          today.isBefore(day.add(1, 'days'), 'day')
        )
      }),
    )
    .map<SystemGeneratedNotification>(holiday => ({
      _id: `holiday-${holiday.key}-${today.format('YYYY')}`,
      expiration: today.add(7, 'days').toDate(),
      text: getHolidayMessage(holiday),
      type: 'holiday',
    }))
    .filter(n => !seenNotifications.includes(n._id))

  const allNotifications = [...notificationsToShow, ...holidaysToShow]
  if (!allNotifications.length) return null

  return (
    <div>
      {allNotifications.map(n => (
        <SystemNotificationBanner
          notification={n}
          key={n._id}
          onHide={() => setSeenNotifications(p => [...p, n._id])}
        />
      ))}
    </div>
  )
}

type ThemeConfig = Record<
  SystemNotificationType,
  {
    themeIcon: IconName
  }
>

const themeConfig: ThemeConfig = {
  default: {
    themeIcon: 'sticky-note',
  },
  warning: {
    themeIcon: 'hand-paper',
  },
  success: {
    themeIcon: 'check',
  },
  holiday: {
    themeIcon: 'calendar-day',
  },
  info: {
    themeIcon: 'info-circle',
  },
  release: {
    themeIcon: 'info-circle',
  },
}
type Props = {
  notification: DTO<SystemNotification> | SystemGeneratedNotification
  onHide: () => void
}
const SystemNotificationBanner = ({ notification, onHide }: Props) => {
  const type = notification.type
  const themeIcon = themeConfig[type].themeIcon

  return (
    <div>
      <div
        className={
          clsx()
          // overlay && 'fixed z-[1000] top-0 bottom-0 w-full bg-black/85',
        }
      >
        <div
          className={clsx('flex flex-wrap p-4 pr-6 border-b', {
            'bg-white border-fv-gray': type === 'default',
            'bg-[#ffeeee] border-red-200': type === 'warning',
            'bg-[#f2fbed] border-[#c3ecbe]': type === 'success',
            'bg-[#f2fbed] border-fv-beer-dark': type === 'holiday',
            'bg-fv-blue-50 border-fv-blue-200':
              type === 'info' || type === 'release',
          })}
        >
          <div className="flex items-start w-full">
            <p className="mb-0 flex items-start">
              <Icon
                icon={themeIcon}
                className={clsx(`fa-fw`, {
                  'text-fv-gray': type === 'default',
                  'text-fv-orange': type === 'warning',
                  'text-fv-green': type === 'success',
                  'text-fv-beer': type === 'holiday',
                  'text-fv-blue': type === 'info' || type === 'release',
                })}
                transform="down-2"
              />
              <span
                className="ml-2"
                dangerouslySetInnerHTML={{
                  __html: notification.text.replace(
                    /\[(.+)\]\((.+)\)/,
                    '<a href="$2" class="text-fv-blue" target="_blank">$1</a>',
                  ),
                }}
              />
            </p>
            <div className="leading-[1.4rem] ml-auto pl-10">
              <FvButton icon="times" onClick={onHide}>
                Got it, close this message
              </FvButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
