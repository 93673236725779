import { Fragment, useEffect, useRef, useState } from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'

import {
  countLoads,
  getHasItems,
  getItemsBySequence,
} from '../../features/quote-request/quoteRequestUtils'
import {
  useQuoteFormActions,
  useQuoteFormLoads,
  useQuoteFormLocations,
} from '../../features/quote-request/useQuoteFormState'
import arrayOfLength from '../../utils/arrayOfLength'
import InfoBox from '../InfoBox'
import { ValidatedForm } from '../inputs'
import { PillHeader } from '../PillHeader'
import { QuoteItemsForm } from './QuoteItemsForm'
import { RouteStopConfig } from './RouteStopConfig'
import { RouteStopDate } from './RouteStopDate'

const maxStopCount = 12

type Props = {
  isEditing: boolean
}

export const QuoteRequestLocations = ({ isEditing }: Props) => {
  const { addStops, openQuoteItemsForm, setStopData } = useQuoteFormActions()
  const [dateSequence, setDateSequence] = useState<number | null>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const loads = useQuoteFormLoads()
  const loadCount = countLoads(loads)
  const canAddStops = loadCount < 2
  const stops = useQuoteFormLocations()
  const maxAdditionalStops = maxStopCount - stops.length
  const addStopLinks = arrayOfLength(maxAdditionalStops).map(n => n + 1)
  const hasItems = getHasItems(loads)
  const isLoading = isEditing && !loadCount

  // Set `formRef` on mount
  useEffect(() => {
    setStopData(0, { formRef: formRef.current })
  }, [setStopData])

  return (
    <>
      <ValidatedForm
        autoReportNextError={false}
        className="py-8 px-6 bg-fv-gray-100 overflow-auto b1350:row-start-3"
        ref={formRef}
      >
        <header>
          <PillHeader st className="beer-container -mt-4 mb-6">
            Truckload route
          </PillHeader>
        </header>

        {isLoading ? (
          <InfoBox icon="spinner">Loading...</InfoBox>
        ) : (
          stops.map(stop => {
            const isLastStop = stop.sequence === stops.length - 1

            return (
              <Fragment key={stop.id}>
                {isLastStop && canAddStops && maxAdditionalStops > 0 && (
                  <>
                    <div>
                      <span>
                        <Icon icon="plus" className="text-fv-gray-dark" />
                        <span>Add</span>
                      </span>
                      <span className="multistop-number-links">
                        {addStopLinks.map(quantity => (
                          <FvLinkButton
                            theme="callout"
                            key={quantity}
                            onClick={() => {
                              addStops(quantity)
                            }}
                          >
                            {quantity}
                          </FvLinkButton>
                        ))}{' '}
                        more stops for this shipment
                      </span>
                    </div>
                    <hr className="mx-0 my-6" />
                  </>
                )}

                <RouteStopConfig
                  isLastStop={isLastStop}
                  items={getItemsBySequence(loads, stop.sequence)}
                  setDatepickerSequence={setDateSequence}
                  stop={stop}
                  stops={stops}
                />

                {!isLastStop && <hr className="mx-0 my-6" />}
              </Fragment>
            )
          })
        )}

        <RouteStopDate
          onClose={() => setDateSequence(null)}
          isOpen={dateSequence !== null}
          sequence={dateSequence ?? 0}
          onChange={data => setStopData(dateSequence ?? 0, data)}
          stops={stops}
          required={dateSequence === 0}
        />
        <hr className="mx-0 my-6" />
        <FvLinkButton icon="plus" onClick={openQuoteItemsForm}>
          {hasItems ? 'Edit' : 'Add'} product dimensions / details for quote
          {!hasItems && ' (optional)'}
        </FvLinkButton>
        <hr className="mx-0 my-6" />
      </ValidatedForm>

      <QuoteItemsForm loads={loads} locations={stops} />
    </>
  )
}
