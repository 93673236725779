import { lazy, useRef } from 'react'
import { checkboxColumn, keyColumn, textColumn } from 'react-datasheet-grid'
import { useResizeObserver } from 'usehooks-ts'

import { Icon, Tooltip } from '@fv/client-components'
import { legacyShipTypes } from '@fv/client-types'

import { type AddressBookUploadProps } from './types'
import {
  type AddressBookUploadRecord,
  isValidShipType,
  rowHasData,
  rowHasError,
} from './utils'

const requiredCols: Array<keyof AddressBookUploadRecord> = [
  'address',
  'city',
  'state',
  'postalCode',
  'company',
]
const DataGrid = lazy(() =>
  import('../../components/DataGrid').then(d => ({ default: d.DataGrid })),
)

export const AddressBookUploadPreview = ({
  value,
  onChange,
}: AddressBookUploadProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { height } = useResizeObserver({
    ref,
    box: 'border-box',
  })
  return (
    <div ref={ref} className="h-full">
      <DataGrid
        height={height}
        headerRowHeight={60}
        onChange={onChange}
        value={value}
        className="address-book-upload"
        rowClassName={({ rowData }) =>
          rowHasError(rowData as AddressBookUploadRecord)
            ? 'row-error'
            : undefined
        }
        cellClassName={({ rowData, columnId }) => {
          const cellData = rowData as AddressBookUploadRecord
          const col = columnId as keyof AddressBookUploadRecord
          const invalid =
            rowHasData(cellData) &&
            ((requiredCols.includes(col) && !cellData[col]) ||
              (col === 'shipType' && !isValidShipType(cellData[col])))

          return invalid ? 'cell-error' : undefined
        }}
        columns={[
          {
            ...keyColumn('company', textColumn),
            title: 'Company *',
            minWidth: 150,
          },
          {
            ...keyColumn('address', textColumn),
            title: 'Address *',
            minWidth: 150,
          },
          {
            ...keyColumn('address2', textColumn),
            title: 'Address 2',
            minWidth: 120,
          },
          { ...keyColumn('city', textColumn), title: 'City *', minWidth: 120 },
          { ...keyColumn('state', textColumn), title: 'State *' },
          { ...keyColumn('postalCode', textColumn), title: 'Zip *' },
          { ...keyColumn('country', textColumn), title: 'Country' },
          {
            ...keyColumn('contactEmail', textColumn),
            title: 'Contact Email',
            minWidth: 135,
          },
          {
            ...keyColumn('contactPhone', textColumn),
            title: 'Contact Phone',
            minWidth: 140,
          },
          {
            ...keyColumn('contactName', textColumn),
            title: 'Contact Name',
            minWidth: 140,
          },
          {
            ...keyColumn('bolFollowers', textColumn),
            title: 'Share shipments with',
            minWidth: 200,
          },
          {
            ...keyColumn('shipType', textColumn),
            title: (
              <span>
                Location Type *{' '}
                <Tooltip
                  label={`Valid values are: ${legacyShipTypes
                    .map(v => v.value)
                    .join('; ')}`}
                >
                  <Icon icon="question-circle" />
                </Tooltip>
              </span>
            ),
            minWidth: 200,
          },
          {
            ...keyColumn('dockHoursStart', textColumn),
            title: 'Dock Hours Start',
            headerClassName: 'text-center',
            minWidth: 130,
          },
          {
            ...keyColumn('dockHoursEnd', textColumn),
            title: 'Dock Hours End',
            headerClassName: 'text-center',
            minWidth: 130,
          },
          {
            ...keyColumn('specialInstructions', textColumn),
            title: 'Special instructions',
            headerClassName: 'text-center',
            minWidth: 150,
          },
          {
            ...keyColumn('adultSignatureRequired', checkboxColumn),
            title: 'Adult Signature Required',
            headerClassName: 'text-center',
            minWidth: 175,
          },
          {
            ...keyColumn('callNotifyBeforeDelivery', checkboxColumn),
            title: 'Call/Notify Before Delivery',
            headerClassName: 'text-center',
            minWidth: 175,
          },
          {
            ...keyColumn('deliveryAppointment', checkboxColumn),
            title: 'Delivery Appointment Required',
            headerClassName: 'text-center',
            minWidth: 200,
          },
          {
            ...keyColumn('insideDelivery', checkboxColumn),
            title: 'Inside Delivery',
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('insidePickup', checkboxColumn),
            title: 'Inside Pickup',
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('liftgateDelivery', checkboxColumn),
            title: 'Liftgate Delivery',
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('liftgatePickup', checkboxColumn),
            title: 'Liftgate Pickup',
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('noSignatureRequired', checkboxColumn),
            title: 'No Signature Required',
            minWidth: 175,
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('signatureRequired', checkboxColumn),
            title: 'Signature Required',
            minWidth: 165,
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('indirectSignature', checkboxColumn),
            title: 'Indirect Signature Required',
            minWidth: 175,
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('sortAndSegregate', checkboxColumn),
            title: 'Sort and Segregate',
            minWidth: 150,
            headerClassName: 'text-center',
          },
          {
            ...keyColumn('twic', checkboxColumn),
            title: 'TWIC Required',
            minWidth: 150,
            headerClassName: 'text-center',
          },
        ]}
      />
    </div>
  )
}
