import { type PropsWithChildren } from 'react'

import InfoBox from '../../components/InfoBox'
import {
  useAppContext,
  useAppSettings,
  useUserSettings,
} from '../../hooks/settings'
import { useAccountLocations } from '../addresses/useAddress'

const style = { margin: '2rem auto', maxWidth: '11rem' }

export const SessionGate = ({ children }: PropsWithChildren) => {
  const appSettingsQuery = useAppSettings()
  const contextQuery = useAppContext()
  const userSettingsQuery = useUserSettings()
  const locationsQuery = useAccountLocations() // used by onboarding check

  const hasRequiredData =
    appSettingsQuery.isSuccess &&
    contextQuery.isSuccess &&
    userSettingsQuery.isSuccess &&
    locationsQuery.isSuccess

  return hasRequiredData ? (
    children
  ) : (
    <div style={style}>
      <InfoBox icon="sync">Authenticating</InfoBox>
    </div>
  )
}
