import dayjs from 'dayjs'

import { FvDate, Icon } from '@fv/client-components'
import {
  type EstimatedDeliveryTimeWindow,
  type FullShipment,
} from '@fv/client-types'

import { getDestination } from '../../utils/shipmentFuncs'

const buildTimeString = (timeWindow?: EstimatedDeliveryTimeWindow) => {
  const begins = timeWindow?.begins
    ? dayjs(timeWindow.begins).format('hh:mma')
    : undefined
  const ends = timeWindow?.ends
    ? dayjs(timeWindow.ends).format('hh:mma')
    : undefined

  if (begins && ends) {
    return `between ${begins} and ${ends}`
  }
  if (begins) {
    return `after ${begins}`
  }
  if (ends) {
    return `by ${ends}`
  }
  return undefined
}

type Props = {
  load: FullShipment
}
export const DeliveryDate = ({ load }: Props) => {
  const { locations } = load
  const destination = getDestination({ locations })
  let deliveryText = <span>No delivery date specified</span>
  if (destination?.stopDate) {
    deliveryText = (
      <span>
        Requested delivery of{' '}
        <FvDate val={destination.stopDate} format="ddd MMM D, YYYY" />
      </span>
    )
  }
  if (!destination?.stopDate && load.estimatedDeliveryDate) {
    const trackingTimeWindow = buildTimeString(
      load.tracking?.estimatedDeliveryTimeWindow,
    )
    deliveryText = (
      <span>
        ETA <FvDate val={load.estimatedDeliveryDate} format="ddd MMM D, YYYY" />{' '}
        {trackingTimeWindow}
      </span>
    )
  }

  return (
    <span>
      <Icon icon="calendar-day" className="text-fv-gray-dark" />
      {deliveryText}
    </span>
  )
}
