import { useCallback, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { OlarkHelper } from '@fv/client-components'

import { supportMessage } from '../../constants'
import {
  ShipmentDetailsSliderPanel,
  type ShipmentSliderState,
} from '../../features/shipment-details-panel/ShipmentDetailsSliderPanel'
import { type SetActiveSlider } from '../../features/shipment-details-panel/types'
import { useLoad } from '../../hooks/shipments'
import { routes } from '../../routes'
import InfoBox from '../InfoBox'
import TopNav from '../TopNav'
import { ActionsPanel } from './ActionsPanel'
import { DetailsPanel } from './DetailsPanel'
import { Footer } from './Footer'
import { OverviewPanel } from './OverviewPanel'

export const ShipmentDetailsPage = () => {
  const [sliderState, setSliderState] = useState<ShipmentSliderState>()
  const { loadId } = useParams<{ loadId: string }>()
  const loadQuery = useLoad(loadId, { refetchWhileDispatching: true })
  const load = loadQuery.data
  const { status } = load ?? {}

  const setActiveSlider = useCallback<SetActiveSlider['setActiveSlider']>(
    (tab, tabProps) =>
      tab ? setSliderState({ tab, tabProps }) : setSliderState(undefined),
    [],
  )

  if (loadQuery.isLoading || !loadQuery.data || !loadId) {
    return (
      <div className="flex-col h-screen flex">
        <TopNav />

        <div className="flex flex-1 items-center justify-center">
          {loadQuery.isLoading ? (
            <InfoBox icon="sync">Loading shipment...</InfoBox>
          ) : (
            <InfoBox icon="exclamation-triangle">
              Unable to load shipment, {supportMessage}
            </InfoBox>
          )}
        </div>
      </div>
    )
  }

  if (status === 'pending') {
    return <Navigate to={`/search?text=${loadId}`} replace />
  }

  if (
    load?.selectedQuote &&
    (load?.pickup?.status === 'error' ||
      (load?.pickup?.status === 'not-requested' &&
        load?.bol?.status === 'error'))
  ) {
    return (
      <Navigate
        to={routes.book({
          loadId: load.loadId,
          quoteId: load.selectedQuote._id,
        })}
      />
    )
  }

  return (
    <div className="grid-master-shipment grid bg-fv-gray-100 h-screen grid-cols-[22rem_18rem_1fr] grid-rows-[auto_1fr_auto] b1350:grid-cols-[18rem_1fr] b1350:grid-rows-[auto_auto_1fr_auto]">
      <TopNav />
      <OverviewPanel load={loadQuery.data} />
      <ActionsPanel load={loadQuery.data} setActiveSlider={setActiveSlider} />
      <DetailsPanel load={loadQuery.data} setActiveSlider={setActiveSlider} />
      <Footer load={loadQuery.data} />

      <ShipmentDetailsSliderPanel
        loadId={loadId}
        state={sliderState}
        onChange={setSliderState}
      />
      <OlarkHelper loadId={loadQuery.data?.loadId} />
    </div>
  )
}
