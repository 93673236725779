import clsx from 'clsx'

import {
  FvLinkButton,
  type FvLinkButtonProps,
  Icon,
} from '@fv/client-components'

type Props = FvLinkButtonProps
export const ExpanderButton = ({
  children,
  className,
  disabled,
  ...props
}: Props) => {
  return (
    <FvLinkButton
      icon="filter"
      transform="up-.75"
      {...props}
      disabled={disabled}
      className={clsx(
        'bg-fv-blue-100 border-t-fv-blue border-b-fv-blue-200 relative mt-auto flex items-center justify-center border-b border-t px-2 py-3',
        className,
      )}
    >
      <div
        className={clsx(
          { hidden: disabled },
          'bg-fv-blue-100 border-fv-blue absolute -top-4 left-1/2 h-4 w-8 -translate-x-1/2 rounded-tl-2xl rounded-tr-2xl border border-b-0 pt-1 text-center',
        )}
      >
        <Icon icon="arrows-up-down" size="sm" />
      </div>
      {children}
    </FvLinkButton>
  )
}
