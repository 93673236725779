import { useEffect, useState } from 'react'

import { FvLinkButton } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { usePermissions } from '../../../features/auth'
import { AddRateButton } from '../../../features/quote/ManualRatePanel'
import { useCancelLoad, useEditBookingPage } from '../../../hooks/shipments'
import { CancelShipmentPrompt } from '../../shared/CancelShipmentPrompt'

type ActivePanelFooterProps = {
  isShowingRates: boolean
  load: FullShipment
  setShowingRates: (s: boolean) => void
}

export const ActivePanelFooter = ({
  isShowingRates,
  load,
  setShowingRates,
}: ActivePanelFooterProps) => {
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const { canBook } = usePermissions()
  const { canEdit, composeEditLink } = useEditBookingPage(load)
  const cancelLoad = useCancelLoad(load.loadId)
  const isCanceling = cancelLoad.isLoading || cancelLoad.isSuccess

  const { loadId, status } = load
  const isCanceled = status === 'canceled'
  const hasCancelButton = !isCanceled && canBook

  useEffect(() => {
    setShowCancelDialog(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadId])

  const handleCancelConfirm = () => {
    if (isCanceling) return

    cancelLoad.mutate(false, {
      onSuccess: () => {
        setShowCancelDialog(false)
        setShowingRates(false)
      },
    })
  }

  if (!canEdit && !hasCancelButton && !isShowingRates) return null

  return (
    <div className="shipment-list-ancillary-details__footer">
      <div className="divided-content divided-content--start">
        {isShowingRates && (
          <AddRateButton icon="dollar-sign" load={load}>
            Enter rate
          </AddRateButton>
        )}

        {canEdit && (
          <FvLinkButton icon="pen-alt" to={composeEditLink()}>
            Edit load
          </FvLinkButton>
        )}

        {hasCancelButton && (
          <FvLinkButton
            icon={isCanceling ? 'spinner' : 'times'}
            onClick={() => {
              if (isCanceling) return
              setShowCancelDialog(true)
            }}
          >
            Cancel shipment
          </FvLinkButton>
        )}
      </div>

      {showCancelDialog && (
        <div className="shipment-list-ancillary-details-ctn__cancel-prompt">
          <CancelShipmentPrompt
            loading={isCanceling}
            onConfirm={handleCancelConfirm}
            onDecline={() => setShowCancelDialog(false)}
          />
        </div>
      )}
    </div>
  )
}
