export const dateOptions = [
  { text: 'This year', value: 'this-year' },
  { text: 'Today', value: 'today' },
  { text: 'Tomorrow', value: 'tomorrow' },
  { text: 'Yesterday', value: 'yesterday' },
  { text: 'This week', value: 'this-week' },
  { text: 'Next week', value: 'next-week' },
  { text: 'Last 7 days', value: 'last-7-days' },
  { text: 'This month', value: 'this-month' },
  { text: 'Last month', value: 'last-month' },
  { text: 'Last 30 days', value: 'last-30-days' },
  { text: 'Last 12 months', value: 'last-12-months' },
]

export const pastDateOptions = dateOptions.filter(
  o => o.value !== 'tomorrow' && o.value !== 'next-week',
)

export const directionOptions = [
  { text: 'Inbound', value: 'inbound' },
  { text: 'Outbound', value: 'outbound' },
  { text: 'Third party', value: 'third-party' },
]

export const inProcessStatusOptions = [
  { text: 'Quoting', value: 'pending' },
  { text: 'Awarded', value: 'awarded' },
]

export const pricingMethodOptions = [
  { text: 'Capacity', value: 'capacity' },
  { text: 'Contracted', value: 'contracted' },
]

export const pricingTypeOptions = [
  { text: 'Density', value: 'density' },
  { text: 'Dimensional', value: 'dimensional' },
  { text: 'Pallet', value: 'pallet' },
  { text: 'Spot', value: 'spot' },
  { text: 'Tariff', value: 'tariff' },
]

export const readyCompletedStatusOptions = [
  { text: 'Ready / Scheduled', value: 'confirmed' },
  { text: 'In transit', value: 'picked-up' },
  { text: 'Delivered', value: 'delivered' },
  { text: 'Canceled', value: 'canceled' },
]

export const shippingModeOptions = [
  { text: 'Consolidated', value: 'consolidated' },
  { text: 'Intermodal', value: 'intermodal' },
  { text: 'Less than truckload', value: 'ltl' },
  { text: 'Parcel', value: 'parcel' },
  { text: 'Truckload', value: 'truckload' },
]

export const yesNoOptions = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
]
