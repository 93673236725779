import { Icon } from '@fv/client-components'

import { type FormSectionProps } from '../../components/inputs/types'
import { PillHeader } from '../../components/PillHeader'
import { useBookingFormCtx } from '../book/BookingFormProvider'
import { unitIsHazardous } from '../commodities/load-items/loadItemHelpers'
import { EmergencyContactForm } from './EmergencyContactForm'

export const LTLContact = ({ disabled }: FormSectionProps) => {
  const { setEmergencyContactValues } = useBookingFormCtx(s => s.actions)
  const contact = useBookingFormCtx(s => s.emergencyContact)
  const required = useBookingFormCtx(s =>
    s.itemState.items.some(unitIsHazardous),
  )

  if (!required) return null

  return (
    <div className="border-fv-gray relative mb-8 mt-4 border bg-fv-gray-100 px-4 pb-2 pt-10">
      <PillHeader className="beer-container">
        <Icon icon="radiation-alt" className="text-fv-orange" />
        <span>Emergency</span>
      </PillHeader>
      <div className="form-row">
        <EmergencyContactForm
          disabled={disabled}
          required={required}
          setValues={setEmergencyContactValues}
          values={contact}
        />
      </div>
    </div>
  )
}
