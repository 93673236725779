import { Icon } from '@fv/client-components'

type SentQuoteRequestsHeaderProps = { emails?: string[] | null }

export const SentQuoteRequestsHeader = ({
  emails,
}: SentQuoteRequestsHeaderProps) => {
  return (
    <>
      <h6 className="header-divider">
        <Icon
          icon="check-circle"
          className="text-fv-green"
          transform="grow-5 left-2"
        />
        <span>We sent a quote request to the following contacts:</span>
      </h6>

      <div className="send-quotes-emails-ctn">
        <p className="mb-0">{emails?.join(', ')}</p>
      </div>
    </>
  )
}
