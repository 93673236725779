import { FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import UploadDocumentField from '../../components/inputs/UploadDocumentField'
import DocumentList from './DocumentList'

type Props = {
  closePanel: () => void
  load: FullShipment
}

const ListingDocumentsPanel = ({ closePanel, load }: Props) => {
  return (
    <div className="shipment-list-ancillary-details-ctn">
      <nav className="divided-content divided-content--start shipment-list-ancillary-details__nav">
        <FvLinkButton onClick={() => closePanel()} icon="arrow-to-left">
          Back
        </FvLinkButton>
        <div>
          <Icon className="text-fv-gray-dark" icon="upload" />
          <span>Upload document</span>
        </div>
      </nav>
      <div className="shipment-list-ancillary-details flex flex-col flex-nowrap items-stretch overflow-auto">
        <InfoBox>
          Current documents are shown below. To upload a new document, select
          'Choose file' below.
        </InfoBox>
        <DocumentList
          className="mb-8 flex-1 overflow-auto"
          documents={load.documents}
          loadId={load.loadId}
          canDelete={true}
        />

        <UploadDocumentField
          handleClose={closePanel}
          loadId={load.loadId}
          requireDocType
        />
      </div>
    </div>
  )
}

export default ListingDocumentsPanel
