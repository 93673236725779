import capitalize from 'lodash/capitalize'
import { type ChangeEvent, useEffect, useRef, useState } from 'react'

import { addressFuncs, isDirtyForm, validateEmailInput } from '@fv/client-core'
import { type SelectFieldOption, type ShipmentLocation } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { AddressInput } from '../../components/inputs/AddressInput'
import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { SelectField } from '../../components/inputs/SelectField'
import {
  filterOption,
  TypeaheadField,
} from '../../components/inputs/TypeaheadField'
import { type FormSectionProps } from '../../components/inputs/types'
import { Loading } from '../../components/Loading'
import { customsBrokerMapper } from './brokerFuncs'
import {
  type CustomsBroker,
  type CustomsBrokerType,
  customsBrokerTypes,
  type FormCustomsBroker,
} from './brokerTypes'
import { useCustomsBrokerByLocation, useCustomsBrokers } from './useBrokers'

type Props = FormSectionProps & {
  loadLocation: Pick<ShipmentLocation, 'addressBookId' | 'postalCode'>
  autofocus?: boolean
  nameLabel?: string
  required?: boolean
  setValues: (values: Partial<FormCustomsBroker>) => void
  values: FormCustomsBroker
  requireValue?: boolean
  workflow: Workflow
}

export const CustomsBrokerForm = ({
  autofocus = false,
  disabled = false,
  loadLocation,
  nameLabel = 'Customs broker company name',
  required = true,
  setValues,
  values,
  requireValue,
  workflow,
}: Props) => {
  const addressRef = useRef<HTMLInputElement>(null)
  const enabled = !!(loadLocation || required)
  const brokerOptions = useBrokerOptions(enabled)
  const {
    getInputProps,
    setFormValues,
    isLoading: formLoading,
  } = useForm({
    disabled,
    loadLocation,
    setValues,
    values,
  })

  if (!enabled) return null

  const typeOptions: SelectFieldOption[] = [
    {
      text: '',
      value: '',
    },
    ...customsBrokerTypes.map(c => ({
      text: capitalize(c),
      value: c,
    })),
  ]

  function onSelectChange(broker: CustomsBroker | null) {
    const nextValues = broker
      ? {
          country: 'us' as const,
          ...broker,
          value: String(broker.value || ''),
        }
      : {
          address: '',
          address2: '',
          city: '',
          companyName: '',
          country: 'us' as const,
          email: '',
          phone: '',
          phoneExt: '',
          postalCode: '',
          state: '',
        }

    setFormValues(nextValues)
    if (broker) addressRef.current?.focus()
  }

  if (brokerOptions.loading || formLoading) {
    return <Loading />
  }

  return (
    <>
      <InputGroupWrapper
        className="basis-4/12"
        label={nameLabel}
        name={getInputName('companyName')}
        required={required}
      >
        <TypeaheadField
          autoFocus={autofocus && !values.companyName}
          className="form-control"
          filterOption={filterOption}
          isLoading={brokerOptions.loading}
          name={getInputName('companyName')}
          onChange={onSelectChange}
          onInputChange={companyName => {
            setFormValues({ companyName })
          }}
          options={brokerOptions.data}
          readOnly={disabled}
          required={required}
          value={values.companyName}
        />
      </InputGroupWrapper>

      <InputGroup
        className="basis-4/12"
        inputProps={getInputProps('address', {
          ref: addressRef,
        })}
        inputType="text"
        label="Address"
      />

      <InputGroup
        className="basis-4/12"
        inputProps={getInputProps('address2')}
        inputType="text"
        label="Address 2"
      />

      <InputGroupWrapper
        className="basis-4/12"
        label="City, State, Zip, Country"
        name={getInputName('cityStateZipCountry')}
      >
        <AddressInput
          context={workflow}
          formatAddress={addressFuncs.cityStateZip}
          location={{
            ...values,
            country: values.country || 'us',
          }}
          name={getInputName('cityStateZipCountry')}
          onLocationChange={x => {
            setFormValues({
              adminArea3: x?.adminArea3,
              city: x?.city || '',
              country: x?.country || 'us',
              postalCode: x?.postalCode || '',
              state: x?.state || '',
            })
          }}
          readOnly={disabled}
        />
      </InputGroupWrapper>

      <InputGroupWrapper
        className="basis-2/12"
        label="Type"
        name={getInputName('type')}
      >
        <SelectField
          name={getInputName('type')}
          options={typeOptions}
          value={values.type ?? ''}
          onChange={e =>
            setFormValues({ type: e.target.value as CustomsBrokerType })
          }
        />
      </InputGroupWrapper>

      <ContactPhoneInput
        className="basis-3/12"
        disabled={disabled}
        label="Phone"
        name={getInputName('phone')}
        onChange={v => {
          setFormValues({
            phone: v.contactPhone,
            phoneExt: v.contactPhoneExt,
          })
        }}
        required={required}
        values={{
          contactPhone: values.phone,
          contactPhoneExt: values.phoneExt,
        }}
      />

      <InputGroup
        className="basis-3/12"
        inputProps={getInputProps('email')}
        inputType="text"
        label="Email"
      />

      <InputGroup
        className="form-group--value basis-2/12"
        inputProps={getInputProps('value', {
          isPrice: true,
          min: 1,
        })}
        inputType="amount"
        label="Declared value"
        required={required && requireValue}
        startContent={
          <InputAdornment
            icon="dollar-sign"
            iconClass="text-fv-gray-dark"
            position="start"
          />
        }
      />
    </>
  )
}

function getInputName(name: string) {
  return `customsBroker_${name}`
}

function useBrokerOptions(required: boolean) {
  const brokersQuery = useCustomsBrokers(required)
  const brokers = brokersQuery.data ?? []

  return {
    data: brokers.map(b => ({
      label: b.companyName.trim(),
      value: b,
    })),
    loading: brokersQuery.isFetching,
  }
}

function useForm(config: {
  loadLocation: Pick<ShipmentLocation, 'addressBookId' | 'postalCode'>
  disabled: boolean
  setValues: (values: Partial<FormCustomsBroker>) => void
  values: FormCustomsBroker
}) {
  const [loading, setLoading] = useState(true)
  const { disabled, loadLocation, setValues, values } = config
  const [dirty, setDirty] = useState(() => isDirtyForm(values))
  const enableBrokerLookup = !!loadLocation && !dirty

  const defaultBrokerQuery = useCustomsBrokerByLocation(
    loadLocation,
    enableBrokerLookup,
  )

  useEffect(() => {
    if (defaultBrokerQuery.isLoading && enableBrokerLookup) return
    if (!loadLocation || dirty || !defaultBrokerQuery.data) {
      setLoading(false)
      return
    }

    const defaultBroker = customsBrokerMapper.dtoToForm(defaultBrokerQuery.data)

    setDirty(true)
    setValues(defaultBroker)
    setLoading(false)
  }, [
    defaultBrokerQuery.isLoading,
    defaultBrokerQuery.data,
    dirty,
    loadLocation,
    setValues,
    enableBrokerLookup,
  ])

  function setFormValues(values: Partial<FormCustomsBroker>) {
    setDirty(true)
    setValues(values)
  }

  return {
    isLoading: loading,
    getInputProps: (
      name: 'address' | 'address2' | 'email' | 'value',
      inputProps?: object,
    ) => ({
      disabled,
      name: getInputName(name),
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        if (name === 'email') {
          validateEmailInput(e.target)
        }

        setDirty(true)
        setValues({ [name]: e.target.value })
      },
      value: values[name] ?? '',
      ...inputProps,
    }),
    setFormValues,
  }
}
