import dayjs from 'dayjs'
import { type PropsWithChildren, type ReactNode } from 'react'

import { FvButton, FvDate, FvLinkButton, Icon } from '@fv/client-components'
import { tenderInProgressOrComplete } from '@fv/client-core'
import { type PropsWithLoad, type UIQuote } from '@fv/client-types'

import { routes } from '../../routes'
import { quoteFuncs } from './quoteFuncs'
import { useForceRerate } from './useForceRerate'

type RateAlertProps = Partial<PropsWithLoad> & {
  rates?: UIQuote[]
}

const MAX_DIMENSION = 96

const Alert = ({ children }: PropsWithChildren) => {
  return (
    <div className="py-3 flex">
      <Icon icon="hand-paper" transform="down-3" className="text-fv-orange" />
      <p className="mb-0">{children}</p>
    </div>
  )
}

const AlertList = ({ children }: { children: ReactNode[] }) => {
  if (children.every(n => !n)) return null

  return (
    <div className="rates-banner border-b border-gray-300 px-6 py-2">
      <div className="divide-y divide-fv-gray divide-dotted">{children}</div>
    </div>
  )
}

const Enter = ({ id }: { id: string }) => {
  return (
    <FvLinkButton
      theme="underlined"
      to={routes.quote('ltl', { id, mode: 'edit' })}
    >
      enter
    </FvLinkButton>
  )
}

// TODO: rate unavailable alert
export const RateAlerts = ({ load, rates }: RateAlertProps) => {
  const forceRerate = useForceRerate(load?.loadId ?? '')

  if (!load) return null
  const { items, loadId } = load

  const isLTL = load.workflow === 'ltl'
  const activeApiQuotes =
    rates?.filter(r => r.status === 'active' && r.method === 'api') ?? []
  const quoteDate = quoteFuncs.getRecentAPIQuoteDate(load, rates)
  const isMissingDims = items.some(i => !i.height || !i.weight || !i.length)
  const isMissingFreightClass = items.some(
    i => !i.freightClass && i.contains?.some(c => !c.freightClass),
  )
  const isOverHeight = items.some(i => !!i.height && i.height > MAX_DIMENSION)
  const isOverLength = items.some(i => !!i.length && i.length > MAX_DIMENSION)
  const isOverWidth = items.some(i => !!i.width && i.width > MAX_DIMENSION)
  const isOverDimension = isOverHeight && isOverWidth
  const isOverHeightOnly = isOverHeight && !isOverWidth
  const isRerateAlert = load.quoteProcessStatus === 'rerate-no-compat'
  const isOutdated =
    activeApiQuotes.length > 0 &&
    dayjs.utc(quoteDate).isBefore(dayjs.utc(), 'day')
  const allowRerate =
    load.status === 'pending' || !tenderInProgressOrComplete(load)
  // const rateUnavailable = status === 'rerated' && (!selectedRate?.carrierId || selectedRate?.status === 'error')

  return (
    <AlertList>
      {isLTL && isMissingFreightClass && (
        <Alert>
          Notice: Many carriers can't provide LTL rates without a freight class.
          To see more options, <Enter id={loadId} /> a freight class.
        </Alert>
      )}

      {isLTL && isMissingDims && (
        <Alert>
          Notice: Many brokers and carriers don't provide their best LTL rates
          without dimensions. For the best rates, <Enter id={loadId} />{' '}
          dimensions.
        </Alert>
      )}

      {isLTL && isOverLength && (
        <Alert>
          Notice: This shipment may incur additional over-length charges,
          depending on the carrier and your tariff with them.
        </Alert>
      )}

      {isLTL && isOverDimension && (
        <Alert>
          Notice: This shipment may incur additional over-dimension charges,
          depending on the carrier and your tariff with them.
        </Alert>
      )}

      {isLTL && isOverHeightOnly && (
        <Alert>
          Notice: This shipment contains items that may be too tall to fit in a
          carrier vehicle.
        </Alert>
      )}

      {isOutdated && (
        <Alert>
          You ran this quote on <FvDate val={quoteDate} /> so your rates may be
          out of date.{' '}
          {allowRerate && (
            <FvButton
              icon="arrow-right"
              onClick={() => {
                if (forceRerate.isLoading) return
                forceRerate.mutate()
              }}
            >
              Refresh rates
            </FvButton>
          )}
        </Alert>
      )}

      {isRerateAlert && (
        <Alert>
          Rate not available: The rate option you previously chose when quoting
          is not available due to the changes you made to the shipment details.
          {!!activeApiQuotes.length && <> Select a different rate below.</>}
        </Alert>
      )}
    </AlertList>
  )
}
