import clsx from 'clsx'
import pluralize from 'pluralize'
import { type MouseEvent, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, Icon, SliderPanel } from '@fv/client-components'
import { submitForm } from '@fv/client-core'
import {
  type EquipmentAccessorial,
  type EquipmentAccessorialType,
  type LoadEquipment,
  type PackagingType,
} from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { ValidatedForm } from '../../components/inputs'
import { List, ListItem } from '../../components/List'
import { PillHeader } from '../../components/PillHeader'
import EquipmentAccessorialConfig from '../../components/shared/EquipmentAccessorialConfig'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import {
  useCurrency,
  usePrettyNumber,
  useQuoteSettings,
  useTypeNames,
} from '../../hooks/settings'
import { type AccessorialTypeEnum } from '../../hooks/settings/useAccessorialOptions'
import { buildFormCommodity } from '../commodities/commodityUtils'
import { useBookingForm } from './hooks'
import { LoadItemFields } from './LoadItemFields'
import { type BookFormLoad, composeLoadKey } from './types'

const hidden = { display: 'none' }
const noOp = (): null => null
const tempControlFields = ['min', 'max', 'uom']

type Props = {
  load: BookFormLoad
}

export const BookingLoad = ({ load }: Props) => {
  const prettyNumber = usePrettyNumber()
  const priceParts = useCurrency()
  const { equipment, loadId, type, weight, weightUOM } = load
  const { setFormRef, ...form } = useBookingForm()
  const { equipmentName, packageName, accessorialName } = useTypeNames()

  const quote = form.selectedQuotes.find(q => q._id === load.quoteId)
  const accessorialOptions = useAccessorialOptions(
    equipment,
    quote?.equipmentType,
  )

  const isMultiStop = form.locations.length > 2
  const canAddItems = !form.isPickupOnly && !isMultiStop
  const isTempControlRequired = !!accessorialOptions.find(o => o.key === 'temp')
  const requiresDeclaredValue = form.settings.requiresDeclaredValue
  const tempSettings = equipment.accessorials?.find(a => a.key === 'temp')

  const [dollars, cents] = priceParts(quote?.amount)
  const formRef = useRef<HTMLFormElement>(null)
  const accessorialFormRef = useRef<HTMLFormElement>(null)
  const sectionKey = composeLoadKey(loadId)
  const isEditing = form.activeSection === sectionKey
  const section = form.formSections.find(s => s.key === sectionKey)
  const hasSection = !!section

  const [showAccessorialPanel, setShowAccessorialPanel] = useState(false)
  const closePanel = () => {
    const isValid = accessorialFormRef.current?.checkValidity()
    if (isValid) return setShowAccessorialPanel(false)
    submitForm(accessorialFormRef.current)
  }

  useEffect(() => {
    if (!hasSection) return
    setFormRef(sectionKey, formRef)
  }, [hasSection, sectionKey, setFormRef])

  if (!hasSection) return null

  function editLoad() {
    form.activateSection(sectionKey)
  }

  function setAccessorials(data: { accessorials: EquipmentAccessorial[] }) {
    const tempControl = data.accessorials.find(a => a.key === 'temp')

    if (isTempControlRequired && !tempControl) {
      toast.error(
        'Temp control is required for refrigerated equipment types.',
        { id: 'tempControl' },
      )
      return
    }

    form.setLoads(prev =>
      prev.map(load => {
        if (load.loadId !== loadId) return load
        return {
          ...load,
          equipment: { ...load.equipment, ...data },
        }
      }),
    )
  }

  function onInvalidSubmit(invalidEl?: HTMLInputElement) {
    if (
      isTempControlRequired &&
      tempControlFields.includes(invalidEl?.name ?? '')
    ) {
      // Ensure temp control accessorial is selected
      const nextAccessorials = (equipment.accessorials ?? [])
        .filter(a => a.key !== 'temp')
        .concat({
          key: 'temp',
          ...tempSettings,
        })

      setAccessorials({ accessorials: nextAccessorials })
      setShowAccessorialPanel(true)
    }
  }

  function addCommodity(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    form.setLoads(prev =>
      prev.map(load => {
        if (load.loadId !== loadId) return load

        return {
          ...load,
          items: load.items.concat(buildFormCommodity({ loadId })),
        }
      }),
    )
  }

  function setValues(id: string) {
    return (item: FormCommodity | null) => {
      form.setLoads(prev =>
        prev.map(load => {
          if (load.loadId !== loadId) return load

          return {
            ...load,
            items: item
              ? load.items.map(i => (i.id === id ? item : i))
              : load.items.filter(c => c.id !== id),
          }
        }),
      )
    }
  }

  return (
    <section className="booking-step-ctn booking-step--truck">
      <div
        className={clsx('booking-step', {
          editing: isEditing,
          valid: section?.isValid,
        })}
      >
        <PillHeader
          st
          className={clsx(
            'beer-container -top-3.5 z-10 mb-3 flex min-h-[3.5rem] items-center',
            !isEditing &&
              !section?.isValid &&
              '-ml-[calc(.5rem)] w-[calc(100%-3rem)]',
            isEditing && 'absolute left-[calc(1.5rem)] w-[calc(100%-7rem)]',
            section?.isValid &&
              'absolute -left-[calc(.5rem+1px)] w-[calc(100%-3rem+1px)]',
          )}
        >
          {section?.isValid && (
            <FvButton theme="round" onClick={() => editLoad()} icon="pen-alt" />
          )}
          <div className="flex items-center">
            <Icon
              icon={quote ? 'truck' : 'ban'}
              className="text-fv-gray-dark fa-fw"
            />
            <p className="mb-0">
              {equipmentName(quote?.equipmentType ?? type)}
              {quote && ` via ${quote.providerName}`} / {prettyNumber(weight)}{' '}
              {weightUOM}.{!quote && ' / No carrier selected'}
            </p>
          </div>
          <span className="price ml-auto">
            {!quote && 'NA'}
            {quote && <span className="price__dollars">${dollars}</span>}
            {quote && <span className="price__cents">{cents}</span>}
            {quote && (
              <span className="price__currency">
                {quote.currency.toUpperCase()}
              </span>
            )}
          </span>
        </PillHeader>

        {isEditing && (
          <ValidatedForm
            onInvalidSubmit={onInvalidSubmit}
            onValidSubmit={form.goToNextSection}
            ref={formRef}
          >
            <div
              className={clsx('mt-2', {
                'mb-4': !canAddItems,
                'mb-6': canAddItems,
              })}
            >
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  setShowAccessorialPanel(true)
                }}
              >
                <Icon icon="list" />
                <span>Equipment accessorials</span>
              </a>
              :{' '}
              <span>
                {composeAccessorials(accessorialName, equipment.accessorials)}
              </span>
              {isTempControlRequired &&
                tempControlFields.map(f => {
                  const key = f as keyof EquipmentAccessorial
                  const value = tempSettings?.[key] ?? ''

                  // Hidden input for accessorial validation only
                  return (
                    <input
                      key={f}
                      name={f}
                      onChange={noOp}
                      required
                      style={hidden}
                      value={value}
                    />
                  )
                })}
            </div>

            {canAddItems ? (
              load.items.map((values, i) => (
                <LoadItemFields
                  autoFocus={!values.quantity && i === 0}
                  canRemove={load.items.length > 1}
                  className=""
                  key={values.id}
                  locations={form.locations}
                  requiresDeclaredValue={requiresDeclaredValue}
                  setValues={setValues(values.id)}
                  values={values}
                />
              ))
            ) : (
              <hr className="mb-8" />
            )}
            <div className="mt-8 flex items-center">
              <FvButton
                icon="arrow-down-to-line"
                theme="secondary"
                fwd
                type="submit"
              >
                Looks good, proceed
              </FvButton>
              {canAddItems && <span className="ml-6">- or -</span>}
              {canAddItems && (
                <a className="ml-4" href="#" onClick={addCommodity}>
                  <Icon icon="plus" />
                  <span>Add another handling unit group</span>
                </a>
              )}
            </div>
          </ValidatedForm>
        )}

        {section?.isValid && (
          <div className="booking-step__summary">
            <List fcp0 lcp0>
              <ListItem>
                <Icon
                  className="fa-fw text-fv-gray-dark"
                  icon="forklift"
                  transform="down-0"
                />
                <span>
                  {form.isPickupOnly
                    ? 'Commodities to be added later.'
                    : composeLoadSummary({
                        commodities: load.items,
                        getPackageName: packageName,
                        prettyNumber,
                      })}
                </span>
              </ListItem>
            </List>
          </div>
        )}
      </div>

      <SliderPanel isOpen={showAccessorialPanel} closePanel={closePanel}>
        <p className="mb-0">
          <Icon icon="truck" className="text-fv-gray-dark" />
          <span>
            {equipmentName(quote?.equipmentType ?? type)}
            {quote && ` via ${quote.providerName}`} / {prettyNumber(weight)}{' '}
            {weightUOM}.{!quote && ' / No carrier selected'}
          </span>
        </p>
        <hr className="mb-8" />
        <ValidatedForm ref={accessorialFormRef}>
          <div className="booking-accessorial-options">
            <EquipmentAccessorialConfig
              accessorialOptions={accessorialOptions}
              accessorials={equipment.accessorials}
              id={loadId}
              setAccessorials={setAccessorials}
            />
          </div>
        </ValidatedForm>
      </SliderPanel>
    </section>
  )
}

function composeLoadSummary({
  commodities,
  getPackageName,
  prettyNumber,
}: {
  commodities: FormCommodity[]
  getPackageName: (t: PackagingType) => string
  prettyNumber: (n?: string | number | null | undefined) => string | undefined
}) {
  return `Hauling ${commodities
    .map(i => i.description)
    .join(', ')} / ${commodities
    .map(i => pluralize(getPackageName(i.type), Number(i.quantity), true))
    .join(', ')} / ${commodities
    .map(i => `${prettyNumber(i.weight)} ${i.weightUOM}.`)
    .join(', ')}`
}

function composeAccessorials(
  getAccessorialName: (k: EquipmentAccessorialType) => string,
  accessorials?: EquipmentAccessorial[],
) {
  if (!accessorials?.length) return 'None Selected'

  const countHandler = (a: EquipmentAccessorial) =>
    `${a.count ? a.count + ' ' : ''}${getAccessorialName(a.key)}`

  const composerDict: {
    [key: string]: (a: EquipmentAccessorial) => string
  } = {
    'load-bars': countHandler,
    chains: countHandler,
    straps: countHandler,
    tarps: a => `${a.size ? a.size + ' ft. ' : ''}${getAccessorialName(a.key)}`,
    temp: a => {
      if (typeof a.min === 'number' && typeof a.max === 'number' && !!a.uom) {
        return `Temp between ${a.min} and ${a.max} \u00b0${a.uom.toUpperCase()}`
      }

      return 'Temp control'
    },
  }

  return accessorials
    .map(a => {
      return composerDict[a.key]
        ? composerDict[a.key](a)
        : getAccessorialName(a.key)
    })
    .join(' / ')
}

function useAccessorialOptions(
  equipment: LoadEquipment,
  quotedEquipmentType?: EquipmentType,
): AccessorialTypeEnum[] {
  const settingsQuery = useQuoteSettings('truckload')
  const equipmentOpts = settingsQuery.data?.equipmentOptions ?? []
  const activeEquipment = equipmentOpts.find(
    o => o.key === (quotedEquipmentType ?? equipment.type),
  )

  const activeAccessorialOpts = activeEquipment?.accessorialOptions ?? []
  if (!quotedEquipmentType || quotedEquipmentType === equipment.type) {
    return activeAccessorialOpts
  }

  // Return current accessorials and options for alt equipment type
  const primaryEquipment = equipmentOpts.find(o => o.key === equipment.type)
  const primaryAccessorialOpts = primaryEquipment?.accessorialOptions ?? []
  const currentAccessorialOpts = primaryAccessorialOpts.filter(
    o => !!equipment.accessorials?.find(a => a.key === o.key),
  )

  return [...currentAccessorialOpts, ...activeAccessorialOpts]
}
