import { type ReactNode } from 'react'

import { VirtualizedTable } from '@fv/client-components'
import { type FvVirtualizedTableResult } from '@fv/client-core'

interface Props<TData> {
  table: FvVirtualizedTableResult<TData>
  loadMore?: ReactNode
}
export function ShipperTable<TModel>(opts: Props<TModel>) {
  return (
    <VirtualizedTable
      table={opts.table}
      loadMore={opts.loadMore}
      tableClassName="w-full table-auto border-collapse"
      tbodyClassName=""
      tdClassName="p-3"
      thClassName="text-left bg-fv-gray-100 whitespace-nowrap px-2 py-3 font-normal border-b"
      theadClassName="[&_tr]:flex [&_tr]:w-full [&_tr]:items-stretch"
      trClassName="border-fv-gray-300 hover:bg-fv-beer-light border-b hover:cursor-pointer"
    />
  )
}
