import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { useAppContext, useUserSettings } from '../../hooks/settings'
import { useAccountLocations } from '../addresses/useAddress'
import { useIsAdminAccount, useIsCarrierAccount } from '../auth/hooks'

type StatePart<S> = [S, Dispatch<SetStateAction<S>>]
type OnboardingState = {
  onboarding: StatePart<boolean>
}

const OnboardingContext = createContext<OnboardingState | null>(null)

export const OnboardingProvider = ({ children }: PropsWithChildren) => {
  const force = useForceOnboarding()
  const context = useAppContext()
  const locationsQuery = useAccountLocations()
  const settingsQuery = useUserSettings()
  const isCarrier = useIsCarrierAccount()
  const isAdmin = useIsAdminAccount()
  const user = context.data?.user
  const locations = locationsQuery.data
  const settings = settingsQuery.data
  const onboarding = useState<boolean>(
    !isCarrier &&
      !isAdmin &&
      (force ||
        !user?.firstName ||
        !user?.lastName ||
        !locations?.length ||
        !settings?.registration.billTo[0]?.address),
  )
  return (
    <OnboardingContext.Provider
      value={{
        onboarding,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext)
  if (!context) throw new Error('OnboardingContext.Provider not rendered')
  return context
}

export const useForceOnboarding = () => {
  const { pathname } = useLocation()
  // if they intentionally navigated to the welcome process
  return pathname.includes('welcome')
}
