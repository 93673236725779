import { type ListShipment } from '@fv/client-types'

import { getCarrierDetails } from '../../utils/shipmentFuncs'
import ActiveShipmentCardHeader from './ActiveShipmentCardHeader'
import PendingShipmentCardHeader from './PendingShipmentCardHeader'

type ShipmentCardHeaderProps = { shipment: ListShipment }

const ShipmentCardHeader = ({ shipment }: ShipmentCardHeaderProps) => {
  const { status } = shipment
  const carrierDetails = getCarrierDetails(shipment)
  const HeaderComponent =
    status === 'awarded' || status === 'pending'
      ? PendingShipmentCardHeader
      : ActiveShipmentCardHeader

  return (
    <div className="flex border-b border-fv-gray">
      <HeaderComponent carrierName={carrierDetails} shipment={shipment} />
    </div>
  )
}

export default ShipmentCardHeader
