import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import toast, { Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { BrowserCheck } from '@fv/client-components'

import { AuthenticatedApp } from './components/AuthenticatedApp'
import ErrorBoundary from './components/layout/ErrorBoundary'
import Login from './features/auth/Login'
import { SharedShipmentPage } from './features/share/SharedShipmentPage'
import { marketingUri } from './constants'

const loginRedirect = `${marketingUri}/login`
const staleTime = 60 * 1000 * 2 // 2 minutes

function handleApiError(error: unknown) {
  const status = (error as { status?: number })?.status

  if (status === 401) {
    toast.error('Access denied, redirecting to login.', { id: 'unauthorized' })
    window.location.href = loginRedirect
  }

  if (status === 402) {
    toast.error('Payment required, redirecting to subscription.', {
      id: 'payment-required',
    })
    window.location.href = `/settings/subscription`
  }

  if (status === 403) {
    toast.error(
      'You do not have access to the requested data. Please contact customer support.',
      { id: 'forbidden' },
    )
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: { onError: handleApiError },
    queries: {
      onError: handleApiError,
      staleTime,
      retry: (failCount, error) => {
        const status = (error as { status?: number })?.status
        if (status === 401 || status === 403 || status === 402) return false
        return failCount < 3
      },
    },
  },
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserCheck>
        <BrowserRouter>
          <ErrorBoundary>
            <Routes>
              <Route path="share/:loadId" element={<SharedShipmentPage />} />

              <Route
                path="login/:token"
                element={<Login loginRedirect={loginRedirect} />}
              />

              <Route path="*" element={<AuthenticatedApp />} />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />

        <Toaster position="bottom-center" />
      </BrowserCheck>
    </QueryClientProvider>
  )
}

export default App
