import { useEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

import { FvButton, FvLinkButton } from '@fv/client-components'
import { apiUri } from '@fv/client-core'
import { formatPhone } from '@fv/models/core'

import TopNav from '../../components/TopNav'
import { useAppContext } from '../../hooks/settings'
import { routes } from '../../routes'
import { EditContactInfoPanel } from './contact-information/EditContactInfoPanel'
import { SettingsNav } from './SettingsNav'

export const SettingsPage = () => {
  const contextQuery = useAppContext()
  const user = contextQuery.data?.user
  const isDemoAccount = user?.accountRoles.includes('demo') ?? false
  const isRegistered = !!user?.registrationDate
  const [isEditingContactInfo, setEditingContactInfo] = useState(false)
  const [params] = useSearchParams()

  useEffect(() => {
    const editParam = params.get('edit-contact')
    if (editParam) {
      setEditingContactInfo(true)
    }
  }, [params])

  return (
    <div className="grid h-screen bg-fv-gray-100 grid-cols-[20rem_18rem_1fr] grid-settings grid-rows-[auto_1fr_auto] b1350:grid-cols-[18rem_1fr] b1350:grid-rows-[auto_auto_1fr_auto]">
      <TopNav />

      <div className="settings-overview b1350:py-4 b1350:border-b b1350:border-gray-300 b1350:flex-row b1350:pl-4 b1350:border-r-0 b1350:items-center flex flex-col b1350:row-start-2 b1350:col-span-full">
        <p className="limit-characters mb-0 flex-shrink-0 whitespace-nowrap !leading-[1.6rem]">
          {user?.firstName} {user?.lastName}
          <span className="b1350:hidden">
            {user?.title && (
              <>
                <br /> <span title={user.title}>{user.title}</span>
              </>
            )}
            {user?.company && (
              <>
                <br /> <span title={user.company}>{user.company}</span>
              </>
            )}
          </span>
        </p>
        <hr className="b1350:hidden" />
        <p className="b1350:overflow-hidden b1350:whitespace-nowrap mb-0 !leading-[1.6rem]">
          <span className="b1350:hidden">
            <span
              className="block overflow-hidden text-ellipsis whitespace-nowrap"
              title={user?.email}
            >
              {user?.email}
            </span>
            {user?.phoneNumber && <>{formatPhone(user.phoneNumber)}</>}
          </span>
          <span className="b1350:flex hidden">
            {user?.email && (
              <span className="limit-characters ml-3 border-l border-gray-300 pl-3">
                {user.email}
              </span>
            )}
            {user?.phoneNumber && (
              <span className="ml-3 border-l border-gray-300 pl-3">
                {formatPhone(user.phoneNumber)}
              </span>
            )}
          </span>
        </p>
        <hr className="b1350:hidden" />
        <div className="b1350:border-gray-300 b1350:pl-3 b1350:ml-3 b1350:border-l divided-content divided-content--start whitespace-nowrap">
          <FvLinkButton
            icon="pen-alt"
            onClick={() => {
              setEditingContactInfo(true)
            }}
          >
            Edit
          </FvLinkButton>
          <FvLinkButton to={routes.settings('change-password')} icon="key">
            Change password
          </FvLinkButton>
        </div>

        <form
          method="post"
          action={`${apiUri}/auth/sign-out`}
          className="b1350:mt-0 b1350:border-l border-t-fv-gray b1350:border-l-fv-gray b1350:pl-4 b1350:border-solid b1350:mb-0 b1350:pt-0 b1350:border-t-0 -mb-4 mt-auto border-t border-dashed pt-4"
        >
          <FvButton type="submit" icon="power-off">
            Sign Out
          </FvButton>
        </form>
      </div>

      <SettingsNav isDemoAccount={isDemoAccount} isRegistered={isRegistered} />

      <Outlet />

      <EditContactInfoPanel
        isOpen={isEditingContactInfo}
        setOpen={setEditingContactInfo}
      />
    </div>
  )
}
