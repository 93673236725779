import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type EquipmentType } from '@fv/models'

import { apiUri, supportMessage } from '../../../constants'
import {
  type UserSettings,
  userSettingsKey,
} from '../../../hooks/settings/useUserSettings'
import { type ApiError } from '../../../types/ApiError'
import { type UserPreference } from './types'

type PartialAccountUser = Pick<UserSettings, 'preferences'>

async function updateFavoriteEquipment(
  equipmentTypes: EquipmentType[],
): Promise<PartialAccountUser> {
  const endpoint = `${apiUri}/accounts/tl-equipment-types`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ equipmentTypes }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUpdateFavoriteEquipment() {
  const queryClient = useQueryClient()

  return useMutation(updateFavoriteEquipment, {
    onSuccess: ({ preferences }) => {
      queryClient.setQueriesData<UserSettings | undefined>(
        userSettingsKey,
        prev =>
          prev && {
            ...prev,
            preferences,
          },
      )

      toast.success('Favorite equipment updated successfully.')
    },
  })
}

async function toggleUserPreference(dto: {
  feature: UserPreference
  value: boolean
}): Promise<PartialAccountUser> {
  const endpoint = `${apiUri}/accounts/toggle-user-preference`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(dto),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useToggleUserPreference(
  feature: UserPreference,
  notify?: boolean, // toast message on response
) {
  const queryClient = useQueryClient()
  const mutation = useMutation(toggleUserPreference, {
    onMutate: ({ value }) => {
      // Update cache immediately to drive UI
      queryClient.setQueriesData<UserSettings | undefined>(
        userSettingsKey,
        prev =>
          prev && {
            ...prev,
            preferences: {
              ...prev.preferences,
              [feature]: value,
            },
          },
      )
    },
    onSuccess: () => {
      notify &&
        toast.success('Preference updated successfully.', {
          id: 'user-preference-update',
        })
    },
  })

  function setPreference(value: boolean) {
    mutation.mutate(
      { feature, value },
      {
        onError: e => {
          const error = e as ApiError

          // Roll back cache update
          queryClient.setQueriesData<UserSettings | undefined>(
            userSettingsKey,
            prev =>
              prev && {
                ...prev,
                preferences: {
                  ...prev.preferences,
                  [feature]: !value,
                },
              },
          )

          if (notify) {
            let message = 'Unable to update preference'
            if (error?.message) message += `: ${error.message}`
            else message += `, ${supportMessage}`
            toast.error(message)
          }
        },
      },
    )
  }

  return {
    isBusy: mutation.isLoading,
    setPreference,
  }
}
