import { useState } from 'react'

import { type FullShipment, type ListShipment } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { List } from '../../components/List'
import InactiveCarrierListItem from '../../components/list/InactiveCarrierListItem'
import { useQuoteRequestResponses } from '../../hooks/shipments'
import { type QuoteRequestResponse } from '../../hooks/shipments/useQuoteRequestResponses'
import { MiniQuoteListItem } from './MiniQuoteListItem'

export type FilterType = 'active' | 'pending' | 'declined'

type MiniQuoteListListProps = {
  filter?: FilterType
  load: FullShipment | ListShipment
  readOnly?: boolean
  canViewMessages?: boolean
  initialActiveId?: string
  sort?: (responses: QuoteRequestResponse[]) => QuoteRequestResponse[]
}

export const MiniQuoteList = ({
  filter = 'active',
  load,
  readOnly,
  canViewMessages,
  initialActiveId,
  sort = r => r,
}: MiniQuoteListListProps) => {
  const { selectedQuote } = load
  const [activeRowId, setActiveRowId] = useState<string | undefined>(
    initialActiveId,
  )
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(
    selectedQuote?._id,
  )

  const awardedQuoteId = selectedQuote?._id

  const responses = useQuoteRequestResponses({
    filter: 'price',
    load,
  })

  if (filter === 'active') {
    return (
      <List className="mb-4">
        {sort(responses.active).map(response => (
          <MiniQuoteListItem
            awardedQuoteId={awardedQuoteId}
            isActive={activeRowId === response.id}
            isChecked={selectedRowId === response.id}
            key={response.id}
            canViewMessages={canViewMessages}
            load={load}
            quote={response}
            readOnly={readOnly}
            setActiveQuoteId={setActiveRowId}
            setSelectedQuoteId={setSelectedRowId}
          />
        ))}

        {!responses.active.length && (
          <InfoBox>Nobody has responded yet.</InfoBox>
        )}
      </List>
    )
  }

  return (
    <List className="mb-8">
      {sort(responses[filter]).map(response => (
        <InactiveCarrierListItem
          carrier={response}
          hasNewActivity={false} // TODO
          isActive={activeRowId === response.id}
          key={response.id}
          load={load}
          setActiveQuoteId={setActiveRowId}
        />
      ))}

      {!responses[filter].length && (
        <InfoBox>
          {filter === 'pending'
            ? 'No pending responses.'
            : 'Nobody has declined.'}
        </InfoBox>
      )}
    </List>
  )
}
