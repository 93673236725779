import { type ChangeEvent, useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, Icon, TextField } from '@fv/client-components'

import { supportMessage } from '../../constants'
import { useUpdateSavedView } from '../../features/shipment-filters/mutations'
import { useShipmentFilterListContext } from '../../features/shipment-filters/ShipmentFilterListProvider'
import { type FilterBase, getFilterQueryString } from '../../types/SavedFilter'
import { ValidatedForm } from '../inputs'
import FilterControl from './FilterControl'
import { dateOptions } from './filterOptions'

type CustomFilterFormProps = {
  onClose: () => void
  onFilter: (qs: string) => void
  editing?: FilterBase
}

export const CustomFilterForm = ({
  onClose,
  onFilter,
  editing,
}: CustomFilterFormProps) => {
  const [showFilterSave, setShowFilterSave] = useState(false)
  const [viewName, setViewName] = useState(editing?.name ?? '')
  const updateSavedView = useUpdateSavedView()
  const isUpdating = updateSavedView.isLoading
  const { filterConfig } = useShipmentFilterListContext(s => s)
  const listName = useShipmentFilterListContext(s => s.listType)

  const [queryString, setQueryString] = useState(() => {
    if (!editing) return ''
    const qs = new URLSearchParams(getFilterQueryString(editing))
    qs.delete('view') // viewId added by API
    return qs.toString()
  })

  const handleSave = async () => {
    if (isUpdating) return
    if (!validateQueryString(queryString)) return

    const isSystemFilter = editing?.isSystemFilter
    const trimmedViewName = viewName.trim()
    try {
      const result = await updateSavedView.mutateAsync({
        pageName: listName,
        queryString: queryString,
        viewName: trimmedViewName,
        ...(!isSystemFilter && { viewId: editing?._id }),
        ...(isSystemFilter && { systemFilterId: editing?._id }),
      })
      const resultViews = result[listName]
      const filter = resultViews.find(v => v.name === trimmedViewName)
      onFilter(filter?.queryString ?? '')
      toast.success('Filter saved successfully.')
      onClose()
    } catch (e: any) {
      toast.error(e?.message || `Unable to save filter, ${supportMessage}`)
    }
  }

  return (
    <>
      <div className="create-filter-ctn">
        <div className="filter-type-ctn">
          <div className="filter-type-ctn__header">
            <h6 className="mb-0">
              <Icon icon="filter" className="text-fv-gray-dark" />
              <span>Create a custom filter</span>
            </h6>

            <hr className="mb-6 mt-2" />
          </div>

          {filterConfig.map(filter => (
            <FilterControl
              {...filter}
              key={filter.name}
              queryString={queryString}
              setQueryString={setQueryString}
            />
          ))}
        </div>

        <div className="filter-actions-ctn">
          {!showFilterSave ? (
            <div className="flex">
              <FvButton
                theme="default"
                icon="filter"
                onClick={() => {
                  if (!validateQueryString(queryString)) return
                  onFilter(`${queryString}&view=custom`)
                  onClose()
                }}
              >
                Filter
              </FvButton>
              <FvButton
                icon="filter"
                theme="primary"
                onClick={() => setShowFilterSave(true)}
              >
                Filter & Save
              </FvButton>
              <FvButton theme="plain" onClick={onClose} icon="times" />
            </div>
          ) : (
            <ValidatedForm onValidSubmit={handleSave}>
              <label htmlFor="viewName" className="label">
                Name your new filter
              </label>
              <TextField
                autoFocus
                className="form-control mb-2"
                name="viewName"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setViewName(e.target.value)
                }}
                placeholder="i.e. Delivered yesterday"
                readOnly={isUpdating}
                required
                value={viewName}
              />
              <div className="flex">
                <FvButton
                  type="submit"
                  theme="primary"
                  icon="check"
                  loading={isUpdating}
                >
                  Save and filter
                </FvButton>
                <FvButton
                  theme="plain"
                  loading={isUpdating}
                  onClick={() => setShowFilterSave(false)}
                  icon="times"
                >
                  Cancel
                </FvButton>
              </div>
            </ValidatedForm>
          )}
        </div>
      </div>
      <div className="create-filter-ctn-overlay" onClick={onClose} />
    </>
  )
}

function validateCustomDateRange(range: string) {
  const [start, end] = range.split('-')
  const startDate = Number(start)
  const endDate = Number(end)

  if (!startDate || !endDate) {
    toast.error('Date range must include a start and end date.')
    return false
  }

  return true
}

function validateQueryString(qs: string) {
  if (!qs) {
    toast.error('Must select at least one filter.')
    return false
  }

  const params = new URLSearchParams(qs)
  const pickupDate = params.get('pickupDate')
  const deliveryDate = params.get('deliveryDate')
  const bookedDate = params.get('bookedDate')

  if (pickupDate && !dateOptions.find(o => o.value === pickupDate)) {
    const isValid = validateCustomDateRange(pickupDate)
    if (!isValid) return false
  }

  if (deliveryDate && !dateOptions.find(o => o.value === deliveryDate)) {
    const isValid = validateCustomDateRange(deliveryDate)
    if (!isValid) return false
  }

  if (bookedDate && !dateOptions.find(o => o.value === bookedDate)) {
    const isValid = validateCustomDateRange(bookedDate)
    if (!isValid) return false
  }

  return true
}

export default CustomFilterForm
