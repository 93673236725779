import { useState } from 'react'

import { Icon } from '@fv/client-components'

import { List, ListItem } from '../../../components/List'
import { HasFeature } from '../../auth'
import { SettingsSection } from '../SettingsSection'
import FavoriteEquipmentPanel from './FavoriteEquipmentPanel'
import { useEquipmentTypeEnums, useFavoriteEquipmentTypes } from './hooks'

const TruckloadPreferences = () => {
  const [isEditingEquipment, setEditingEquipment] = useState(false)
  const equipmentTypeEnums = useEquipmentTypeEnums('truckload')
  const favoriteEquipment = useFavoriteEquipmentTypes()

  return (
    <HasFeature name="truckLoad">
      <SettingsSection title="Truckload Quoting">
        <List fcp0 lcp0>
          <ListItem className="divided-content divided-content--start leading-[1.6rem] !gap-x-0">
            <span>Use the following equipment types as my favorites</span>
            <a
              href="#"
              className="whitespace-nowrap"
              onClick={e => {
                e.preventDefault()
                setEditingEquipment(true)
              }}
            >
              <Icon icon="pen-alt" />
              <span>Edit</span>
            </a>
          </ListItem>
          <ListItem className="settings-favorite-equipment !grid !gap-4 pt-4">
            {favoriteEquipment.map(key => {
              const equipment = equipmentTypeEnums.find(e => e.key === key)

              return (
                <div className="flex items-center" key={key}>
                  <div className="limit-characters">
                    <Icon icon="dot-circle" className="text-fv-gray-dark" />
                    <span>{equipment?.name ?? key}</span>
                  </div>
                  <div className="flex-pinline flex-pinline--subtle flex-pinline--tall" />
                </div>
              )
            })}
          </ListItem>
        </List>
      </SettingsSection>

      <FavoriteEquipmentPanel
        initialFavorites={favoriteEquipment}
        isOpen={isEditingEquipment}
        setOpen={setEditingEquipment}
      />
    </HasFeature>
  )
}

export default TruckloadPreferences
