import { useFvNavigate } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { routes } from '../../routes'

export function useRepeatShipment(shipment: FullShipment) {
  const { loadId, quoteRequestId, workflow } = shipment
  const navigate = useFvNavigate()

  return function repeatShipment() {
    localStorage.setItem('quoteRequestTemplate', JSON.stringify([shipment]))
    const id = workflow === 'truckload' ? quoteRequestId : loadId
    navigate(routes.quote(workflow, { id, mode: 'copy' }))
  }
}
