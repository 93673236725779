import { useState } from 'react'

import { Icon, useFvNavigate } from '@fv/client-components'
import { type FullShipment, type UIQuote } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { quoteFuncs } from '../../features/quote/quoteFuncs'
import { useCancelLoad } from '../../hooks/shipments'
import arrayOfLength from '../../utils/arrayOfLength'
import InfoBox from '../InfoBox'
import { List, ListItem } from '../List'
import { RouteStopDate } from '../QuoteRequest/RouteStopDate'
import TruckSelector from '../shared/TruckSelector'
import { ItemSummaryPanel } from './ItemSummaryPanel'
import { LoadCancellationPanel } from './LoadCancellationPanel'
import { LoadSummaryActions, LoadSummaryDetails } from './LoadSummaryDetails'
import { RouteSummary } from './RouteSummary'
import { TruckloadActions, TruckloadSummary } from './TruckloadSummary'

type Props = {
  activeIndex?: number
  equipmentType?: EquipmentType
  hasAwardedEquipment?: boolean
  isBooking?: boolean
  isEditingLoad?: boolean
  load?: FullShipment
  numberOfTrucks?: number
  quote?: UIQuote
  onDateEdit?: (sequence: number, date?: Date | null) => void
  selectTruck?: (i: number) => void
}

// TODO: parcel icon
export const LoadSummary = ({
  activeIndex = 0,
  equipmentType,
  hasAwardedEquipment,
  isBooking,
  isEditingLoad,
  load,
  numberOfTrucks = 1,
  quote,

  onDateEdit,
  selectTruck,
}: Props) => {
  const [isShowingItems, setShowingItems] = useState(false)
  const [isShowingCancel, setShowingCancel] = useState(false)
  const [editStopDateSequence, setEditStopDateSequence] = useState(-1)
  const navigate = useFvNavigate()
  const cancelLoad = useCancelLoad(load?.loadId)
  const isCanceling = cancelLoad.isLoading || cancelLoad.isSuccess
  const allowDateEdits = !!onDateEdit

  if (!load) {
    return (
      <div className="logistics-summary overflow-hidden">
        <InfoBox icon="sync">Loading shipment...</InfoBox>
      </div>
    )
  }

  const { locations, refNums, workflow } = load
  const showItems = () => setShowingItems(true)

  function cancelRequest(isAllLoads: boolean, redirect: string) {
    if (isCanceling) return
    cancelLoad.mutate(isAllLoads, {
      onSuccess: () => navigate(redirect),
    })
  }

  return (
    <div className="logistics-summary row-start-2">
      <List bare className="[scrollbar-gutter:stable]">
        {numberOfTrucks > 1 && (
          <ListItem className="">
            <div className="flex items-center gap-x-1">
              <span className="mr-1">Truck</span>

              {arrayOfLength(numberOfTrucks).map(i => (
                <TruckSelector
                  active={i === activeIndex}
                  key={i}
                  selectTruck={() => selectTruck?.(i)}
                  truckNumber={i + 1}
                />
              ))}
            </div>
          </ListItem>
        )}

        {workflow === 'truckload' && !!refNums?.length && (
          <ListItem className="b1200:first:pl-0">
            <Icon icon="hashtag" className="fa-fw text-fv-gray-dark" />
            <span>{refNums.map(n => n.value).join(' / ')}</span>
          </ListItem>
        )}

        <LoadSummaryDetails load={load} showItems={showItems} />

        <TruckloadSummary
          equipmentType={equipmentType}
          isBooking={isBooking}
          load={load}
          showItems={showItems}
        />
      </List>

      <RouteSummary
        numberOfTrucks={numberOfTrucks}
        load={load}
        editOptions={
          allowDateEdits && isEditingLoad
            ? [
                quoteFuncs.isSpotWorkflow(quote) ? 'all' : 'origin',
                seq => setEditStopDateSequence(seq),
              ]
            : undefined
        }
      />

      <List lcp0 className="mt-auto">
        <LoadSummaryActions load={load} />

        <TruckloadActions
          cancelRequest={cancelRequest}
          hasAwardedEquipment={hasAwardedEquipment}
          isBooking={isBooking}
          isCanceling={isCanceling}
          isEditingLoad={isEditingLoad}
          load={load}
          openCancellationPanel={() => setShowingCancel(true)}
        />
      </List>

      <ItemSummaryPanel
        activeIndex={activeIndex}
        isOpen={isShowingItems}
        load={load}
        numberOfTrucks={numberOfTrucks}
        setOpen={setShowingItems}
      />

      <LoadCancellationPanel
        cancelRequest={cancelRequest}
        hasAwardedEquipment={hasAwardedEquipment}
        isCanceling={isCanceling}
        isOpen={isShowingCancel}
        numberOfTrucks={numberOfTrucks}
        setOpen={setShowingCancel}
      />

      {allowDateEdits && (
        <RouteStopDate
          onClose={() => setEditStopDateSequence(-1)}
          isOpen={editStopDateSequence >= 0}
          sequence={editStopDateSequence}
          onChange={({ stopDate }) =>
            onDateEdit(editStopDateSequence, stopDate)
          }
          showStopDetailConfig={false}
          stops={locations}
        />
      )}
    </div>
  )
}
