import { Icon, Tooltip } from '@fv/client-components'

import { type ShipmentContentProps } from '../../features/shipment-details-panel/types'
import { ShipmentTagsControl } from '../../features/tags/ShipmentTagsControl'
import { TrackingHistory } from '../../features/track/TrackingHistory'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { getCarrierDetails } from '../../utils/shipmentFuncs'
import { ShipmentTrackingBar } from '../ShipmentTrackingBar'
import { DeliveryDate } from './DeliveryDate'
import { NotificationsBanner } from './NotificationsBanner'
import { RouteDetails } from './RouteDetails'

export const DetailsPanel = ({
  load,
  setActiveSlider,
}: ShipmentContentProps) => {
  const { selectedQuote } = load
  const { carrierPublicSettings } = useCarrierPublicSettings(
    selectedQuote?.carrierId,
  )
  const carrierDetails = getCarrierDetails(load)

  return (
    <div className="shipment-details-main flex flex-col flex-nowrap items-stretch b1350:row-start-3">
      <NotificationsBanner
        carrierSettings={carrierPublicSettings}
        load={load}
        setActiveSlider={setActiveSlider}
      />
      <div className="shipment-details-main__header">
        <div className="flex items-start gap-4">
          {carrierDetails && (
            <span className="whitespace-nowrap rounded-sm border border-[#dbd59e] bg-[#edeacb] px-2 py-1">
              <Icon icon="truck" className="text-fv-gray-dark" />
              <span>{carrierDetails}</span>
            </span>
          )}
          <p className="mb-0 mt-[.2rem] flex items-start">
            <DeliveryDate load={load} />
          </p>

          {carrierPublicSettings?.dispatchPhone && (
            <a
              className="mt-[.2rem] whitespace-nowrap border-l border-l-[#dbd59e] pl-4 leading-[1.4rem]"
              href={`tel:${carrierPublicSettings.dispatchPhone}`}
            >
              <Tooltip label={carrierPublicSettings.dispatchPhone}>
                <Icon icon="phone" />
              </Tooltip>
              <span className="b1450:hidden b1350:inline b1100:hidden">
                {carrierPublicSettings.dispatchPhone}
              </span>
            </a>
          )}
        </div>
      </div>

      <div className="flex-1 px-6 pb-6 pt-0">
        <ShipmentTrackingBar
          tracking={load.tracking}
          onClick={() => setActiveSlider('track')}
        />
        <div className="input-group mb-4">
          <div className="input-group__prepend">
            <div className="input-group__addon whitespace-nowrap">
              Tag this shipment
            </div>
          </div>
          <ShipmentTagsControl loadId={load.loadId} />
        </div>

        <RouteDetails holdAtLocation={load.holdAtLocation} load={load} />
        <TrackingHistory loadId={load.loadId} tracking={load.tracking} />
      </div>
    </div>
  )
}
