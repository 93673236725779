import { FvButton, SliderPanel } from '@fv/client-components'

import { routes } from '../../routes'
import InfoBox from '../InfoBox'

type Props = {
  cancelRequest: (all: boolean, redirect: string) => void
  hasAwardedEquipment?: boolean
  isCanceling: boolean
  isOpen: boolean
  numberOfTrucks: number
  setOpen: (open: boolean) => void
}

export const LoadCancellationPanel = ({
  cancelRequest,
  hasAwardedEquipment,
  isCanceling,
  isOpen,
  numberOfTrucks,
  setOpen,
}: Props) => {
  return (
    <SliderPanel closePanel={() => setOpen(false)} isOpen={isOpen}>
      <div className="flex h-full flex-col">
        <InfoBox>
          {numberOfTrucks === 1 && (
            <p className="mb-0">
              Canceling this quote request will lose all of its data. Carriers
              will be notified that this load is no longer available. Proceed?
            </p>
          )}

          {numberOfTrucks > 1 && (
            <p className="mb-0">
              This quote request contains multiple loads. Do you just want to
              cancel this specific load or every load for this entire quote
              request? Canceling a load will lose all of its data
              {hasAwardedEquipment &&
                ', including awarded and confirmed trucks'}
              . Carriers will be notified that these loads are no longer
              available. Proceed?
            </p>
          )}
        </InfoBox>

        <div className="mb-2 flex">
          <FvButton
            theme="default"
            icon="times"
            onClick={() => !isCanceling && setOpen(false)}
          >
            No
          </FvButton>

          {numberOfTrucks > 1 && (
            <FvButton
              onClick={() => cancelRequest(false, routes.quote('truckload'))}
              icon={isCanceling ? 'spinner' : 'check'}
              theme="primary"
            >
              <span>Yes, this load only</span>
            </FvButton>
          )}

          <FvButton
            onClick={() => cancelRequest(true, routes.quote('truckload'))}
            icon={isCanceling ? 'spinner' : 'check'}
            theme="primary"
          >
            <span>
              {numberOfTrucks > 1
                ? 'Yes, all loads'
                : 'Yes, cancel this quote request'}
            </span>
          </FvButton>
        </div>
      </div>
    </SliderPanel>
  )
}
