import { type SignatureAccessorialType, type Workflow } from '@fv/client-types'
import { type LocationAccessorialType } from '@fv/models'

import CheckboxField from '../../components/inputs/CheckboxField'
import { InputGroup } from '../../components/inputs/InputGroup'
import { type FormLocation } from '../../types/FormLocation'
import { hasInsideOption, hasLiftgateOption } from '../../utils/locationFuncs'

type Props = {
  workflow: Workflow
  value: FormLocation
  disabled?: boolean
  onChange: (v: FormLocation) => void
}
export const LocationAccessorialForm = (props: Props) => {
  const { workflow, value } = props

  if (workflow === 'ltl' && value.type === 'origin') {
    return <LtlOriginAccessorials {...props} />
  } else if (workflow === 'ltl' && value.type === 'destination') {
    return <LtlDestinationAccessorials {...props} />
  } else if (workflow === 'parcel' && value.type === 'origin') {
    return <ParcelOriginAccessorials {...props} />
  } else if (workflow === 'parcel' && value.type === 'destination') {
    return <ParcelDestinationAccessorials {...props} />
  }
  return null
}

const ParcelDestinationAccessorials = ({
  value,
  disabled,
  onChange,
}: Props) => {
  return (
    <div>
      <div className="form-group border-b border-dashed border-b-[#99d87a] pb-3">
        <CheckboxField
          disabled={disabled}
          onChange={() => onChange(toggleResidential(value))}
          checked={value.shipType === 'residential'}
          name={`${value.type}.residential`}
          className="checkbox checkbox--vertical"
          label="Residential location"
        />
      </div>

      <InputGroup
        className="b1600:basis-1/2 basis-1/3"
        inputType="radio"
        label="Signature Required"
        inputProps={{
          name: 'signature',
          value:
            value.accessorials.find(a => a.key.includes('signature'))?.key ??
            '',
          onChange: e => {
            onChange(
              updateSignatureAccessorial(
                value,
                e.target.value as SignatureAccessorialType,
              ),
            )
          },
          options: [
            { label: 'No', value: '' },
            { label: 'Yes', value: 'signature-required' },
            {
              label: 'Adult',
              value: 'adult-signature-required',
            },
            ...(value.shipType === 'residential'
              ? [
                  {
                    label: 'Indirect',
                    value: 'indirect-signature-required',
                  },
                ]
              : []),
          ],
        }}
      />
    </div>
  )
}

const LtlDestinationAccessorials = ({ value, disabled, onChange }: Props) => {
  const hasDestInsideOption = hasInsideOption(value.shipType)
  const hasDestLiftgateOption = hasLiftgateOption(value.shipType)
  return (
    <>
      {hasDestLiftgateOption && (
        <CheckboxField
          disabled={disabled}
          {...getCheckboxProps(value, 'liftgate', onChange)}
          label="Liftgate required for delivery?"
        />
      )}

      {hasDestInsideOption && (
        <CheckboxField
          disabled={disabled}
          {...getCheckboxProps(value, 'inside', onChange)}
          label="Inside delivery required?"
        />
      )}

      {(hasDestInsideOption || hasDestLiftgateOption) && <hr />}
      <CheckboxField
        disabled={disabled}
        {...getCheckboxProps(value, 'notify', onChange)}
        label="Call/notify before delivery"
      />

      <CheckboxField
        disabled={disabled}
        {...getCheckboxProps(value, 'schedule', onChange)}
        label="Delivery appointment required"
      />

      <CheckboxField
        disabled={disabled}
        {...getCheckboxProps(value, 'sort-and-segregate', onChange)}
        label="Sort and segregate"
      />
      <CheckboxField
        disabled={disabled}
        {...getCheckboxProps(value, 'twic', onChange)}
        label="TWIC required"
      />
    </>
  )
}

const ParcelOriginAccessorials = ({ value, disabled, onChange }: Props) => {
  return (
    <div className="mx-1 mb-4 basis-full border-b border-dashed border-b-gray-400 pb-3">
      <CheckboxField
        disabled={disabled}
        onChange={() => onChange(toggleResidential(value))}
        checked={value.shipType === 'residential'}
        name={`${value.type}.residential`}
        className="checkbox checkbox--vertical"
        label="Residential location"
      />
    </div>
  )
}

const LtlOriginAccessorials = ({
  workflow,
  value,
  disabled,
  onChange,
}: Props) => {
  const hasOriginInsideOption = hasInsideOption(value.shipType)
  const hasOriginLiftgateOption = hasLiftgateOption(value.shipType)
  if (
    workflow !== 'ltl' ||
    !(hasOriginInsideOption || hasOriginLiftgateOption)
  ) {
    return null
  }

  return (
    <div className="form-group mb-4 mt-2">
      {hasOriginLiftgateOption && (
        <CheckboxField
          disabled={disabled}
          {...getCheckboxProps(value, 'liftgate', onChange)}
          label="Liftgate required for pickup?"
        />
      )}

      {hasOriginInsideOption && (
        <CheckboxField
          disabled={disabled}
          {...getCheckboxProps(value, 'inside', onChange)}
          label="Inside pickup required?"
        />
      )}
    </div>
  )
}

const hasAccessorial = (value: FormLocation, name: LocationAccessorialType) =>
  value.accessorials.some(s => s.key === name)

function getCheckboxProps(
  value: FormLocation,
  name: LocationAccessorialType,
  onChange?: Props['onChange'],
) {
  const residentialCheck =
    name === 'residential' && value.shipType === 'residential'
  return {
    name: `${value.type}.${name}`,
    className: 'checkbox checkbox--vertical',
    checked: hasAccessorial(value, name) || residentialCheck,
    ...(onChange && {
      onChange: () => onChange(toggleAccessorial(value, name)),
    }),
  }
}

const toggleResidential = (value: FormLocation) => {
  const nextValue = { ...value }
  const stopType = value.type
  const isResidential = nextValue.shipType === 'residential'

  nextValue.shipType = isResidential ? 'business dock' : 'residential'
  if (isResidential && stopType === 'destination') {
    nextValue.accessorials = nextValue.accessorials.filter(
      a => a.key !== 'indirect-signature-required',
    )
  }
  return nextValue
}

const toggleAccessorial = (
  value: FormLocation,
  accessorial: LocationAccessorialType,
) => {
  const nextValue = { ...value }
  const hasAccessorial = value.accessorials.some(a => a.key === accessorial)

  nextValue.accessorials = hasAccessorial
    ? nextValue.accessorials.filter(a => a.key !== accessorial)
    : nextValue.accessorials
        .filter(a => {
          // Cannot select both notify & schedule
          if (accessorial === 'notify') return a.key !== 'schedule'
          if (accessorial === 'schedule') return a.key !== 'notify'
          return true
        })
        .concat({ key: accessorial })

  return nextValue
}

const updateSignatureAccessorial = (
  value: FormLocation,
  sigAccessorial: SignatureAccessorialType,
) => {
  const nextValue = {
    ...value,
    accessorials: value.accessorials.filter(a => !a.key.includes('signature')),
  }
  if (sigAccessorial) {
    nextValue.accessorials.push({ key: sigAccessorial })
  }
  return nextValue
}
