import upperFirst from 'lodash/upperFirst'
import { type Dispatch, type SetStateAction, useState } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import { FvButton, Icon } from '@fv/client-components'
import { getEquipmentLabel, totalShipmentWeight } from '@fv/client-core'
import { type FullShipment, type UIQuote } from '@fv/client-types'

import { BidExpirationEditor } from '../../../features/bid-expiration/BidExpirationEditor'
import {
  ChargeList,
  useCanShowCharges,
} from '../../../features/mini-quote-list/ChargeList'
import { quoteFuncs } from '../../../features/quote/quoteFuncs'
import { LoadUserDisplay } from '../../../features/shipment-list/LoadUserDisplay'
import {
  useCurrency,
  usePrettyNumber,
  useTypeNames,
} from '../../../hooks/settings'
import { useUpdateTargetPrice } from '../../../hooks/spotQuotes'
import { InputAdornment, InputGroup } from '../../inputs/InputGroup'

type Props = {
  isShowingRates: boolean
  load: FullShipment
  setShowingRates: Dispatch<SetStateAction<boolean>>
  hasAnyResponses?: number
}

const PanelHeader = ({
  isShowingRates,
  load,
  setShowingRates,
  hasAnyResponses,
}: Props) => {
  const { equipment, isLiveLoad, refNums, selectedQuote, status, targetPrice } =
    load
  const totalWeight = totalShipmentWeight(load)
  const { equipmentName } = useTypeNames()
  const prettyNumber = usePrettyNumber()
  const [updatedTargetPrice, setTargetPrice] = useState(targetPrice)
  const [showCharges, toggleCharges] = useToggle()

  const equipmentLabel = getEquipmentLabel({
    altEquipmentNames: equipment.alternateTypes?.map(
      (t, i) => `${equipmentName(t)} (A${i + 1})`,
    ),
    equipmentName: equipmentName(
      selectedQuote?.equipmentType ?? equipment.type,
    ),
    isAwarded: !!selectedQuote,
  })

  const sendTargetPrice = useUpdateTargetPrice()
  const [showTargetField, setShowTargetField] = useState(false)

  const updateTargetPrice = () => {
    sendTargetPrice.mutate(
      {
        loadId: load.loadId,
        targetPrice: updatedTargetPrice,
      },
      {
        onSuccess: () => {
          setShowTargetField(false)
          setTargetPrice(updatedTargetPrice)
          toast.success('Target price has been updated.')
        },
      },
    )
  }

  return (
    <>
      {status === 'awarded' && isShowingRates && (
        <nav className="shipment-list-ancillary-details__nav alternate divided-content divided-content--start mb-6">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              setShowingRates(false)
            }}
          >
            <Icon icon="arrow-to-left" />
            <span>Back</span>
          </a>
          <h6 className="mb-0">
            <Icon icon="truck-moving" className="text-fv-gray-dark" />
            <span>Select a new carrier</span>
          </h6>
        </nav>
      )}

      <p className="mb-2 !leading-[1.6rem]">
        {selectedQuote && quoteFuncs.isSpotWorkflow(selectedQuote) && (
          <>
            <span>
              <Icon icon="trophy" className="text-fv-gray-dark" />
              <span>Awarded - awaiting confirmation</span> <br />
            </span>
            <QuotePriceButton quote={selectedQuote} onClick={toggleCharges} />
            <br />
          </>
        )}

        {showCharges && <ChargeList quote={selectedQuote} className="pl-3" />}

        {equipmentLabel}
        <span className="shipment-list-ancillary-details__live-load">
          {' / '}
          {isLiveLoad ? 'Live load' : 'Quote only'}
        </span>
        {refNums?.length > 0 &&
          ` / Ref# ${refNums.map(n => n.value).join(' / ')}`}
        <br />
        {equipment.isHazardous && (
          <Icon icon="radiation-alt" className="text-fv-orange" />
        )}
        <span>
          {equipment.isHazardous && 'Hazardous / '}
          {equipment.description && `${upperFirst(equipment.description)} / `}
          {prettyNumber(totalWeight)} {equipment.weightUOM}.
        </span>
        <br />
        <span>
          Quoted by <LoadUserDisplay {...load.createdBy} />
        </span>
        <br />
        {hasAnyResponses > 0 && (
          <>
            {!showTargetField ? (
              <span className="block">
                Target price:{' '}
                {/* if no target value for bidding has been entered, we should show 'None'. 
            inside the following button and it should open a text field so they can enter a target price*/}
                <FvButton
                  theme="underlined"
                  onClick={() => setShowTargetField(true)}
                >
                  {targetPrice ? <>${targetPrice}</> : <>none</>}
                </FvButton>
              </span>
            ) : (
              <InputGroup
                className="my-2"
                inputType="amount"
                inputProps={{
                  name: 'target-price',
                  placeholder: 'Target price',
                  onChange: e => setTargetPrice(parseFloat(e.target.value)),
                  value: updatedTargetPrice,
                  isPrice: true,
                  type: 'number',
                  min: 1,
                }}
                startContent={
                  <InputAdornment position="start" icon="dollar-sign" />
                }
                endContent={
                  <>
                    <InputAdornment position="end">
                      <FvButton
                        theme="default"
                        icon="check"
                        onClick={updateTargetPrice}
                      >
                        <span>Save</span>
                      </FvButton>
                    </InputAdornment>
                    <InputAdornment position="end">
                      <FvButton
                        theme="plain"
                        icon="times"
                        onClick={() => {
                          setShowTargetField(false)
                          setTargetPrice(null)
                        }}
                      />
                    </InputAdornment>
                  </>
                }
              />
            )}
            <BidExpirationEditor load={load} />
          </>
        )}
      </p>
    </>
  )
}

type ButtonProps = {
  quote: UIQuote
  onClick?: () => void
}
const QuotePriceButton = ({ quote, onClick }: ButtonProps) => {
  const canShowCharges = useCanShowCharges(quote)
  const priceParts = useCurrency()
  const [dollars, cents] = priceParts(quote?.amount)
  if (!canShowCharges) {
    return (
      <span>
        {quote.providerName} ${dollars}.{cents}
      </span>
    )
  }

  return (
    <span>
      {quote.providerName}{' '}
      <FvButton onClick={onClick} theme="underlined">
        ${dollars}.{cents}
      </FvButton>
    </span>
  )
}

export default PanelHeader
