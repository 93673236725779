import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import { useFvNavigate } from '@fv/client-components'

import { InputAdornment, InputGroup } from './inputs/InputGroup'

const TopNavSearch = ({ focusSearch, resetNav }) => {
  const [searchText, setSearchText] = useState('')
  const navigate = useFvNavigate()
  const searchInput = useRef()

  useEffect(() => {
    if (focusSearch) {
      searchInput.current?.focus()
    }
  }, [focusSearch])

  return (
    <InputGroup
      className={clsx('mb-0 flex-1 b1350:hidden ml-4', focusSearch && '!block')}
      custom
      inputType="text"
      inputProps={{
        placeholder: 'PRO#, BOL#, REF#, tracking#, location or company',
        value: searchText,
        ref: searchInput,
        name: 'searchText',
        onChange: e => setSearchText(e.target.value),
        onKeyDown: e => {
          if (e.key === 'Enter' && searchText.trim()) {
            const text = encodeURIComponent(searchText.trim())
            setSearchText('')
            navigate(`/search?text=${text}`)
            resetNav && resetNav()
          }
        },
      }}
      startContent={
        <InputAdornment
          icon="search"
          iconClass="text-fv-green"
          position="start"
        />
      }
    />
  )
}

TopNavSearch.propTypes = {
  focusSearch: PropTypes.bool,
  resetNav: PropTypes.func,
}

export default TopNavSearch
