import { useState } from 'react'
import { type FieldPath } from 'react-hook-form'

import { type FormModelState, setPathValue } from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'
import { CountryMapper, states } from '@fv/models'

import {
  AddressInput,
  type AddressInputProps,
} from '../../../components/inputs/AddressInput'
import { InputGroupWrapper } from '../../../components/inputs/InputGroup'
import { StateSelector } from '../../../components/inputs/StateSelector'
import { TabLink, TabLinkList } from '../../../components/TabLink'
import { type AddressWithCompany } from '../../addresses/addressFuncs'
import { type PartialContractedRate } from './types'

export const locTypes = ['zip', 'zip-range', 'state', 'state-to-state'] as const
export type LocType = (typeof locTypes)[number]

type Props = {
  form: FormModelState<PartialContractedRate>
  initialType: LocType
}

const locLabels: Record<LocType, { full: string; short: string }> = {
  'zip-range': {
    full: 'Postal code to range of postal codes',
    short: 'Postal code to range',
  },
  zip: {
    full: 'Postal code to postal code',
    short: 'Postal code to postal code',
  },
  state: {
    full: 'Postal code to state',
    short: 'Postal code to state',
  },
  'state-to-state': {
    full: 'State to state',
    short: 'State  to state',
  },
}

export const RateLocationForm = ({ form, initialType }: Props) => {
  const [rateType, setRateType] = useState<LocType>(initialType)
  const { setValue } = form
  const handleTypeChange = (loc: LocType) => {
    setRateType(loc)
    if (loc !== 'zip-range') {
      setValue(p => ({
        ...p,
        destination: {
          ...p.destination,
          postalCodeEnd: undefined,
        },
      }))
    }

    if (loc !== 'state' && loc !== 'state-to-state') {
      setValue(p => ({
        ...p,
        destination: {
          ...p.destination,
          state: undefined,
        },
      }))
    }

    if (loc !== 'state-to-state') {
      setValue(p => ({
        ...p,
        origin: {
          ...p.origin,
          state: undefined,
        },
      }))
    }
  }
  return (
    <div className=" border-fv-blue-200 -mx-8 mb-4 border-t ">
      <div className="bg-fv-beer-light flex items-center gap-2 px-8 pb-3 pt-2">
        <span className="pt-1 b1500:hidden">Rate type &gt;</span>
        <TabLinkList className="flex-wrap pt-1 xl:flex-nowrap">
          {locTypes.map(locType => (
            <TabLink
              key={locType}
              isActive={rateType === locType}
              icon="dot-circle"
              onClick={() => handleTypeChange(locType)}
            >
              <span className="b1500:hidden b1350:inline-block">
                {locLabels[locType].full}
              </span>
              <span className="hidden b1500:inline-block b1350:hidden">
                {locLabels[locType].short}
              </span>
            </TabLink>
          ))}
        </TabLinkList>
      </div>
      <div className="bg-fv-blue-100 border-fv-blue-200 grid grid-cols-2 border-b border-t px-8 pb-2 pt-4 gap-x-2">
        {rateType !== 'state-to-state' && (
          <RateLocationFormPart type="zip" part="origin" form={form} />
        )}

        {rateType === 'state-to-state' && (
          <RateLocationFormPart type={rateType} part="origin" form={form} />
        )}

        <RateLocationFormPart part="destination" type={rateType} form={form} />
      </div>
    </div>
  )
}

type PartProps = Pick<Props, 'form'> & {
  part: 'origin' | 'destination'
  type: LocType
}
const RateLocationFormPart = ({ part, type, form }: PartProps) => {
  const { value, setValue } = form
  const loc = value[part]
  const postalCodeKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.postalCode' : 'destination.postalCode'
  const postalCodeEndKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.postalCodeEnd' : 'destination.postalCodeEnd'
  const countryKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.country' : 'destination.country'
  const stateKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.state' : 'destination.state'
  const labelPrefix = part === 'origin' ? 'Origin' : 'Destination'
  const label = (text: string) => `${labelPrefix} ${text}`
  const locState = states.find(v => v.abbreviation === loc.state)

  return (
    <>
      {type === 'zip' && (
        <InputGroupWrapper
          key={`${part}.zip-postal`}
          name={postalCodeKey}
          label={label('postal code')}
          className="flex-1"
          required
        >
          <div className="w-full">
            <PostalCodeInput
              name={postalCodeKey}
              onLocationChange={v =>
                setValue(p => ({
                  ...setPathValue(
                    setPathValue(p, countryKey, v?.country ?? 'us'),
                    postalCodeKey,
                    v?.postalCode,
                  ),
                }))
              }
              location={{
                postalCode: loc.postalCode,
                country: loc.country,
              }}
            />
          </div>
        </InputGroupWrapper>
      )}
      {type === 'zip-range' && (
        <InputGroupWrapper
          key={`${part}.range-postal`}
          name={postalCodeKey}
          label={label('postal code range')}
          labelClassName="whitespace-nowrap"
          required
        >
          <div className="flex items-center gap-2 w-full">
            <PostalCodeInput
              name={postalCodeKey}
              onLocationChange={v => {
                setValue(p => ({
                  ...setPathValue(
                    setPathValue(p, countryKey, v?.country ?? 'us'),
                    postalCodeKey,
                    v?.postalCode,
                  ),
                }))
              }}
              location={{
                postalCode: loc.postalCode,
                country: loc.country,
              }}
            />

            <div className="bg-fv-blue-300 flex h-8 w-8 items-center justify-center rounded-full p-1 text-lg flex-none">
              to
            </div>

            <PostalCodeInput
              name={postalCodeEndKey}
              onLocationChange={v =>
                setValue(p => ({
                  ...setPathValue(p, postalCodeEndKey, v?.postalCode),
                }))
              }
              location={{
                postalCode: loc.postalCodeEnd,
                country: loc.country,
              }}
            />
          </div>
        </InputGroupWrapper>
      )}
      {(type === 'state' || type === 'state-to-state') && (
        <InputGroupWrapper
          key={stateKey}
          name={stateKey}
          label={label('state')}
          className="flex-1"
          labelClassName="whitespace-nowrap"
          required
        >
          <StateSelector
            name={stateKey}
            value={
              locState
                ? `${locState.name}, ${CountryMapper.toUpper(locState.country)}`
                : undefined
            }
            onChange={v => {
              setValue(p => ({
                ...setPathValue(
                  setPathValue(
                    setPathValue(
                      setPathValue(p, stateKey, v?.abbreviation),
                      postalCodeKey,
                      '',
                    ),
                    postalCodeEndKey,
                    '',
                  ),
                  countryKey,
                  v?.country ?? 'us',
                ),
              }))
            }}
          />
        </InputGroupWrapper>
      )}
    </>
  )
}

type PciProps = Pick<
  AddressInputProps,
  'name' | 'onLocationChange' | 'location'
> & {
  format?: (loc: AddressWithCompany) => string
}
const PostalCodeInput = (props: PciProps) => {
  const format = (x: AddressWithCompany) =>
    x.postalCode ? `${x.postalCode}, ${CountryMapper.toUpper(x.country)}` : ''
  return (
    <AddressInput
      context="truckload"
      cacheFormattedLocation={false}
      includeAddressBook // TODO: https://github.com/freightview/freightview/issues/21354 - change to not include address book values once server accepts that option
      filterOptions={dedupePostalCodes}
      formatOptionLabel={props.format ?? format}
      formatAddress={format}
      required
      {...props}
    />
  )
}
function dedupePostalCodes(
  options: Array<{ label: string; value: ClientAddressLookupResult }>,
) {
  return options.filter((o, i, list) => {
    return i === list.findIndex(opt => opt.label === o.label)
  })
}
