import clsx from 'clsx'
import dayjs from 'dayjs'
import {
  type ChangeEvent,
  type ClipboardEvent,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import toast from 'react-hot-toast'

import {
  FvButton,
  FvLinkButton,
  Icon,
  SliderPanel,
  TextAreaField,
  TextField,
  Tooltip,
  ValidatedForm,
} from '@fv/client-components'
import { dateToStringWithLocalTimezoneAbbr } from '@fv/client-core'
import { type FullShipment, type Workflow } from '@fv/client-types'
import { type EquipmentType, type LoadBidExpiration } from '@fv/models'

import InfoBox from '../../components/InfoBox'
import CheckboxField from '../../components/inputs/CheckboxField'
import { InputAdornment } from '../../components/inputs/InputGroup'
import MultiSelectField from '../../components/inputs/MultiSelectField'
import UploadDocumentField from '../../components/inputs/UploadDocumentField'
import { List, ListItem } from '../../components/List'
import { RouteStopDate } from '../../components/QuoteRequest/RouteStopDate'
import TruckSelector from '../../components/shared/TruckSelector'
import { useShowBidExpiration } from '../../features/settings/account-settings/hooks'
import { useQuoteSettings } from '../../hooks/settings'
import { useSendSpotQuoteRequests } from '../../hooks/spotQuotes'
import { useRecentGroups } from '../../hooks/spotQuotes/useRecentGroups'
import arrayOfLength from '../../utils/arrayOfLength'
import { getDestination } from '../../utils/shipmentFuncs'
import { usePermissions } from '../auth'
import { ExpirationDatePickerPanel } from '../bid-expiration/ExpirationDatePanel'
import DocumentList from '../documents/DocumentList'
import {
  SliderContentLayout,
  SliderGenericHeader,
} from '../shipment-details-panel/SliderContentLayout'
import { useSpotQuoteGroupsWithUngrouped } from '../spot-quote-contacts/queries'
import {
  ALL_CONTACTS_ID,
  type SpotQuoteGroup,
} from '../spot-quote-contacts/types'
import {
  SpotQuoteEmailConfirmation,
  SpotQuoteEmailPreview,
  useSpotQuoteEmailMessage,
} from './SpotQuoteEmailPreview'
import { SpotQuoteGroupEditor } from './SpotQuoteGroupEditor'
import {
  useSpotQuoteContactListStore,
  useSpotQuoteListActions,
  useSpotQuoteListSelected,
} from './store'
import { type SpotQuoteFormData } from './types'
import { useUpdateDeliveryDate } from './useUpdateDeliveryDate'

type AltEquipmentFieldProps = {
  altEquipment: EquipmentType[]
  readOnly: boolean
  setAltEquipment: Dispatch<SetStateAction<EquipmentType[]>>
  workflow: Workflow
}

const AltEquipmentField = ({
  altEquipment,
  readOnly,
  setAltEquipment,
  workflow,
}: AltEquipmentFieldProps) => {
  const settingsQuery = useQuoteSettings(workflow)
  const equipmentTypes = settingsQuery.data?.equipmentOptions ?? []
  const options = equipmentTypes.map(o => ({
    label: o.name,
    value: o.key,
  }))

  return (
    <MultiSelectField
      // @ts-expect-error component not TS
      containerClassName="mb-4"
      isClearable={false}
      isSearchable
      name="alternate-types"
      onChange={(nextValues: Array<{ value: EquipmentType }>) => {
        setAltEquipment(nextValues.map(v => v.value))
      }}
      options={options}
      placeholder="Optional equipment types"
      readOnly={readOnly}
      values={options.filter(o => altEquipment.includes(o.value))}
    />
  )
}

type SpotQuoteFormProps = {
  activeLoadIndex: number
  close: () => void
  loads: FullShipment[]
  onSuccess?: () => void
  selectTruck?: (i: number) => void
  formData: SpotQuoteFormData[]
  setFormData: Dispatch<SetStateAction<SpotQuoteFormData[]>>
  groups: Group[]
  setGroups: Dispatch<SetStateAction<Group[]>>
}

const SpotQuoteForm = ({
  activeLoadIndex,
  close,
  loads,
  onSuccess,
  selectTruck,
  setFormData,
  formData,
  groups,
  setGroups,
}: SpotQuoteFormProps) => {
  const { locations, documents, loadId, equipment, workflow } = loads[0]
  const [altEquipment, setAltEquipment] = useState([
    equipment.type,
    ...(equipment.alternateTypes ?? []),
  ])
  const showBidExpiration = useShowBidExpiration()

  const [uploadDoc, setUploadDoc] = useState(false)
  const [isPickingDate, setPickingDate] = useState(false)
  const [isPreviewing, setPreviewing] = useState(false)
  const [isEditingGroups, setEditingGroups] = useState(false)
  const [shipperMessage, setShipperMessage] = useState('')

  const sendRequests = useSendSpotQuoteRequests(equipment.mode)
  const updateDeliveryDate = useUpdateDeliveryDate()
  const readOnly = sendRequests.isLoading || sendRequests.isSuccess
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const destination = getDestination({ locations })
  const emailsString = useSpotQuoteContactListStore(s => s.emails)
  const emails = useSpotQuoteListSelected()
  const { canManage } = usePermissions()
  const placeHolderText = `Enter any additional details here for truck ${activeLoadIndex + 1}`
  const systemMessage = useSpotQuoteEmailMessage(loads, altEquipment, formData)
  const { setRawEmail, setEmails } = useSpotQuoteListActions()
  const activeLoad = loads[activeLoadIndex]
  const activeFormData = formData.find(l => l.loadId === activeLoad.loadId)
  const selectedGroups = groups.filter(g => g.checked)

  const handleToggleAllGroups = () => {
    setGroups(prev => {
      const allChecked = !prev.some(g => !g.checked)
      return prev.map(g => ({
        ...g,
        checked: !allChecked,
      }))
    })
  }

  function toggleGroup(e: ChangeEvent<HTMLInputElement>) {
    const { checked, value } = e.target

    setGroups(prev => {
      return prev.map(g => ({
        ...g,
        checked: g.id === value ? checked : g.checked,
      }))
    })
  }

  function onPaste(e: ClipboardEvent<HTMLInputElement>) {
    const message = e.clipboardData.getData('text')

    if (message.length > 500) {
      toast('Max of 500 characters, your message has been shortened.')
    }
  }

  function onValidSubmit() {
    if (readOnly) return

    if (!emails?.length) {
      textAreaRef.current?.setCustomValidity(
        'Please enter a valid email address.',
      )

      textAreaRef.current?.reportValidity()
      return
    }

    const selectedGroupIds = groups.filter(g => g.checked).map(g => g.id)

    sendRequests.mutate(
      {
        emails,
        loadIds: loads.map(x => x.loadId),
        selectedGroupIds,
        shipperMessage,
        systemMessage,
        bidExpirations: formData.map(x => ({
          loadId: x.loadId,
          bidExpiration: x.bidExpiration,
        })),
        targetPrices: formData.map(x => ({
          loadId: x.loadId,
          targetPrice: x.targetPrice,
        })),
        ...(equipment?.mode === 'ltl' && {
          alternateTypes: altEquipment,
        }),
      },
      {
        onSuccess: () => {
          setGroups(prev => prev.map(g => ({ ...g, checked: false })))
          setShipperMessage('')
          onSuccess?.()

          if (textAreaRef.current) {
            textAreaRef.current.form?.reset()
            setEmails([])
          }
        },
      },
    )
  }

  return (
    <>
      <div className="bg-fv-beer-light border-t-fv-beer-medium basis-1/3 overflow-auto border-t p-8">
        <header className="border-fv-blue-300 mb-5 flex items-center border-b border-dashed pb-2">
          <h6 className="mb-0 mr-3 border-r border-gray-200 pr-3">
            Spot quote groups
          </h6>

          <FvLinkButton
            icon={groups.some(g => !g.checked) ? 'check' : 'times'}
            onClick={handleToggleAllGroups}
          >
            <span className="b1650:inline b1450:hidden b1250:inline hidden">
              All
            </span>
            <span className="b1650:hidden b1450:inline b1250:hidden inline">
              {groups.some(g => !g.checked) ? (
                <span>Select all</span>
              ) : (
                <span>All</span>
              )}
            </span>
          </FvLinkButton>
        </header>

        <List fcp0>
          {groups.map(g => {
            const name = `group-${g.id}`

            return (
              <ListItem className=" !border-fv-beer-medium" key={name}>
                <CheckboxField
                  checked={g.checked}
                  label={g.label}
                  labelClassName="limit-characters"
                  name={name}
                  onChange={toggleGroup}
                  tooltipOnOverflow
                  value={g.id}
                />
              </ListItem>
            )
          })}

          {!groups.length && (
            <div className="flex justify-center">
              <Icon icon="sync" size="2x" />
            </div>
          )}
        </List>
      </div>
      <ValidatedForm
        className="bg-fv-beer-light border-l-fv-beer-medium border-t-fv-beer-medium flex basis-2/3 flex-col flex-nowrap overflow-auto border-l border-t p-8"
        onValidSubmit={onValidSubmit}
      >
        <div className="relative mb-4 flex-1">
          <TextAreaField
            className="form-control h-full"
            name="email-list"
            placeholder="sandy@example.com, jim@example.com"
            readOnly={readOnly || !canManage}
            ref={textAreaRef}
            value={emailsString}
            onChange={e => setRawEmail(e.target.value)}
            required
          />
          <FvButton
            className="bg-fv-beer-light absolute right-0 top-0 z-40 h-auto border border-[#b3b3b3] px-2 py-1"
            icon="pen-alt"
            size="xs"
            onClick={() => setEditingGroups(true)}
          >
            Edit
          </FvButton>
        </div>

        {equipment.mode === 'ltl' && (
          <>
            <div className="input-group mb-4">
              <InputAdornment
                icon="calendar-day"
                iconClass="fa-fw text-fv-gray-dark"
                position="start"
                htmlFor="delivery-date"
              />
              <TextField
                className="form-control"
                name="delivery-date"
                placeholder="Delivery date"
                readOnly
                onFocus={() => setPickingDate(true)}
                value={
                  destination?.stopDate
                    ? dayjs(destination?.stopDate).format('ddd MMM D')
                    : ''
                }
              />
            </div>

            <AltEquipmentField
              altEquipment={altEquipment}
              readOnly={readOnly || !!equipment.alternateTypes?.length}
              setAltEquipment={setAltEquipment}
              workflow={workflow}
            />
          </>
        )}
        <div className="mb-3 grid grid-cols-12 items-center gap-x-2">
          <TargetPriceForm
            activeLoadIndex={activeLoadIndex}
            numberOfTrucks={loads.length}
            value={activeFormData.targetPrice}
            onChange={targetPrice =>
              setFormData(prev =>
                prev.map(p =>
                  p.loadId === activeLoad.loadId ? { ...p, targetPrice } : p,
                ),
              )
            }
            selectTruck={selectTruck}
          />
          {showBidExpiration && (
            <BidExpirationForm
              value={activeFormData.bidExpiration}
              onChange={bidExpiration =>
                setFormData(prev =>
                  prev.map(p =>
                    p.loadId === activeLoad.loadId
                      ? { ...p, bidExpiration }
                      : p,
                  ),
                )
              }
            />
          )}
        </div>

        <TextField
          className="form-control mb-4"
          maxLength={500}
          name="user-message"
          onChange={e => setShipperMessage(e.target.value)}
          onPaste={onPaste}
          placeholder={placeHolderText}
          readOnly={readOnly}
          value={shipperMessage}
        />

        <div className="flex items-center self-end">
          <Tooltip label="Cancel / close panel">
            <FvButton icon="times" onClick={close} theme="plain" />
          </Tooltip>
          <FvButton
            icon="file-plus"
            onClick={() => setUploadDoc(true)}
            theme="plain"
          >
            Attach doc(s)
          </FvButton>

          <FvButton
            icon="eye"
            onClick={() => setPreviewing(true)}
            theme="plain"
          >
            View email
          </FvButton>

          <FvButton theme="default" icon="paper-plane" fwd type="submit">
            <span>Send</span>
            <span className="1550:hidden"> out for quotes</span>
          </FvButton>
        </div>
      </ValidatedForm>

      <SliderPanel
        width="32rem"
        closePanel={() => setUploadDoc(false)}
        isOpen={uploadDoc}
      >
        <SliderContentLayout>
          <SliderGenericHeader
            content="Attach document(s)"
            handleClose={() => setUploadDoc(false)}
          />
          <InfoBox>
            Document(s) will be sent to the carrier prior to them quoting on
            your shipment. Any documents already sent will be shown below.
          </InfoBox>
          <DocumentList
            className="mb-6 overflow-auto"
            canDelete={true}
            {...{ documents, loadId }}
          />
          <UploadDocumentField loadId={loadId} defaultDocType={'other'} />
        </SliderContentLayout>
      </SliderPanel>

      <SpotQuoteEmailPreview
        closePanel={() => setPreviewing(false)}
        isOpen={isPreviewing}
        loadId={loads[activeLoadIndex].loadId}
        shipperMessage={shipperMessage}
        systemMessage={systemMessage}
      />

      <SpotQuoteEmailConfirmation
        closePanel={() => sendRequests.reset()}
        emails={sendRequests.variables?.emails}
        isOpen={sendRequests.isSuccess}
        workflow={loads[activeLoadIndex].workflow}
      />

      <SpotQuoteGroupEditor
        closePanel={() => setEditingGroups(false)}
        isOpen={isEditingGroups}
        initialGroupId={
          selectedGroups.length === 1 ? selectedGroups[0].id : undefined
        }
      />

      <RouteStopDate
        onClose={() => setPickingDate(false)}
        isOpen={isPickingDate}
        sequence={1}
        onChange={data => {
          updateDeliveryDate.mutate({
            date: data.stopDate,
            loadId,
          })
        }}
        showStopDetailConfig={false}
        stops={locations}
      />
    </>
  )
}

type SpotQuotePanelProps = {
  activeLoadIndex?: number
  className?: string
  defaultOpen?: boolean
  label: string
  loads?: FullShipment[]
  onSuccess?: () => void
  selectTruck?: (i: number) => void
}

export const SpotQuotePanel = ({
  activeLoadIndex = 0,
  className,
  defaultOpen = false,
  label,
  loads,
  onSuccess,
  selectTruck,
}: SpotQuotePanelProps) => {
  const [isOpen, setOpen] = useState(defaultOpen)
  const [formData, setFormData] = useState<SpotQuoteFormData[]>(
    loads.map(l => ({
      loadId: l.loadId,
      targetPrice: l.targetPrice,
      bidExpiration: l.bidExpiration,
    })),
  )
  const groupsQuery = useSpotQuoteGroupsWithUngrouped()
  const recentGroups = useRecentGroups(loads[0].workflow)

  const [groups, setGroups] = useGroups({
    spotQuoteGroups: groupsQuery.data,
    recentGroupIds: recentGroups.isLoading ? undefined : recentGroups.ids,
  })

  function localOnSuccess() {
    onSuccess?.()
    setOpen(false)
  }

  return (
    <div
      className={clsx(
        'ltl-spot-quotes-ctn flex w-full flex-col flex-nowrap items-stretch bg-fv-gray-50',
        className,
        {
          active: isOpen,
        },
      )}
      key={loads?.[activeLoadIndex].loadId}
    >
      <a
        className="bg-fv-beer-xlight border-t-fv-beer-medium relative flex justify-center border-t-2 p-4 shadow-[0_-.5rem_.75rem_-.25rem_rgba(0,0,0,0.1)]"
        href="#"
        onClick={e => {
          e.preventDefault()
          setOpen(o => !o)
        }}
      >
        <div className="border-fv-blue absolute -top-5 flex aspect-square w-8 items-center justify-center rounded-full border-2 bg-white">
          <Icon icon={!isOpen ? 'arrow-up' : 'arrow-down'} transform="" />
        </div>
        <Icon icon="paper-plane" />
        <span>{label}</span>
      </a>

      <div
        className={clsx(
          'flex flex-1 flex-nowrap justify-center overflow-hidden',
          {
            'items-stretch': !!loads?.length,
            'items-center': !loads?.length,
          },
        )}
      >
        {loads?.length ? (
          <SpotQuoteForm
            activeLoadIndex={activeLoadIndex}
            close={() => setOpen(false)}
            loads={loads}
            onSuccess={localOnSuccess}
            selectTruck={selectTruck}
            formData={formData}
            setFormData={setFormData}
            setGroups={setGroups}
            groups={groups}
          />
        ) : (
          <Icon icon="sync" size="3x" />
        )}
      </div>
    </div>
  )
}

type Group = {
  checked: boolean
  emails: string[]
  id: string
  label: string
}

function useGroups({
  recentGroupIds,
  spotQuoteGroups,
}: {
  recentGroupIds?: string[]
  spotQuoteGroups?: SpotQuoteGroup[]
}): [Group[], Dispatch<SetStateAction<Group[]>>] {
  const [groups, setGroups] = useState<Group[]>([])
  const { addEmails, removeEmails } = useSpotQuoteListActions()
  useEffect(() => {
    if (!spotQuoteGroups?.length || recentGroupIds === undefined) return
    setGroups(prev =>
      spotQuoteGroups.map(g => {
        const { groupId, groupName, users } = g
        return {
          checked:
            prev?.find(g => g.id === groupId)?.checked ??
            recentGroupIds.includes(groupId),
          emails: users.map(u => u.email),
          id: groupId,
          label: groupName,
        }
      }),
    )
  }, [recentGroupIds, spotQuoteGroups])

  useEffect(() => {
    const allContacts = groups.find(g => g.id === ALL_CONTACTS_ID)
    const otherGroups = groups.filter(g => g.id !== ALL_CONTACTS_ID)
    if (allContacts?.checked) {
      addEmails(allContacts.emails)
    } else if (allContacts) {
      removeEmails(allContacts.emails)
    }
    removeEmails(otherGroups.filter(g => !g.checked).flatMap(g => g.emails))
    addEmails(otherGroups.filter(g => g.checked).flatMap(g => g.emails))
  }, [groups, addEmails, removeEmails])

  return [groups, setGroups]
}

type TargetPriceFormProps = {
  numberOfTrucks: number
  activeLoadIndex: number
  value?: number
  onChange: (val: number) => void
  selectTruck?: (i: number) => void
}

const TargetPriceForm = ({
  numberOfTrucks,
  activeLoadIndex,
  value,
  onChange,
  selectTruck,
}: TargetPriceFormProps) => {
  const showBidExpiration = useShowBidExpiration()
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseFloat(e.target.value))
  }

  return (
    <>
      {numberOfTrucks > 1 && (
        <div className="flex col-span-12 mb-3 gap-x-2 items-center">
          <div className="flex items-center gap-x-1">
            <span className="mr-1">Truck</span>

            {arrayOfLength(numberOfTrucks).map(i => (
              <TruckSelector
                active={i === activeLoadIndex}
                className="callout-link boxed-link px-2 py-1"
                key={i}
                selectTruck={() => selectTruck?.(i)}
                truckNumber={i + 1}
              />
            ))}
          </div>
          <div className="flex-1 border-t h-px border-fv-blue-300 border-dashed" />
        </div>
      )}

      <div
        className={clsx(
          'input-group',
          showBidExpiration ? 'col-span-5' : 'col-span-12',
        )}
      >
        <InputAdornment
          position="start"
          label="Target price"
          className="whitespace-nowrap"
        />
        <InputAdornment position="start" icon="dollar-sign" />
        <TextField
          type="number"
          className="form-control"
          name="target-price"
          onChange={handlePriceChange}
          value={value ?? ''}
          min={1}
        />
      </div>
    </>
  )
}

type BidExpirationFormProps = {
  value?: LoadBidExpiration
  onChange: (exp: LoadBidExpiration) => void
}

export const BidExpirationForm = ({
  value,
  onChange,
}: BidExpirationFormProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const handleSetDate = (expireDateTime: string | Date | undefined) => {
    onChange({
      local: dateToStringWithLocalTimezoneAbbr(expireDateTime),
      utc: !expireDateTime ? null : dayjs(expireDateTime).utc().toDate(),
    })
  }

  return (
    <>
      <div className="input-group col-span-7">
        <InputAdornment
          position="start"
          label="Expires on"
          className="whitespace-nowrap"
        />
        <InputAdornment position="start">
          <FvButton
            icon="calendar-day"
            theme="default"
            onClick={() => setShowDatePicker(true)}
          />
        </InputAdornment>
        <TextField
          className="form-control"
          name="expiration-date"
          placeholder=""
          readOnly
          onFocus={() => setShowDatePicker(true)}
          value={value?.utc ? dayjs(value.utc).format('MMM DD, h:mm A') : ''}
        />
      </div>
      <ExpirationDatePickerPanel
        value={value?.utc?.toString() ?? ''}
        onClose={() => setShowDatePicker(false)}
        open={showDatePicker}
        onSave={dateTime => {
          handleSetDate(dateTime)
          setShowDatePicker(false)
        }}
      />
    </>
  )
}
