import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { showApiError } from '@fv/client-core'
import { type SavedViewScheduledReportDTO } from '@fv/models'

import {
  type UserSettings,
  userSettingsKey,
} from '../../hooks/settings/useUserSettings'
import { shipperFetch } from '../../utils/shipperFetch'

type UpsertScheduledReportsResponse = UserSettings['savedViews']

const upsertScheduledReports = async (body: SavedViewScheduledReportDTO[]) => {
  return await shipperFetch<UpsertScheduledReportsResponse>(
    `/scheduled-reports`,
    {
      method: 'PUT',
      body: body.map(b => ({
        ...b,
        scheduledReport: {
          ...b.scheduledReport,
          time: dayjs(b.scheduledReport.time, 'HH:mm').utc().format('HH:mm'),
        },
      })),
    },
  )
}
export const useUpsertScheduledReports = () => {
  const queryClient = useQueryClient()
  return useMutation(upsertScheduledReports, {
    onSuccess(resp) {
      queryClient.setQueryData<UserSettings>(userSettingsKey, prev => ({
        ...prev,
        savedViews: resp,
      }))
    },
    onError(e) {
      showApiError('Failed to update scheduled reports', e)
    },
  })
}
