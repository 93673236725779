import { Icon, type IconProps } from '@fv/client-components'
import { type Mode } from '@fv/client-types'

import { modeNames } from '../../utils/shipmentFuncs'

const icons: { [key: string]: IconProps['icon'] } = {
  intermodal: 'truck',
  ltl: 'truck',
  parcel: 'box',
  truckload: 'truck-moving',
}

type Props = {
  mode: Mode
  carrierName?: string
  isMultiStop?: boolean
}

export const ShipmentCardWorkflowLabel = ({
  mode,
  carrierName,
  isMultiStop,
}: Props) => {
  const label = composeCardLabel(mode, isMultiStop)
  const icon = icons[mode]
  return (
    <span className="limit-characters">
      <Icon icon={icon} className="text-fv-gray-dark" />
      <span>
        {label}
        {carrierName ? ` / ${carrierName}` : null}
      </span>
    </span>
  )
}

function composeCardLabel(mode: Mode, isMultiStop = false) {
  const modeName = modeNames[mode]
  return isMultiStop ? `Multi-stop ${modeName}` : modeName
}
