import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FvLinkButton, useFvNavigate } from '@fv/client-components'

import InfoBox from '../../components/InfoBox'
import { useQueryParam } from '../../hooks/settings'
import { useVerifyToken } from './hooks'

const style = { margin: '2rem auto', maxWidth: '11rem' }

type Props = {
  loginRedirect: string
}

const Login = ({ loginRedirect }: Props) => {
  const { token } = useParams()
  const navigate = useFvNavigate()
  const redirectTo = useQueryParam('redirectTo')
  const [error, setError] = useState(null)
  const verify = useVerifyToken()

  const establishSession = async (regToken: string) => {
    try {
      const result = await verify.mutateAsync({
        token: regToken,
      })
      if (!result.success) {
        setError(`There's an error with your session.`)
      }

      try {
        navigate(`/${redirectTo?.replace(/^\//, '') ?? 'active'}`, {
          replace: true,
        })
      } catch {
        setError(
          `Unable to access your browser's local storage. Please enable local storage to continue.`,
        )
      }
    } catch (e: any) {
      setError(e.message || `There's an error with your session.`)
      window.location.href = loginRedirect
    }
  }

  useEffect(() => {
    if (!token) {
      window.location.href = loginRedirect
      return
    }

    establishSession(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, loginRedirect, redirectTo, token])

  if (error) {
    return (
      <div style={style}>
        <InfoBox icon="exclamation-triangle">
          <p>{error}</p>
          <FvLinkButton theme="callout" href={loginRedirect}>
            Click here to login.
          </FvLinkButton>
        </InfoBox>
      </div>
    )
  }
  return (
    <div style={style}>
      <InfoBox icon="sync">Authenticating</InfoBox>
    </div>
  )
}

export default Login
