import upperFirst from 'lodash/upperFirst'
import { type Dispatch, type SetStateAction, useMemo } from 'react'

import { DelimitedContent, FvLinkButton, Icon } from '@fv/client-components'
import { totalShipmentWeight } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { HasPermission } from '../../../features/auth'
import { RefNumsEditor } from '../../../features/ref-nums/RefNumsEditor'
import { LoadUserDisplay } from '../../../features/shipment-list/LoadUserDisplay'
import { useCurrency, usePrettyNumber } from '../../../hooks/settings'
import { useRateModifier } from '../../../hooks/useRateModifier'
import { routes } from '../../../routes'
import { mapPaymentTerms } from '../../../utils/shipmentFuncs'
import { type PanelName } from '../LoadDetailPanel'

type ActivePanelHeaderProps = {
  isShowingRates: boolean
  load: FullShipment
  setActivePanel: Dispatch<SetStateAction<PanelName>>
  setShowingRates: (s: boolean) => void
}

const ActivePanelHeader = ({
  isShowingRates,
  load,
  setActivePanel,
  setShowingRates,
}: ActivePanelHeaderProps) => {
  const paymentTerms = mapPaymentTerms(load)
  const prettyNumber = usePrettyNumber()
  const priceParts = useCurrency()
  const rateMod = useRateModifier()

  const { equipment, selectedQuote } = load
  const [dollars, cents] = useMemo(() => {
    if (!selectedQuote) return priceParts(0)
    const { amount } = rateMod(selectedQuote, load.workflow)
    return priceParts(amount)
  }, [load.workflow, priceParts, selectedQuote, rateMod])

  return (
    <>
      {isShowingRates && (
        <nav className="shipment-list-ancillary-details__nav alternate divided-content divided-content--start mb-6">
          <FvLinkButton
            onClick={() => setShowingRates(false)}
            icon="arrow-to-left"
          >
            Back
          </FvLinkButton>
          <h6 className="mb-0">
            <Icon icon="truck-moving" className="text-fv-gray-dark" />
            <span>Select a new carrier</span>
          </h6>
        </nav>
      )}

      <div className="paragraph ancillary-details-snapshot space-y-1">
        <RefNumsEditor load={load} />
        <p className="leading-6">
          <DelimitedContent delimiter={<> / </>}>
            {equipment.isHazardous && (
              <span>
                Hazardous {' / '}
                {upperFirst(equipment.description)}
              </span>
            )}
            {!!equipment.description && (
              <span>{upperFirst(equipment.description)}</span>
            )}
            <span>
              {prettyNumber(totalShipmentWeight(load))} {equipment.weightUOM}.
            </span>
          </DelimitedContent>
          <br />
          <span>
            Booked by <LoadUserDisplay {...load.bookedBy} />
          </span>
          <br />
          <span className="flex gap-x-1">
            {paymentTerms && `${paymentTerms} / `}
            <HasPermission
              name="book"
              fallback={
                <span>
                  ${dollars}.{cents}
                </span>
              }
            >
              <FvLinkButton
                theme="underlined"
                href={routes.rates(load, 'view')}
                onClick={e => {
                  if (load.workflow === 'truckload') {
                    e.preventDefault()
                    setActivePanel('quote')
                  }
                }}
                rel="noreferrer"
              >
                <span>
                  ${dollars}.{cents}
                </span>
              </FvLinkButton>
            </HasPermission>
          </span>
        </p>
      </div>
    </>
  )
}

export default ActivePanelHeader
