import clsx from 'clsx'
import { type ReactNode } from 'react'

import { Icon, Tooltip } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

import { List, ListItem } from '../../../components/List'
import { useCurrency } from '../../../hooks/settings'
import { type ReconciledChargeItem } from '../types'

type Props = {
  titleContents: ReactNode
  charges: ReconciledChargeItem[]
  total: number
  manual?: boolean
}
export const AuditChargeList = ({
  titleContents,
  charges,
  manual,
  total,
}: Props) => {
  const priceParts = useCurrency()
  const [dollars, cents] = priceParts(total)
  return (
    <div className="b900:basis-full b900:max-w-none b900:mr-0 b900:mb-4 flex flex-col bg-[#fffef8] p-4 mr-4 border-fv-beer-dark border basis-[20rem] flex-shrink-0">
      <div className="bg-[#f4ffed] -mx-4 px-4 py-2 border-[#ede9c6] border-b -mt-4 mb-4">
        {titleContents}
      </div>
      <List fcp0 lcp0 className="whitespace-nowrap flex-1 flex flex-col">
        {charges.map((charge, ix) => (
          <>
            <AuditChargeItem
              charge={charge}
              key={ix}
              className="border-fv-beer-dark"
            />
          </>
        ))}

        <ListItem
          className={clsx('mt-auto', charges.length === 0 ? 'pt-0' : 'pt-4')}
        >
          {manual && (
            <Tooltip label="Manually entered rate">
              <Icon icon="user" transform="up-1" className="cursor-help" />
            </Tooltip>
          )}
          <span>Total quote</span>
          <span className="ml-auto text-right pl-4 font-oxygen">
            ={' '}
            <span className="bg-fv-beer-light p-1 -mx-[calc(.25rem-1px)] border-fv-beer-dark border rounded-sm">
              ${dollars}.{cents}
            </span>
          </span>
        </ListItem>
      </List>
    </div>
  )
}

type ChargeItemProps = PropsWithClassName<{
  charge: ReconciledChargeItem
}>
const AuditChargeItem = ({ charge, className }: ChargeItemProps) => {
  const priceParts = useCurrency()
  const isNeg = charge.amount < 0
  const [dollars, cents] = priceParts(charge.amount)

  return (
    <ListItem className={className}>
      <span className="truncate">{charge.label}</span>
      <span
        className={clsx('ml-auto text-right pl-4 font-oxygen', {
          'text-fv-orange font-oxygen': isNeg,
        })}
      >
        {isNeg && '-'}${dollars}.{cents}
      </span>
    </ListItem>
  )
}
