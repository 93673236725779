import { lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { AccountCarriersPage } from '../features/account-carriers/AccountCarriersPage'
import { AccountUsersPage } from '../features/account-users/AccountUsersPage'
import { AnalyticsPage } from '../features/analytics/AnalyticsPage'
import { AuditPage } from '../features/audit/AuditPage'
import { useAccountFeatures, usePermissions } from '../features/auth'
import { useIsCarrierAccount } from '../features/auth/hooks'
import { BookingPage } from '../features/book/BookingPage'
import { CustomerRoutedBookingPage } from '../features/book/CustomerRoutedBookingPage'
import { NotificationsPage } from '../features/notifications/NotificationsPage'
import { useOnboardingContext } from '../features/onboarding/OnboardingProvider'
import { ProvisionerAccountPage } from '../features/provisioner/ProvisionerAccountPage'
import { ProvisionerPage } from '../features/provisioner/ProvisionerPage'
import { DefaultQuoteRedirect } from '../features/quote/DefaultQuoteRedirect'
import { QuotePage } from '../features/quote/QuotePage'
import { RatesPage } from '../features/quote/RatesPage'
import { QuoteRequestForm } from '../features/quote-request/QuoteRequestForm'
import AccountSettingsPage from '../features/settings/account-settings/AccountSettingsPage'
import { AddressBookPage } from '../features/settings/address-book/AddressBookPage'
import { BillToPage } from '../features/settings/bill-to/BillToPage'
import { ChangePassword } from '../features/settings/change-password/ChangePassword'
import { CompanyBranding } from '../features/settings/company-branding/CompanyBranding'
import { CompanyLocationsPage } from '../features/settings/company-locations/CompanyLocationsPage'
import AccountFuelTable from '../features/settings/contracted-rates/AccountFuelTable'
import { ContractedRatesList } from '../features/settings/contracted-rates/ContractedRatesList'
import { ContractedRatesPage } from '../features/settings/contracted-rates/ContractedRatesPage'
import { NewContractedRate } from '../features/settings/contracted-rates/NewContractedRate'
import { IntegrationPage } from '../features/settings/integration/IntegrationPage'
import { ProductCatalogPage } from '../features/settings/product-catalog/ProductCatalogPage'
import { SettingsPage } from '../features/settings/SettingsPage'
import { SpotContactsPage } from '../features/settings/spot-contacts/SpotContactsPage'
import { SubscriptionPage } from '../features/settings/subscription/SubscriptionPage'
import { TagManagement } from '../features/settings/tag-management/TagManagement'
import UserPreferencesPage from '../features/settings/user-preferences/UserPreferencesPage'
import { ConfirmedShipmentList } from '../features/shipment-list/ConfirmedShipmentList'
import { InProcessPage } from '../features/shipment-list/InProcessPage'
import QuoteRequestDetail from './QuoteRequest/QuoteRequestDetail'
import InfoBox from './InfoBox'
import SearchResults from './SearchResults'
import ShipmentDetailsPage from './ShipmentDetails'
import TopNav from './TopNav'

const OnboardingApp = lazy(() => import('../features/onboarding/OnboardingApp'))
export const ShipperRoutes = () => {
  const { canAudit, canBook, canManage } = usePermissions()
  const { truckLoad } = useAccountFeatures()
  const isCarrier = useIsCarrierAccount()
  const {
    onboarding: [isOnboarding],
  } = useOnboardingContext()

  return (
    <Routes>
      <Route
        path="welcome/*"
        element={
          <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
            <OnboardingApp />
          </Suspense>
        }
      />
      <Route path="active" element={<ConfirmedShipmentList />} />

      <Route path="analytics" element={<AnalyticsPage />}>
        <Route path=":view" element={null}>
          <Route path=":subView" element={null} />
        </Route>
      </Route>

      <Route
        path="notifications"
        element={
          <div className="grid grid-cols-[20rem_1fr] grid-rows-[auto_1fr] h-screen">
            <TopNav />
            <NotificationsPage />
          </div>
        }
      />

      <Route path="details/:loadId" element={<ShipmentDetailsPage />} />

      {canAudit && <Route path="audit" element={<AuditPage />} />}

      <Route path="pending" element={<InProcessPage />}>
        <Route path=":quoteRequestId" element={null} />
      </Route>

      <Route
        path="quote/:quoteRequestId"
        element={
          <div className="grid bg-[#f2f2f2] grid-rows-[auto_1fr] h-screen grid-pending-detail-tl grid-cols-[minmax(20rem,0.3fr)_1fr_minmax(16rem,0.4fr)]">
            <TopNav />
            <QuoteRequestDetail />
          </div>
        }
      />

      <Route
        path="quote-request"
        element={
          <div className="grid grid-cols-[15rem_22rem_1fr] grid-rows-[auto_1fr_auto] bg-[#f2f2f2] h-screen b1500:grid-cols-[15rem_20rem_1fr] b1350:grid-rows-[auto_auto_1fr_auto] b1350:grid-cols-[21rem_1fr]">
            <TopNav />
            <QuoteRequestForm />
          </div>
        }
      >
        <Route path=":quoteRequestId" element={null}>
          <Route path=":mode" element={null} />
        </Route>
      </Route>

      <Route path=":workflow/quote" element={<QuotePage />}>
        <Route path=":loadId" element={null}>
          <Route path=":mode" element={null} />
        </Route>
      </Route>
      <Route path="dashboard" element={<DefaultQuoteRedirect />} />

      <Route path="rates/:loadId" element={<RatesPage />}>
        <Route path=":mode" element={null} />
      </Route>

      <Route
        path="book"
        element={
          <div className="grid grid-cols-[20rem_1fr] grid-rows-[auto_1fr_auto] h-screen bg-fv-gray-100">
            <TopNav />
            <Outlet />
          </div>
        }
      >
        <Route
          path="customer-routed"
          element={
            canBook ? (
              <CustomerRoutedBookingPage />
            ) : (
              <Navigate to="ltl/quote" replace />
            )
          }
        />
        <Route path=":loadId/:quoteId" element={<BookingPage />} />
      </Route>

      <Route path="search" element={<SearchResults />} />

      <Route path="settings" element={<SettingsPage />}>
        {canManage && (
          <>
            <Route path="company-users" element={<AccountUsersPage />} />
            <Route path="account-settings" element={<AccountSettingsPage />} />
            <Route path="product-catalog" element={<ProductCatalogPage />} />
            <Route path="branding" element={<CompanyBranding />} />
            <Route path="spot-quote" element={<SpotContactsPage />} />
            {truckLoad && (
              <Route path="contracted-rates" element={<ContractedRatesPage />}>
                <Route path="" element={<ContractedRatesList />} />
                <Route path="add" element={<NewContractedRate />} />
                <Route path="fuel" element={<AccountFuelTable />} />
                <Route path="edit/:rateId" element={<ContractedRatesList />} />
              </Route>
            )}
          </>
        )}
        <Route path="carriers" element={<AccountCarriersPage />}>
          <Route path=":carrierId" element={null}>
            <Route path=":locationId" element={null} />
          </Route>
        </Route>
        <Route path="company-locations" element={<CompanyLocationsPage />} />

        <Route path="user-preferences" element={<UserPreferencesPage />} />
        <Route path="tag-management" element={<TagManagement />} />
        <Route path="integration" element={<IntegrationPage />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="bill-to" element={<BillToPage />} />
        <Route path="address-book" element={<AddressBookPage />} />
        <Route path="subscription" element={<SubscriptionPage />} />
        <Route path="*" element={<Navigate to="user-preferences" replace />} />
      </Route>

      {isCarrier && (
        <>
          <Route path="provisioning">
            <Route
              path=""
              element={<Navigate to="/provisioning/requested" replace />}
            />
            <Route path=":status" element={<ProvisionerPage />} />
            <Route
              path="account/:accountId"
              element={<ProvisionerAccountPage />}
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/provisioning/requested" replace />}
          />
        </>
      )}

      {isOnboarding ? (
        <Route path="*" element={<Navigate to="welcome" replace />} />
      ) : (
        <Route path="*" element={<Navigate to="active" replace />} />
      )}
    </Routes>
  )
}
