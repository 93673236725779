import clsx from 'clsx'
import dayjs from 'dayjs'
import {
  type ChangeEvent,
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
} from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'
import { composeStopDetails } from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type StopDateType,
  type StopType,
} from '@fv/client-types'
import { type LocationAccessorialType } from '@fv/models'

import { AddressBookButton } from '../../features/addresses/AddressBookButton'
import { addressMapper } from '../../features/addresses/addressFuncs'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import { ItemSummary } from '../../features/commodities/ItemSummary'
import { useQuoteFormActions } from '../../features/quote-request/useQuoteFormState'
import { type FormLocation } from '../../types/FormLocation'
import createStopDetailOptions from '../../utils/createStopDetailOptions'
import { AddressInput } from '../inputs/AddressInput'
import LocationAccessorials from '../inputs/LocationAccessorials'
import { ShipTypeSelector } from '../inputs/ShipTypeSelector'
import { List } from '../List'

type RouteStopConfigProps = {
  isLastStop: boolean
  items: FormCommodity[]
  setDatepickerSequence: Dispatch<SetStateAction<number | null>>
  stop: FormLocation
  stops: FormLocation[]
}

export const RouteStopConfig = ({
  isLastStop,
  items,
  setDatepickerSequence,
  stop,
  stops,
}: RouteStopConfigProps) => {
  const { accessorials, id, sequence, stopDate, stopDateType, stopType } = stop
  const { removeStop, setStopData } = useQuoteFormActions()
  const isFirstStop = sequence === 0
  const isMultiStop = stops.length > 2

  function updateStop(data: Partial<FormLocation>) {
    setStopData(sequence, data)
  }

  function onAccessorialChange(e: ChangeEvent<HTMLInputElement>) {
    const key = e.target.value as LocationAccessorialType

    updateStop({
      accessorials: e.target.checked
        ? stop.accessorials.concat({ key })
        : stop.accessorials.filter(a => a.key !== key),
    })
  }

  function openDatePicker(e?: MouseEvent<HTMLAnchorElement | HTMLDivElement>) {
    e?.preventDefault()
    setDatepickerSequence(stop.sequence)
  }

  function setAddress(location: ClientAddressLookupResult | null) {
    updateStop(addressMapper.dtoToFormStop(location, 'truckload'))
  }

  function setStopDetails(e: ChangeEvent<HTMLSelectElement>) {
    const details = e.target.value
    let stopDateType: StopDateType = 'on'
    let stopType: StopType = 'pickup'

    if (details.includes('by')) stopDateType = 'by'
    if (details.includes('Deliver')) stopType = 'delivery'
    if (details.includes('and')) stopType = 'both'

    updateStop({
      stopDateType,
      stopType,
    })
  }

  const dayjsDate = stopDate ? dayjs(stopDate) : null
  const month = dayjsDate?.format('MMM')
  const day = dayjsDate?.format('DD')
  const weekday = dayjsDate?.format('ddd')
  const zIndex = stops.length - stop.sequence
  const stopDetails = composeStopDetails({ stopDate, stopDateType, stopType })
  const stopDetailOptions = createStopDetailOptions({
    isFirstStop,
    isLastStop,
    stopDate,
  })

  return (
    <div className="quote-route relative" data-cy={`location-${sequence}`}>
      <div className="quote-route__pickup-stop-delivery">
        {(isFirstStop || isLastStop) &&
          (stopDate ? (
            <a
              className="date-block-calendar"
              href="#"
              onClick={openDatePicker}
            >
              <div className="date-block-calendar__month">{month}</div>
              <div className="callout-link text-xl">{day}</div>
              <div className="date-block-calendar__weekday">{weekday}</div>
            </a>
          ) : (
            <a
              className="date-block-calendar date-block-calendar--open stacked-icon-link"
              href="#"
              onClick={openDatePicker}
            >
              <Icon icon="calendar-day" transform="up-1" />
              <span>Date</span>
            </a>
          ))}

        {!isFirstStop &&
          !isLastStop &&
          (stopDate ? (
            <a
              className="date-block-calendar"
              href="#"
              onClick={openDatePicker}
            >
              <div className="date-block-calendar__month">{month}</div>
              <div className="callout-link text-xl">{day}</div>
              <div className="date-block-calendar__weekday">{weekday}</div>
            </a>
          ) : (
            <a className="no-date-stop" href="#" onClick={openDatePicker}>
              <div>Stop</div>
              <div className="numbered-circle -mt-[0.2rem]">{sequence}</div>
              <span className="callout-link">
                <Icon icon="calendar-day" />
              </span>
            </a>
          ))}
      </div>
      <div className="mr-8 grid flex-1 grid-cols-12 gap-2">
        <div className="b1650:col-span-12 col-span-8">
          <div className="input-group required">
            <div className="input-group__prepend">
              <AddressBookButton context="truckload" setAddress={setAddress} />
            </div>
            <div className="input-group__prepend">
              <div className="input-group__addon">
                <Icon
                  icon="map-marker"
                  className={clsx({
                    'text-fv-orange': isFirstStop,
                    'text-fv-green': isLastStop,
                    'text-fv-beer': !isFirstStop && !isLastStop,
                  })}
                />
              </div>
            </div>
            <AddressInput
              context="truckload"
              includeAddressBook
              location={stop}
              name={`stop-${id}-address`}
              onLocationChange={setAddress}
              placeholder="i.e. Kansas City, MO or 66040"
              required
              style={{ zIndex }}
            />
          </div>
        </div>
        <div className="b1600:col-span-5 b1400:col-span-6 b1100:col-span-7 col-span-4 min-w-[17rem]">
          <ShipTypeSelector
            className="-mb-4" // cancels out form-group margin
            value={stop}
            name={`stop-${id}-shiptype`}
            onChange={({ shipType, accessorials }) =>
              updateStop({ shipType, accessorials })
            }
            showLabels={false}
          />
        </div>

        <select
          id={`stop-${id}-details`}
          className="form-control form-control--select col-span-3 min-w-[11rem]"
          onChange={setStopDetails}
          value={stopDetails}
        >
          {stopDetailOptions.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>

        <LocationAccessorials
          accessorials={accessorials}
          className="flex gap-x-5 mt-2 b1450:ml-0 col-span-9 ml-4 b1650:col-span-full b1650:ml-0 b1150:flex-col b1150:gap-y-3"
          inputNamePrefix={`stop-${id}-`}
          onChange={onAccessorialChange}
          openDatePicker={openDatePicker}
        />
      </div>
      <div className="absolute right-0 top-3">
        {!isFirstStop && !isLastStop && (
          <FvLinkButton
            fw
            icon="trash"
            iconClass="text-fv-orange"
            onClick={() => removeStop(sequence)}
          />
        )}
      </div>

      {(isMultiStop || !isFirstStop) && !!items.length && (
        <List
          lcp0
          className="col-12 mt-6 pt-2 border-t border-dashed border-fv-gray"
        >
          {items.map(i => (
            <ItemSummary
              item={i}
              key={i.id}
              label={i.pickSequence === sequence ? 'Picking up' : 'Delivering'}
            />
          ))}
        </List>
      )}
    </div>
  )
}
