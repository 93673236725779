import { useFvNavigate } from '@fv/client-components'

import { routes } from '../../routes'
import { buildQuoteRequestDTO, countLoads } from './quoteRequestUtils'
import { type QuoteFormParams } from './types'
import { useCreateQuoteRequest } from './useCreateQuoteRequest'
import { useQuoteFormActions, useQuoteFormState } from './useQuoteFormState'
import { useUpdateQuoteRequest } from './useUpdateQuoteRequest'

export function useQuoteFormSubmission(
  quoteRequestId?: string,
  mode?: QuoteFormParams['mode'],
) {
  const { validateQuoteRequest } = useQuoteFormActions()
  const navigate = useFvNavigate()
  const createRequest = useCreateQuoteRequest()
  const updateRequest = useUpdateQuoteRequest(quoteRequestId)
  const isBusy =
    createRequest.isLoading ||
    createRequest.isSuccess ||
    updateRequest.isLoading ||
    updateRequest.isSuccess

  function editQuoteRequest() {
    if (isBusy || !quoteRequestId || mode !== 'edit') return
    if (!validateQuoteRequest()) return

    const formState = useQuoteFormState.getState()
    const loadCount = countLoads(formState.loads)

    if (loadCount > 1) {
      const shouldEdit = window.confirm(
        'You are editing a quote request that involves multiple trucks. You can change accessorials per truck, or add and subtract trucks to this group of trucks, but any location and/or date change is a change that is applied to all trucks.',
      )

      if (!shouldEdit) return
    }

    const dto = buildQuoteRequestDTO(formState)
    updateRequest.mutate(dto)
  }

  function fetchQuotes() {
    if (isBusy || !validateQuoteRequest()) return
    const formState = useQuoteFormState.getState()
    const dto = buildQuoteRequestDTO(formState)

    createRequest.mutate(dto, {
      onSuccess: loads => {
        navigate(routes.rates(loads[0]))
      },
    })
  }

  return {
    editQuoteRequest,
    fetchQuotes,
    isBusy,
  }
}
