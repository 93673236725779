import clsx from 'clsx'
import { type ChangeEvent, useMemo } from 'react'
import { useToggle } from 'usehooks-ts'

import { FvButton, Icon, Tooltip } from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import CheckboxField from '../../components/inputs/CheckboxField'
import { ListItem } from '../../components/List'
import MessageField from '../../components/messaging/MessageField'
import MessageList from '../../components/messaging/MessageList'
import { Price } from '../../components/shared/Price'
import { useUnreadMessageIds } from '../../hooks/messages'
import { useTypeNames } from '../../hooks/settings'
import { type QuoteRequestResponse } from '../../hooks/shipments/useQuoteRequestResponses'
import { useRetractTender } from '../../hooks/shipments/useRetractTender'
import { usePermissions } from '../auth'
import { CarrierSelectForm } from './CarrierSelectForm'
import { ChargeList, useCanShowCharges } from './ChargeList'
import { RetractTenderForm } from './RetractTenderForm'

type AltEquipmentTypeIconProps = {
  alternateTypes?: EquipmentType[]
  quotedEquipmentType?: EquipmentType
}

const AltEquipmentTypeIcon = ({
  alternateTypes = [],
  quotedEquipmentType,
}: AltEquipmentTypeIconProps) => {
  const { equipmentName } = useTypeNames()
  const altTypeIndex = quotedEquipmentType
    ? (alternateTypes?.indexOf(quotedEquipmentType) ?? -1)
    : -1

  if (!quotedEquipmentType || altTypeIndex < 0) return null

  return (
    <Tooltip label={equipmentName(quotedEquipmentType)}>
      <span className="legend-icon legend-icon--tooltip">
        A{altTypeIndex + 1}
      </span>
    </Tooltip>
  )
}

type MiniQuoteListItemProps = {
  awardedQuoteId?: string
  isActive: boolean
  isChecked: boolean
  load: ListShipment
  quote: QuoteRequestResponse
  readOnly?: boolean
  setActiveQuoteId: (id: string | undefined) => void
  setSelectedQuoteId: (id: string | undefined) => void
  canViewMessages?: boolean
}

export const MiniQuoteListItem = ({
  awardedQuoteId,
  isActive,
  isChecked,
  load,
  quote,
  readOnly = false,
  canViewMessages = true,
  setActiveQuoteId,
  setSelectedQuoteId,
}: MiniQuoteListItemProps) => {
  const { canBook } = usePermissions()
  const canShowCharges = useCanShowCharges(quote)
  const { equipment, loadId, status: loadStatus } = load

  const { amount, carrierId, currency, id: quoteId, method, name } = quote

  const { canRetract } = useRetractTender()
  const unreadMessageIds = useUnreadMessageIds({ carrierId, loadId })
  const hasUnreadMessages = unreadMessageIds.length > 0
  const isLoadCanceled = loadStatus === 'canceled'
  const isLoadAwarded = loadStatus === 'awarded'
  const isQuoteSelected = quoteId === awardedQuoteId
  const showRetender = isLoadAwarded && !isQuoteSelected
  const showRetract = useMemo(
    () => isChecked && canRetract(load) && isQuoteSelected && !readOnly,
    [isChecked, canRetract, load, isQuoteSelected, readOnly],
  )
  const [showCharges, toggleCharges, setShowCharges] = useToggle()

  const showAward = useMemo(() => {
    if (
      !isChecked ||
      isLoadCanceled ||
      loadStatus === 'delivered' ||
      loadStatus === 'picked-up' ||
      showRetract
    )
      return false

    return loadStatus === 'pending' || !isQuoteSelected
  }, [isLoadCanceled, isChecked, loadStatus, showRetract, isQuoteSelected])

  const label = isQuoteSelected ? (
    <>
      <Icon icon="trophy" className="text-fv-orange" transform="up-1" />
      <span>{name}</span>
    </>
  ) : (
    name
  )

  return (
    <ListItem className="flex-wrap">
      <div className="flex items-center w-full gap-x-2">
        {(readOnly || !canBook) && (
          // add padding and negative margin so icon does not get cut off vertically by truncate
          <div className="flex-1 truncate py-2 -my-2">{label}</div>
          // end
        )}

        {canBook && !readOnly && (
          <CheckboxField
            className="checkbox flex-1"
            checked={isChecked}
            disabled={!amount}
            label={label}
            labelClassName="truncate"
            name={quoteId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target
              // Can't deselect awarded quote
              if (!checked && isQuoteSelected) return
              setSelectedQuoteId(checked ? quoteId : awardedQuoteId)
            }}
          />
        )}

        <AltEquipmentTypeIcon
          alternateTypes={equipment.alternateTypes}
          quotedEquipmentType={quote.equipmentType}
        />

        <div className="flex flex-nowrap items-center">
          <div
            className={clsx(
              'divide-fv-gray flex items-center [&>*]:px-2 mr-2',
              canShowCharges && canViewMessages && 'divide-x',
            )}
          >
            <Tooltip label="See charges">
              <FvButton
                fw
                icon="money-check-alt"
                className={clsx('', {
                  invisible: !canShowCharges,
                })}
                onClick={() => {
                  toggleCharges()
                  setActiveQuoteId(null)
                }}
              />
            </Tooltip>

            {canViewMessages && (
              <div className="relative">
                {hasUnreadMessages && (
                  <div className="shipment-list-pricing__unread-indicator animate-pulse" />
                )}

                <FvButton
                  fw
                  icon={hasUnreadMessages ? 'comment-alt-dots' : 'comment-alt'}
                  transform="down-1.5"
                  onClick={() => {
                    setActiveQuoteId(isActive ? undefined : quoteId)
                    setShowCharges(false)
                  }}
                />
              </div>
            )}
          </div>
          {Boolean(amount) && (
            <Price
              className="shipment-list-pricing__price cursor-pointer"
              amount={amount}
              currency={currency}
            />
          )}
        </div>
      </div>

      {showRetract && <RetractTenderForm loadId={loadId} />}

      {showAward && (
        <CarrierSelectForm
          onCancel={() => {
            setSelectedQuoteId(awardedQuoteId)
          }}
          retender={showRetender}
          load={load}
          quote={quote}
        />
      )}

      {isActive && (
        <div className="my-4 w-full">
          <MessageField carrierId={carrierId} loadId={loadId} />

          <MessageList
            carrierId={carrierId}
            isApiMessages={method === 'api'}
            loadId={loadId}
          />
        </div>
      )}
      {showCharges && (
        <ChargeList
          className="border-t border-fv-gray border-dotted mt-4"
          quote={quote}
        />
      )}
    </ListItem>
  )
}
