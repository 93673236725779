import { List } from '../../components/List'
import { StopList } from '../../components/shared/StopList'
import { getCarrierDetails } from '../../utils/shipmentFuncs'
import ActiveShipmentCardHeader from '../shipment-list/ActiveShipmentCardHeader'
import { ShipmentDetailRow } from '../shipment-list/ShipmentDetailRow'
import { type SharedShipment } from './types'

type SharedShipmentCardProps = { shipment: SharedShipment }

const SharedShipmentCard = ({ shipment }: SharedShipmentCardProps) => {
  const { assetCarrierName, providerName } = shipment
  const carrierDetails = getCarrierDetails({
    selectedQuote: { assetCarrierName, providerName },
  })

  return (
    <div className="shipment-card shipment-card--public-share">
      <div className="flex">
        <ActiveShipmentCardHeader
          carrierName={carrierDetails}
          shipment={shipment}
          hideMessageIndicator
        />
      </div>

      <div className="shipment-card__content shipment-card__content--public-share">
        <List>
          <ShipmentDetailRow
            isExpanded
            shipment={shipment}
            showMixedPallets={true}
          />

          <StopList
            isExpanded
            locations={shipment.locations}
            tracking={shipment.tracking}
            workflow={shipment.workflow}
            quote={shipment.selectedQuote}
          />
        </List>
      </div>
    </div>
  )
}

export default SharedShipmentCard
