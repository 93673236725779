import { FvLinkButton, Icon } from '@fv/client-components'
import { shipperUri } from '@fv/client-core'

type Props = {
  error?: string
}

export const DefaultError = (props: Props) => {
  return (
    <div className="flex items-center flex-col pt-8">
      <Icon icon="exclamation-triangle" size="2x" />
      <br />
      <h1>Sorry, something went wrong.</h1>

      {props.error && <span>Error: {props.error}</span>}

      <br />
      <FvLinkButton href={shipperUri} icon="truck-moving" theme="plain">
        <span>Return to home</span>
      </FvLinkButton>
    </div>
  )
}
