import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { Icon, type IconName } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

type Props = PropsWithChildren<
  PropsWithClassName<{
    title: string
    titleIcon?: IconName
    transform?: string
    noBorder?: boolean
  }>
>
export const SliderPanelLayout = ({
  title,
  titleIcon,
  children,
  className,
  noBorder,
  transform,
}: Props) => {
  return (
    <div className={clsx(className, 'flex h-full flex-col')}>
      <h6
        className={clsx(
          'border-fv-gray m-0  flex gap-x-2 pb-2',
          noBorder ? '!border-none mb-2' : 'border-b mb-4',
        )}
      >
        {titleIcon && (
          <Icon
            icon={titleIcon}
            transform={transform}
            className="text-fv-gray-dark fa-fw"
          />
        )}
        {title}
      </h6>

      {children}
    </div>
  )
}

export const SliderPanelFooter = ({ children }: PropsWithChildren) => {
  return (
    <div className="flyout-footer-actions flex items-center">{children}</div>
  )
}
