import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import { FvButton, Icon } from '@fv/client-components'
import { formatPhone } from '@fv/models/core'

import { ValidatedForm } from '../../components/inputs'
import { List, ListItem } from '../../components/List'
import { PillHeader } from '../../components/PillHeader'
import { EmergencyContactForm } from '../emergencyContact/EmergencyContactForm'
import { useBookingForm } from './hooks'
import { emergencyContactKey } from './types'

export const EmergencyContact = () => {
  const { setFormRef, ...form } = useBookingForm()
  const formRef = useRef(null)
  const isEditing = form.activeSection === emergencyContactKey
  const section = form.formSections.find(s => s.key === emergencyContactKey)
  const hasSection = !!section

  useEffect(() => {
    if (!hasSection) return
    setFormRef(emergencyContactKey, formRef)
  }, [hasSection, setFormRef])

  if (!hasSection) return null

  function editEmergencyContact() {
    form.activateSection(emergencyContactKey)
  }

  const { contractNumber, name, phone, phoneExt } = form.emergencyContact

  return (
    <section className="booking-step-ctn">
      <div
        className={clsx('booking-step', {
          editing: isEditing,
          valid: section?.isValid,
        })}
      >
        <PillHeader
          st
          className={clsx(
            'beer-container -top-3.5 z-10 mb-3 flex min-h-[3.5rem] items-center',
            !isEditing &&
              !section?.isValid &&
              '-ml-[calc(.5rem)] w-[calc(100%-3rem)]',
            isEditing && 'absolute left-[calc(1.5rem)] w-[calc(100%-7rem)]',
            section?.isValid &&
              'absolute -left-[calc(.5rem+1px)] w-[calc(100%-3rem+1px)]',
          )}
        >
          <FvButton
            theme="round"
            onClick={() => editEmergencyContact()}
            icon="pen-alt"
          />
          <div>
            <Icon icon="user-circle" className="fa-fw text-fv-gray-dark" />
            <span>Emergency contact</span>
          </div>
        </PillHeader>

        {isEditing && (
          <ValidatedForm onValidSubmit={form.goToNextSection} ref={formRef}>
            <div className="form-row">
              <EmergencyContactForm
                nameLabel="Name"
                setValues={values => {
                  form.setEmergencyContact(prev => ({
                    ...prev,
                    ...values,
                  }))
                }}
                values={form.emergencyContact}
              />
            </div>

            <FvButton
              theme="secondary"
              icon="arrow-down-to-line"
              fwd
              type="submit"
            >
              Looks good, proceed
            </FvButton>
          </ValidatedForm>
        )}

        {section?.isValid && (
          <List fcp0 lcp0>
            <ListItem className="leading-6">
              {name} / {formatPhone(phone)}
              {phoneExt && ` ext. ${phoneExt}`}
              {contractNumber && ` / ${contractNumber}`}
            </ListItem>
          </List>
        )}
      </div>
    </section>
  )
}
