import { useFormModel } from '@fv/client-core'

import {
  FreightClassField,
  IsHazardousField,
  NmfcField,
  ProductCatalogField,
  QuantityField,
  SecondaryPackageTypeField,
  WeightField,
} from './Fields'
import { HazmatForm } from './HazmatForm'
import { useItemsState } from './loadItemsContext'
import {
  type ItemChangeFn,
  LoadHandlingUnitSchema,
  type LoadItemFormModel,
} from './types'

export type ContainsFormProps = {
  value: LoadItemFormModel
  onChange: ItemChangeFn
  isInPanel?: boolean
}
export const ContainsForm = ({
  value,
  isInPanel,
  onChange,
}: ContainsFormProps) => {
  const { step } = useItemsState()
  const isBooking = step === 'booking'
  const { register } = useFormModel({
    key: value._id,
    value,
    onChange,
    schema: LoadHandlingUnitSchema,
  })
  return (
    <div className="form-row flex flex-wrap pt-2">
      <ProductCatalogField
        label="Commodity description"
        className="basis-2/3"
        onChange={onChange}
        init={{
          useSlider: !isInPanel,
          filter: {
            mode: 'ltl', // Parcel can't have contains so this is a safe assumption
            onlyContainable: true,
          },
        }}
        value={value}
        doublesAsDescription
      />
      <NmfcField className="basis-1/3" register={register} />
      <FreightClassField
        className="basis-2/12"
        label="Class"
        register={register}
      />
      <WeightField className="basis-3/12" register={register} />
      <SecondaryPackageTypeField
        className="basis-3/12"
        label="Packaging Type"
        register={register}
      />
      <QuantityField register={register} className="basis-1/6" />
      <IsHazardousField
        register={register}
        type="checkbox"
        className="basis-1/6"
      />
      {value.isHazardous && isBooking && (
        <div className="border-fv-gray grid-row-2 grid grid-cols-3 border p-2">
          <HazmatForm
            onChange={hazardous => onChange({ hazardous })}
            value={value.hazardous}
            itemId={value._id}
          />
        </div>
      )}
    </div>
  )
}
