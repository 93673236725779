import { type ChangeEvent, useEffect, useRef } from 'react'
import toast from 'react-hot-toast'

import { AmountField, Icon, TextAreaField } from '@fv/client-components'
import { type EquipmentType } from '@fv/models'

import { MAX_ALT_EQUIP } from '../../constants'
import { type QuoteFormLoad } from '../../features/quote-request/types'
import { useQuoteFormActions } from '../../features/quote-request/useQuoteFormState'
import { type AccessorialTypeEnum } from '../../hooks/settings/useAccessorialOptions'
import { ValidatedForm } from '../inputs'
import MultiSelectField from '../inputs/MultiSelectField'
import YesNoInput from '../inputs/YesNoInput'
import EquipmentAccessorialConfig from '../shared/EquipmentAccessorialConfig'

type AltEquipmentOption = {
  label: string
  value: EquipmentType
}

type EquipmentConfigProps = {
  accessorialOptions: AccessorialTypeEnum[]
  altEquipmentOptions: AltEquipmentOption[]
  load: QuoteFormLoad
}

export const EquipmentConfig = ({
  accessorialOptions,
  altEquipmentOptions,
  load,
}: EquipmentConfigProps) => {
  const { loadId, isActiveInUI, revalidationDelay, type } = load
  const { setLoadData, validateLoad } = useQuoteFormActions()
  const formRef = useRef<HTMLFormElement>(null)

  // Set `formRef` on mount
  useEffect(() => {
    setLoadData(type, loadId, { formRef: formRef.current })
  }, [loadId, setLoadData, type])

  // Watch for active loads that need to be validated
  useEffect(() => {
    if (!isActiveInUI || revalidationDelay === null) return

    // Need validation to run in a specific order
    setTimeout(() => {
      validateLoad(type, loadId)
    }, revalidationDelay)
  }, [isActiveInUI, loadId, revalidationDelay, type, validateLoad])

  function updateLoad(data: Partial<QuoteFormLoad>) {
    setLoadData(type, loadId, data)
  }

  return (
    <ValidatedForm
      className="equipment-form"
      ref={formRef}
      // Hiding inactive forms so `ref` always available in Provider
      style={{ display: isActiveInUI ? 'block' : 'none' }}
    >
      <div className="input-group">
        <div className="input-group__prepend">
          <div className="input-group__addon">
            <Icon
              className="fa-fw text-fv-gray-dark mt-[0.7rem] self-start"
              icon="quote-left"
            />
          </div>
        </div>
        <TextAreaField
          autoFocus={isActiveInUI}
          className="form-control form-control--textarea"
          name={`${loadId}_description`}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            updateLoad({ description: e.target.value })
          }}
          placeholder="What are you shipping?"
          required
          rows={2}
          value={load.description}
        />
      </div>

      <div className="input-group">
        <div className="input-group__prepend">
          <div className="input-group__addon">
            <Icon icon="weight-hanging" className="fa-fw text-fv-gray-dark" />
          </div>
        </div>
        <AmountField
          className="form-control form-control--weight"
          min={1}
          name={`${loadId}_weight`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLoad({ weight: e.target.value })
          }}
          placeholder="Weight"
          required
          value={load.weight}
        />
        <div className="input-group__append">
          <div className="input-group__addon">
            <span>lbs.</span>
          </div>
        </div>
      </div>

      <div className="input-group">
        <div className="input-group__prepend">
          <div className="input-group__addon">
            <Icon icon="dollar-sign" className="fa-fw text-fv-gray-dark" />
          </div>
        </div>
        <AmountField
          className="form-control"
          isPrice
          min={1}
          name={`${loadId}_declaredValue`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLoad({ declaredValue: e.target.value })
          }}
          placeholder="Declared value"
          value={load.declaredValue}
        />
      </div>

      <MultiSelectField
        // @ts-expect-error component not TS
        containerClassName="mb-6"
        isClearable={false}
        isSearchable
        name="alternateTypes"
        onChange={(nextValues: AltEquipmentOption[]) => {
          if (nextValues.length > MAX_ALT_EQUIP) {
            toast.error(`Max of ${MAX_ALT_EQUIP} alternate equipment types.`)
            return
          }

          updateLoad({
            alternateTypes: nextValues.map(v => v.value),
          })
        }}
        options={altEquipmentOptions}
        placeholder="Optional equipment"
        values={altEquipmentOptions.filter(o =>
          load.alternateTypes.includes(o.value),
        )}
      />

      <div className="flex items-center">
        <label htmlFor={`${loadId}_isHazardous`} className="col-auto">
          Hazardous
        </label>
        <div className="flex-pinline flex-1" />
        <div className="col-auto">
          <YesNoInput
            checked={load.isHazardous}
            name={`${loadId}_isHazardous`}
            onChange={e => updateLoad({ isHazardous: e.target.checked })}
          />
        </div>
      </div>

      <EquipmentAccessorialConfig
        accessorialOptions={accessorialOptions}
        accessorials={load.accessorials}
        id={loadId}
        setAccessorials={updateLoad}
      />
    </ValidatedForm>
  )
}
