import clsx from 'clsx'
import { type ReactNode } from 'react'

import { Icon, type IconProps } from '@fv/client-components'

type InfoBoxProps = {
  boxed?: boolean
  children: ReactNode
  className?: string
  custom?: boolean
  gradient?: boolean
  icon?: IconProps['icon']
  iconProps?: Omit<IconProps, 'icon'>
  transform?: string
}

const InfoBox = ({
  boxed = true,
  children,
  className,
  custom = false,
  gradient = true,
  icon = 'hand-paper',
  iconProps,
}: InfoBoxProps) => {
  return (
    <div
      className={clsx('help-box', className, {
        boxed,
        gradient,
        custom,
      })}
    >
      {custom && children}

      {!custom && (
        <>
          <Icon
            icon={icon}
            transform="down-2"
            className="text-fv-orange"
            {...iconProps}
          />
          <p>{children}</p>
        </>
      )}
    </div>
  )
}

export default InfoBox
