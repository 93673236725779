import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'
import { quoteKeys } from '../../hooks/shipments/useQuotes'

interface RevertRerateArgs {
  loadId?: string
}
async function revertRerate({
  loadId,
}: RevertRerateArgs): Promise<FullShipment> {
  if (!loadId) throw new Error('`loadId` required to revert rerate.')

  const endpoint = `${apiUri}/shipments/${loadId}/quotes/revert-rerate`
  const options = buildFetchOptionsWithAuth({ method: 'POST' })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json as FullShipment
  throw response.errorMessage
}

export function useRevertRerate() {
  const queryClient = useQueryClient()
  const updateCachedLoad = useUpdateCachedLoad()
  return useMutation(revertRerate, {
    onSettled(updatedLoad, error) {
      if (error) {
        toast.error(
          'There was an error going back to the previous version of your shipment',
        )
        return
      }

      if (updatedLoad) {
        updateCachedLoad({ updatedLoad })
        setTimeout(() => {
          // without a timeout here, it's possible that we invalidate previous quote versions prior to the old quote version being erased.
          // which would trigger a refetch and potentially still find the quotes that are no longer valid.
          queryClient.invalidateQueries(
            quoteKeys.load(
              updatedLoad.loadId,
              updatedLoad.currentQuoteVersion + 1,
            ),
          )
        }, 5)
      }
    },
  })
}
