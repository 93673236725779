import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import { useFvNavigate } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { ModeNav } from '../../components/ModeNav'
import { EquipmentList } from '../../components/QuoteRequest/EquipmentList'
import { QuoteRequestFooter } from '../../components/QuoteRequest/QuoteRequestFooter'
import { QuoteRequestLocations } from '../../components/QuoteRequest/QuoteRequestLocations'
import { useHomePage } from '../../routes'
import { HasFeature } from '../auth'
import { type QuoteFormParams } from './types'
import { resetQuoteForm, useQuoteFormActions } from './useQuoteFormState'
import { useQuoteRequest } from './useQuoteRequest'

export const QuoteRequestForm = () => {
  const { initializeFromTemplate } = useQuoteFormActions()
  const homePage = useHomePage()
  const { mode, quoteRequestId } = useParams<QuoteFormParams>()
  const isEditing = !!quoteRequestId && mode === 'edit'
  const navigate = useFvNavigate()
  const requestQuery = useQuoteRequest(quoteRequestId, isEditing)

  // Reset state on unmount
  useEffect(() => resetQuoteForm, [])

  // Default form values from template
  useEffect(() => {
    if (!quoteRequestId || !mode) return
    let template: FullShipment[] = []

    if (mode === 'copy') {
      const json = localStorage.getItem('quoteRequestTemplate')
      if (!json) return
      template = JSON.parse(json)
    }

    if (mode === 'edit' && requestQuery.data) {
      template = requestQuery.data.filter(load => load.status === 'pending')
    }

    const templateRequestId = template[0]?.quoteRequestId

    // Mismatching ids or no pending loads on quote request
    if (templateRequestId !== quoteRequestId) {
      if (!requestQuery.isLoading) {
        toast.error('Quote request not found.')
        navigate('/', { replace: true })
      }

      return
    }

    initializeFromTemplate(template, mode)
  }, [
    initializeFromTemplate,
    mode,
    navigate,
    quoteRequestId,
    requestQuery.data,
    requestQuery.isLoading,
  ])

  return (
    <HasFeature name="truckLoad" redirect={homePage}>
      <ModeNav className="b1350:col-span-full" />
      <EquipmentList isEditing={isEditing} />
      <QuoteRequestLocations isEditing={isEditing} />
      <QuoteRequestFooter
        isEditing={isEditing}
        mode={mode}
        quoteRequestId={quoteRequestId}
      />
    </HasFeature>
  )
}
