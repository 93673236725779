import dayjs from 'dayjs'

import { FvLinkButton } from '@fv/client-components'

import { routes } from '../../routes'
import { type Audit } from './types'

type AuditCardHeaderProps = { data: Audit }

// Using zero-width space (&#8203;) to fix bug with double-click select
// https://github.com/freightview/freightview/issues/20350#issuecomment-1031586337
const AuditCardHeader = ({ data }: AuditCardHeaderProps) => {
  const { loadId, pickupDate, quote, refNums, trackingNumber, workflow } = data

  const formattedDate = dayjs.utc(pickupDate).format('ddd MMM D')

  return (
    <p className="audit-card__main-info divided-content divided-content--start divided-content--noflex limit-characters mb-0">
      <FvLinkButton
        theme="underlined"
        className="text-xl"
        rel="noreferrer"
        target="_blank"
        to={routes.details(loadId)}
      >
        {composeLinkText(data)}
      </FvLinkButton>

      {workflow !== 'parcel' && trackingNumber && (
        <span>&#8203;PRO# {trackingNumber}</span>
      )}

      {refNums.map(n => (
        <span key={n.value}>&#8203;Ref# {n.value}</span>
      ))}

      <span>&#8203;{quote.providerName ?? quote.assetCarrierName}</span>
      <span>&#8203;Pickup: {formattedDate}</span>
    </p>
  )
}

const composeLinkText = (audit: Audit) => {
  const { bolNum, trackingNumber, workflow } = audit

  if (workflow === 'parcel' && trackingNumber) {
    return `Tracking# ${trackingNumber}`
  }

  return `BOL# ${bolNum || 'N/A'}`
}

export default AuditCardHeader
