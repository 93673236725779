import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import { FvButton, FvLinkButton, Icon } from '@fv/client-components'

import InfoBox from '../../../components/InfoBox'
import FuelTableRow from './FuelTableRow'
import { useRemoveFuelTable } from './mutations'
import { useFuelTable } from './queries'
import { exportFuelTable } from './utils'

type Props = {
  carrierId: string | null
  className?: string
}

const FuelTable = ({ carrierId, className }: Props) => {
  const fuelTableQuery = useFuelTable(carrierId)
  const removeTable = useRemoveFuelTable(carrierId)

  function deleteFuelTable() {
    if (removeTable.isLoading || !fuelTableQuery.data) return

    const accountLevel = fuelTableQuery.data.carrierId === null
    const fuelTableId = fuelTableQuery.data._id
    const prompt = accountLevel
      ? 'You are about to delete your account-level fuel table. Carriers without their own fuel tables are using this version. Are you sure?'
      : 'You are about to delete the fuel table for this carrier. Are you sure?'

    if (!window.confirm(prompt)) return

    removeTable.mutateAsync({ accountLevel, fuelTableId }).catch(e => {
      toast.error(e.message)
    })
  }

  if (carrierId && fuelTableQuery.data?.carrierId === null) {
    return (
      <p className="mb-6">
        This carrier is currently using your{' '}
        <FvLinkButton theme="underlined" to="./fuel">
          account fuel table
        </FvLinkButton>
        . Below, you can upload a separate fuel table specifically for this
        carrier.
      </p>
    )
  }

  return (
    <div className={className}>
      <div className="mb-2 flex px-4 py-2">
        <div className="w-1/4 text-center">
          <Icon icon="dot-circle" className="text-fv-gray" />
          <span className="ml-1">Lower boundary</span>
        </div>
        <div className="w-1/4 text-center">
          <Icon icon="dot-circle" className="text-fv-gray" />
          <span className="ml-1">Upper boundary</span>
        </div>
        <div className="w-2/12 text-center">
          <Icon icon="dot-circle" className="text-fv-gray" />
          <span className="ml-1">Type</span>
        </div>
        <div className="w-2/12 text-center">
          <Icon icon="dot-circle" className="text-fv-gray" />
          <span className="ml-1">Rate</span>
        </div>
        <div className="w-2/12 text-center">
          <Icon icon="dot-circle" className="text-fv-gray" />
          <span className="ml-1">Currency</span>
        </div>
      </div>

      {fuelTableQuery.isLoading && <InfoBox icon="sync">Loading...</InfoBox>}

      {!fuelTableQuery.isLoading &&
        !fuelTableQuery.data?.rows.length &&
        (fuelTableQuery.error ? (
          <InfoBox icon="exclamation-triangle">
            {(fuelTableQuery.error as any).message}
          </InfoBox>
        ) : (
          <InfoBox icon="gas-pump">Fuel table has not been uploaded.</InfoBox>
        ))}

      {fuelTableQuery.data?.rows.map((row, i) => (
        <FuelTableRow key={i} row={row} />
      ))}

      {fuelTableQuery.data?.rows.length && (
        <>
          <hr className="mt-4" />
          <div className="mb-2 flex justify-end">
            <FvButton
              theme="default"
              onClick={deleteFuelTable}
              type="button"
              icon={removeTable.isLoading ? 'sync' : 'trash'}
              iconClass="text-fv-orange"
            >
              <span>Delete fuel table</span>
            </FvButton>

            <FvButton
              onClick={exportFuelTable}
              type="button"
              theme="default"
              icon="file-export"
            >
              <span>Export fuel table</span>
            </FvButton>
          </div>
        </>
      )}
    </div>
  )
}

export default FuelTable
