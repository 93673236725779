import dayjs from 'dayjs'
import isNumber from 'lodash/isNumber'
import { useMemo, useState } from 'react'

import {
  DelimitedContent,
  FvLinkButton,
  Icon,
  SliderPanel,
  Tooltip,
} from '@fv/client-components'
import { getItemLabels } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { TrackingPanel } from '../../features/shipment-details-panel/TrackingPanel'
import { useTracking } from '../../features/track/queries'
import { useTypeNames } from '../../hooks/settings'
import { type LegItem } from './types'

type UnitIndexDisplayProps = {
  item: LegItem
  load: FullShipment
  index: number
}

export const UnitIndexDisplay = ({
  item,
  load,
  index,
}: UnitIndexDisplayProps) => {
  const tracking = useTracking(load.loadId)
  const { packageName } = useTypeNames()
  const [isSliderOpen, setSliderOpen] = useState(false)

  const label = useMemo(() => {
    const itemDocs = getItemLabels(load.documents, item._id)

    return itemDocs?.find((d, i) =>
      isNumber(d.unitIndex) ? d.unitIndex === index : i === index,
    )
  }, [load.documents, item._id, index])

  const trackSummary = useMemo(() => {
    return tracking?.data?.items?.find(
      d => d.itemId === item._id && d.unitIndex === index,
    )
  }, [tracking?.data, item._id, index])

  const lastUpdatedDate = trackSummary?.lastUpdatedDate
  const summary = trackSummary?.currentLocation?.summary

  return (
    <div className="border-t-fv-gray mt-[.6rem] border-t border-dashed pt-3">
      <div className="flex">
        <div className="ml-2 flex">
          <Icon
            icon="arrow-turn-down-right"
            className="text-fv-gray mr-2"
            transform="down-2 shrink-5"
          />
          <p className="mb-0 pr-4">
            <DelimitedContent delimiter={<> / </>}>
              <>
                {packageName(item.type ?? 'piece')} {index + 1}
              </>
              {!!summary && (
                <>
                  {summary}
                  <span className="ml-1 mt-[.225rem] whitespace-nowrap text-xs">
                    ~ {dayjs.utc(lastUpdatedDate).local().fromNow()}
                  </span>
                </>
              )}
            </DelimitedContent>
          </p>
        </div>
        <div className="ml-auto divide-x whitespace-nowrap">
          {!!label?.url && (
            <Tooltip label="Print this items label">
              <FvLinkButton
                icon="print"
                className="px-2"
                href={label.url}
                target="_blank"
              />
            </Tooltip>
          )}

          {!!summary && (
            <Tooltip label="View this items full tracking">
              <FvLinkButton
                onClick={() => setSliderOpen(true)}
                icon="location-arrow"
                className="px-2"
              />
            </Tooltip>
          )}
        </div>
      </div>
      <SliderPanel
        isOpen={isSliderOpen}
        closePanel={() => setSliderOpen(false)}
      >
        <TrackingPanel
          load={load}
          setActiveSlider={() => null}
          item={{ id: item._id, index }}
        />
      </SliderPanel>
    </div>
  )
}
