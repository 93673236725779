import { createSearchParams } from 'react-router-dom'

import { type AwardChangeReason, type FullShipment } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { useAccountFeatures } from './features/auth'
import { type BookingMode, type ReferrerType } from './features/booking/types'
import { type RatesListingMode } from './features/quote/useRatesListing'
import { useAccountSettings } from './features/settings/account-settings/hooks'
import {
  ltlRecentFilter,
  ltlSavedFitler,
  parcelRecentFilter,
  parcelSavedFilter,
  tlAwardedFilter,
  tlQuotingFilter,
} from './features/shipment-filters/in-process-filters'
import { getFilterQueryString } from './types/SavedFilter'
import { type SettingsPageType } from './constants'

export const routes = {
  book: ({
    loadId,
    quoteId,
  }: Pick<FullShipment, 'loadId'> & { quoteId: string }) => {
    return `/book/${loadId}/${quoteId}`
  },
  bookTruckload: (opts: {
    mode?: BookingMode
    quoteRequestId: string
    reason?: AwardChangeReason | null
    referrer?: ReferrerType
    selectedQuotes: Record<string, string>
  }) => {
    const params = createSearchParams({
      selected: JSON.stringify(opts.selectedQuotes),
      ...(opts.mode && { mode: opts.mode }),
      ...(opts.reason && { reason: opts.reason }),
      ...(opts.referrer && { ref: opts.referrer }),
    })

    return `/pending/${opts.quoteRequestId}?${params}`
  },
  customerRouted: () => '/book/customer-routed',
  details: (loadId: string) => `/details/${loadId}`,
  quote: (
    workflow: Workflow,
    params?: { id: string; mode: 'copy' | 'edit' },
  ) => {
    let path = `/${workflow}/quote`
    if (workflow === 'truckload') path = '/quote-request'
    if (params) path += `/${params.id}/${params.mode}`
    return path
  },
  rates: (
    load: Pick<FullShipment, 'workflow' | 'loadId' | 'quoteRequestId'>,
    mode?: RatesListingMode,
  ) => {
    const { loadId, quoteRequestId, workflow } = load

    return workflow === 'truckload'
      ? `/quote/${quoteRequestId}`
      : [`/rates/${loadId}`, mode].filter(x => x).join('/')
  },
  recentQuotes: (workflow: Workflow) => {
    const filter =
      workflow === 'truckload'
        ? tlQuotingFilter
        : workflow === 'parcel'
          ? parcelRecentFilter
          : ltlRecentFilter
    return `/pending?${getFilterQueryString(filter)}`
  },
  savedQuotes: (workflow: Workflow) => {
    const filter =
      workflow === 'truckload'
        ? tlAwardedFilter
        : workflow === 'parcel'
          ? parcelSavedFilter
          : ltlSavedFitler
    return `/pending?${getFilterQueryString(filter)}`
  },
  settings: (
    page: SettingsPageType,
    mode?: 'add' | 'edit' | 'fuel',
    rateId?: string,
  ) => {
    let path = `/settings/${page}`
    if (mode) path += `/${mode}`
    if (mode && rateId) path += `/${rateId}`
    return path
  },
  contractedRates: (
    mode?: 'add' | 'edit' | 'fuel' | 'list',
    opts?: {
      rateId?: string
      carrierId?: string
    },
  ) => {
    const root = '/settings/contracted-rates'
    if (mode === 'add' || mode === 'fuel') return `${root}/add`
    if (opts?.carrierId && mode === 'list') {
      return `${root}?carrierId=${opts?.carrierId}`
    }
    if (mode === 'edit' && opts?.rateId) {
      return `${root}/edit/${opts?.rateId}`
    }
    return root
  },

  provisioning: (tab: 'requested' | 'active') => {
    return `/provisioning/${tab}`
  },
}

export const useQuotePage = () => {
  const { quoting } = useAccountSettings()
  const { ltl, truckLoad } = useAccountFeatures()

  const quoteWorkflow: Workflow =
    quoting?.defaultWorkflow ??
    (ltl ? 'ltl' : truckLoad ? 'truckload' : 'parcel')

  return routes.quote(quoteWorkflow)
}

export const useHomePage = () => {
  return useQuotePage()
}
