import clsx from 'clsx'

import { FvButton, Icon } from '@fv/client-components'
import {
  addressFuncs,
  composeStopDetails,
  displayLocShipType,
  getLocAccessorials,
} from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { useTypeNames } from '../../hooks/settings/useTypeNames'
import { getApplicableAccessorials } from '../../utils/locationFuncs'
import { List, ListItem } from '../List'

type Props = {
  editOptions?: ['all' | 'origin', (sequence: number) => void]
  numberOfTrucks: number
  load: FullShipment
}

export const RouteSummary = ({ editOptions, numberOfTrucks, load }: Props) => {
  const typeNames = useTypeNames()
  const { locations: stops, workflow, equipment } = load
  // ltl doesn't show equipment accessorials so we throw freezing into destination
  const protectFromFreezing =
    workflow === 'ltl' &&
    equipment.accessorials?.some(a => a.key === 'protect-from-freezing')

  return (
    <List fcp0 lcp0 className="logistics-summary__stops-ctn overflow-auto">
      {numberOfTrucks > 1 && (
        <ListItem>
          <Icon icon="layer-group" className="fa-fw text-fv-gray-dark" />
          <span>Multi-truck shipment ({numberOfTrucks})</span>
        </ListItem>
      )}

      {stops.length > 2 && (
        <ListItem>
          <Icon icon="route" className="fa-fw text-fv-gray-dark" />
          <span>Multi-stop shipment</span>
        </ListItem>
      )}

      {stops.map(location => {
        const { sequence, stopDate, stopDateType, stopType } = location
        const shipType = displayLocShipType(location)
        const isFirstStop = sequence === 0
        const isLastStop = sequence === stops.length - 1
        const accessorials = (
          getLocAccessorials(
            { accessorials: getApplicableAccessorials(location, workflow) },
            typeNames,
          )?.map(a => a.name) ?? []
        ).concat(
          protectFromFreezing && isLastStop ? ['Protect from freezing'] : [],
        )
        const address = addressFuncs.companyAddress(location)
        const canEditDate =
          !!editOptions && (editOptions[0] === 'all' || isFirstStop)

        return (
          <ListItem
            itemsStart
            className="summary-location flex-nowrap"
            key={sequence}
          >
            <Icon
              className={clsx('fa-fw', {
                'text-fv-green': isFirstStop,
                'text-fv-orange': isLastStop,
                'text-fv-beer': !isFirstStop && !isLastStop,
              })}
              icon="map-marker"
              transform="down-2.5"
            />
            <span>
              <p className="mb-0 leading-6">
                {canEditDate && (
                  <FvButton
                    theme="underlined"
                    onClick={() => editOptions[1](sequence)}
                  >
                    {composeStopDetails({ stopDate, stopDateType, stopType })}
                  </FvButton>
                )}
                {!canEditDate &&
                  composeStopDetails({ stopDate, stopDateType, stopType })}{' '}
                at
                <br />
                <span>{` ${address}`}</span>
                {shipType && <span>{` / ${shipType}`}</span>}
                <span>
                  {accessorials.length > 0 &&
                    ` / Accessorials: ${accessorials.join(', ')}`}
                </span>
              </p>
            </span>
          </ListItem>
        )
      })}
    </List>
  )
}
