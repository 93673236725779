import clsx from 'clsx'

import { Icon, type IconProps, Tooltip } from '@fv/client-components'
import { type UIQuote } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { useCurrency, useTypeNames } from '../hooks/settings'
import { type QuoteRequestResponse } from '../hooks/shipments/useQuoteRequestResponses'
import CheckboxField from './inputs/CheckboxField'
import { type ListStatus } from './QuoteRequest/QuoteRequestDetail'

type MethodIcons = {
  api: IconProps['icon']
  email: IconProps['icon']
  manual: IconProps['icon']
  upload: IconProps['icon']
}

type MethodLabels = Record<UIQuote['method'], string>

const methodIcons: MethodIcons = {
  api: 'server',
  email: 'envelope',
  manual: 'user',
  upload: 'money-check-alt',
}

const methodLabels: MethodLabels = {
  api: 'API rate',
  email: 'Email rate',
  manual: 'Manual rate',
  upload: 'Contracted rate',
}

type ResponseRowProps = {
  data: QuoteRequestResponse
  equipmentType: EquipmentType
  filter: ListStatus
  handleChange: () => void
  handleRowClick: () => void
  isActive: boolean
  isAwarded: boolean
  isDisabled: boolean
  isSelected: boolean
}

const ResponseRow = ({
  data,
  equipmentType,
  filter,
  handleChange,
  handleRowClick,
  isActive,
  isAwarded,
  isDisabled,
  isSelected,
}: ResponseRowProps) => {
  const { amount, id, isProcessing, method, name, status } = data
  const { equipmentName: getEquipmentName } = useTypeNames()
  const equipmentName = getEquipmentName(data.equipmentType ?? equipmentType)

  const priceParts = useCurrency()
  const [dollars, cents] = priceParts(amount)

  const showBidInfo = !(
    method === 'api' &&
    (isProcessing || status === 'error')
  )

  return (
    <div
      className={clsx('rates-table__rate', {
        active: isActive,
        // aa mockup
        flash: method === 'manual' && isActive,
        // end aa mockup
      })}
      onClick={handleRowClick}
    >
      <div className="flex-1 overflow-hidden">
        {filter === 'active' && !isProcessing && status !== 'error' && (
          <CheckboxField
            checked={isSelected}
            disabled={isDisabled || !amount}
            label={isAwarded ? `${name} / Awarded` : name}
            name={id}
            onChange={handleChange}
          />
        )}

        {filter === 'active' && isProcessing && (
          <>
            <Icon icon="sync" className="spin fa-fw text-fv-gray-dark" />
            <span>
              {method === 'api' && 'Fetching rates from '}
              {name}
            </span>
          </>
        )}

        {filter === 'active' && status === 'error' && (
          <>
            <Icon
              icon="exclamation-triangle"
              className="fa-fw text-fv-gray-dark"
            />
            <span>
              {name} did not return a rate.{' '}
              <a className="ml-2" href="#" onClick={e => e.preventDefault()}>
                <Icon icon="info-circle" />
                <span>See why</span>
              </a>
            </span>
          </>
        )}

        {filter !== 'active' && name}
      </div>

      {showBidInfo && (
        <div className="rates-table__bid-information">
          <a className="px-4 py-2" href="#" onClick={e => e.preventDefault()}>
            <Icon
              icon="comment-alt-lines"
              className="fa-fw"
              transform="down-.5"
            />
          </a>

          <Tooltip label={method ? methodLabels[method] : ''}>
            <div>
              <Icon
                className="fa-fw text-fv-gray-dark"
                icon={method ? methodIcons[method] : 'envelope'}
              />
            </div>
          </Tooltip>

          <div>{equipmentName}</div>

          {Boolean(amount) && status !== 'error' && (
            <div className="price">
              <span className="price__dollars">${dollars}</span>
              <span className="price__cents">{cents}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ResponseRow
