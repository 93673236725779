import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

type InfoPanelTheme = 'beer'
type Props = PropsWithClassName<
  PropsWithChildren<{
    theme: InfoPanelTheme
    pointer?: 'top' | 'left' | 'bottom' | 'right'
  }>
>
export const InfoPanel = ({ children, theme, className, ...props }: Props) => {
  const pointer = props.pointer && (
    <div
      className={clsx('absolute h-4 w-4 border-l-0 border-t-0', {
        'bg-fv-beer-light border border-gray-300': theme === 'beer',
        'bottom-0 left-1/2 -translate-x-2 translate-y-2 rotate-45':
          props.pointer === 'bottom',
        'left-1/2 top-0 -translate-x-2  -translate-y-2 rotate-[225deg]':
          props.pointer === 'top',
        'left-0 top-1/2 -translate-x-2 -translate-y-2 rotate-[135deg]':
          props.pointer === 'left',
        'right-0 top-1/2 -translate-y-2 translate-x-2 -rotate-45':
          props.pointer === 'right',
      })}
    />
  )
  return (
    <div
      className={clsx(className, 'relative p-4 leading-4', {
        'bg-fv-beer-light border border-gray-300': theme === 'beer',
      })}
    >
      {children}
      {pointer}
    </div>
  )
}
