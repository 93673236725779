import upperFirst from 'lodash/upperFirst'
import { type PropsWithChildren, useState } from 'react'

import { FvLinkButton } from '@fv/client-components'
import { type SlimTrackDetail } from '@fv/client-types'

import { List, ListItem } from '../../components/List'
import { Loading } from '../../components/Loading'
import { supportMessage } from '../../constants'
import { useTracking } from './queries'
import { TrackEvent } from './TrackEvent'
import { type SummarizedTrackingEvent } from './utils'

const MAX_RESULTS = 5

const TrackingHistoryContainer = ({ children }: PropsWithChildren) => (
  <List fcp0 lcp0 className="flex-1">
    {children}
  </List>
)

export type TrackingHistoryProps = {
  loadId: string
  tracking: SlimTrackDetail | undefined
  trackingEvents?: SummarizedTrackingEvent[]
  collapsed?: boolean
  theme?: 'summary' | 'abbreviated' | 'full'
}

export const TrackingHistory = ({
  loadId,
  trackingEvents,
  tracking,
  theme = 'full',
}: TrackingHistoryProps) => {
  const [isShowingAll, setShowingAll] = useState(theme === 'full')
  const enableQuery = trackingEvents === undefined && theme !== 'summary'
  const current = tracking && {
    summary: tracking.currentLocation?.summary,
    timestamp: tracking.lastUpdatedDate,
  }
  const trackingQuery = useTracking(loadId, enableQuery)

  if (enableQuery && trackingQuery.isLoading) {
    return <Loading />
  }

  const showHistory = theme !== 'summary'
  const data = trackingEvents ?? trackingQuery.data?.trackEvents ?? []
  const noAdditionalHistory = (
    <ListItem itemsStart dashed>
      <TrackEvent
        icon={trackingQuery.isError ? 'exclamation-triangle' : 'triangle'}
        iconClassName="text-fv-orange"
        summary={
          trackingQuery.isError
            ? `Unable to load history, ${supportMessage}`
            : 'No additional history available.'
        }
      />
    </ListItem>
  )

  const history =
    data.length > MAX_RESULTS && !isShowingAll
      ? data.slice(0, MAX_RESULTS)
      : data

  return (
    <TrackingHistoryContainer>
      {current && (
        <ListItem itemsStart dashed>
          <TrackEvent
            icon="globe"
            iconClassName="fa-fw text-fv-gray-dark"
            summary={upperFirst(current.summary)}
            timestamp={current.timestamp}
          />
        </ListItem>
      )}
      {showHistory && !data.length && noAdditionalHistory}
      {showHistory &&
        !!data.length &&
        history.map((e, i) => (
          <ListItem key={i} itemsStart dashed>
            <TrackEvent
              iconClassName="text-fv-gray"
              summary={e.summary}
              timestamp={e.timestamp}
            />
          </ListItem>
        ))}

      {showHistory && data.length > MAX_RESULTS && !isShowingAll && (
        <ListItem itemsStart dashed>
          <FvLinkButton
            theme="callout"
            icon="arrow-down"
            iconClass="fa-fw"
            onClick={() => {
              setShowingAll(true)
            }}
          >
            <span>Show full history</span>
          </FvLinkButton>
        </ListItem>
      )}
    </TrackingHistoryContainer>
  )
}
