import { useState } from 'react'

import { usePermissions } from '../auth'
import { MiniQuoteList } from '../mini-quote-list/MiniQuoteList'
import { AddRateButton, ManualRatePanel } from '../quote/ManualRatePanel'
import {
  SliderContentFooter,
  SliderContentHeader,
  SliderContentLayout,
} from './SliderContentLayout'
import { type ShipmentContentProps } from './types'

export const DetailsQuotePanel = ({ load }: ShipmentContentProps) => {
  const [showManualForm, setShowManualForm] = useState(false)
  const { canBook } = usePermissions()
  const { pickup, status } = load
  const isAwarded = status === 'awarded'
  const isBooked = isAwarded || status === 'confirmed'
  const prefix = showManualForm ? 'Manual quote for ' : 'All quotes for '

  const canRetender =
    canBook && (isAwarded || isBooked) && pickup?.method !== 'api'

  return (
    <SliderContentLayout>
      <SliderContentHeader prefix={prefix} {...load} />

      <div className="flex-1">
        {showManualForm ? (
          <ManualRatePanel close={() => setShowManualForm(false)} />
        ) : (
          <MiniQuoteList filter="active" load={load} readOnly={!canRetender} />
        )}
      </div>

      {canRetender && !showManualForm && (
        <SliderContentFooter>
          <AddRateButton
            hasOwnPanel
            icon="plus"
            load={load}
            onClick={() => setShowManualForm(true)}
          >
            Enter rate
          </AddRateButton>
        </SliderContentFooter>
      )}
    </SliderContentLayout>
  )
}
