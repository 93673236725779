import { useMutation, useQueryClient } from '@tanstack/react-query'

import { type UpsertSavedViewDTO } from '@fv/models'

import {
  type UserSettings,
  userSettingsKey,
} from '../../hooks/settings/useUserSettings'
import { shipperFetch } from '../../utils/shipperFetch'

async function updateSavedView({
  pageName,
  queryString,
  viewId,
  viewName,
  systemFilterId,
}: UpsertSavedViewDTO) {
  return shipperFetch<UserSettings['savedViews']>(`/app-settings/views`, {
    method: 'PUT',
    body: {
      pageName,
      queryString,
      viewId,
      viewName,
      systemFilterId,
    },
  })
}

export function useUpdateSavedView() {
  const queryClient = useQueryClient()

  return useMutation(updateSavedView, {
    onSuccess: savedViews => {
      queryClient.setQueryData<UserSettings>(
        userSettingsKey,
        prev =>
          prev && {
            ...prev,
            savedViews: {
              ...prev.savedViews,
              ...savedViews,
            },
          },
      )
    },
  })
}

async function removeSavedView(viewId?: string) {
  if (!viewId) return
  return await shipperFetch<UserSettings['savedViews']>(
    `/app-settings/views/${viewId}`,
    {
      method: 'DELETE',
    },
  )
}

export function useRemoveSavedView() {
  const queryClient = useQueryClient()

  return useMutation(removeSavedView, {
    onSuccess: savedViews => {
      queryClient.setQueryData<UserSettings>(
        userSettingsKey,
        prev =>
          prev && {
            ...prev,
            savedViews: {
              ...prev.savedViews,
              ...savedViews,
            },
          },
      )
    },
  })
}
