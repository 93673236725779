import clsx from 'clsx'
import uniqBy from 'lodash/uniqBy'
import {
  type ComponentType,
  forwardRef,
  type KeyboardEventHandler,
  type RefObject,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  components,
  type ContainerProps,
  type GroupBase,
  type InputProps,
  type MultiValueGenericProps,
  type MultiValueRemoveProps,
  type SelectComponentsConfig,
  type SelectInstance,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { Icon } from '@fv/client-components'
import {
  submitForm,
  validateEmailList,
  validateEmailString,
} from '@fv/client-core'

interface Option {
  readonly label: string
  readonly value: string
}
type IsMulti = true
type Group = GroupBase<Option>
type MySelectInstance = SelectInstance<Option, IsMulti, Group>

export type ValidateAndAddResult = {
  value?: string[]
  inputValue: string
  success: boolean
}
export type ContactListInputHandle = {
  validateAndAddValue: (report: boolean) => ValidateAndAddResult
}

const separatorRegex = /[\s,;]/g

const Input: ComponentType<InputProps<Option, IsMulti, Group>> = props => {
  return (
    <components.Input
      {...props}
      style={{
        background: '0px center',
        flex: '1 0 100%',
        padding: '2px 8px',
      }}
    />
  )
}
const MultiValueLabel: ComponentType<
  MultiValueGenericProps<Option, IsMulti, Group>
> = props => {
  return (
    <components.MultiValueLabel
      {...props}
      innerProps={{
        className: 'bg-fv-beer-light p-1 border-fv-gray border text-sm',
      }}
    />
  )
}
const MultiValueRemove: ComponentType<
  MultiValueRemoveProps<Option, IsMulti, Group>
> = props => {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        ...props.innerProps,
        className:
          'bg-white flex items-center border border-fv-gray border-l-0 p-1',
      }}
    >
      <Icon
        className="text-fv-orange hover:text-fv-gray-dark"
        icon="trash"
        size="xs"
      />
    </components.MultiValueRemove>
  )
}

type MyContainerProps = ContainerProps<Option, IsMulti, Group>
type SelectedValuesProps = Omit<MyContainerProps, 'children' | 'className'> & {
  selectRef: RefObject<MySelectInstance>
}
type SelectedValuesHandle = {
  scrollToBottom: () => void
}
const SelectedValuesContainer = forwardRef<
  SelectedValuesHandle,
  SelectedValuesProps
>(({ isDisabled, getValue, selectRef, ...props }, ref) => {
  const localRef = useRef<HTMLDivElement>(null)
  const { getOptionValue, getOptionLabel, onChange } = props.selectProps

  const focusInput = () =>
    setTimeout(() => selectRef.current?.inputRef?.focus(), 0)

  const handleRemoveClick = (removedValue: Option) => {
    onChange(
      [
        ...getValue().filter(
          v => getOptionValue(v) !== getOptionValue(removedValue),
        ),
      ],
      { action: 'remove-value', removedValue },
    )
    focusInput()
  }
  const scrollToBottom = () => {
    localRef.current?.scrollTo({
      top: localRef?.current.scrollHeight ?? 0,
      behavior: 'smooth',
    })
  }

  useImperativeHandle(ref, () => ({
    scrollToBottom,
  }))

  return (
    <div
      ref={localRef}
      className="flex flex-wrap content-start justify-start overflow-y-auto p-2"
      style={{ height: `calc(100% - 40px)` }}
      onClick={() => focusInput()}
    >
      {getValue().map((opt, index) => (
        <components.MultiValue
          getValue={getValue}
          {...props}
          components={{
            Container: components.MultiValueContainer,
            Label: MultiValueLabel,
            Remove: MultiValueRemove,
          }}
          isDisabled={isDisabled}
          key={`${getOptionValue(opt)}-${index}`}
          index={index}
          data={opt}
          removeProps={{
            onClick: () => handleRemoveClick(opt),
            onTouchEnd: () => handleRemoveClick(opt),
            onMouseDown: e => e.preventDefault(),
          }}
        >
          {getOptionLabel(opt)}
        </components.MultiValue>
      ))}
    </div>
  )
})

const SelectContainerBuilder =
  (
    selectRef: RefObject<MySelectInstance>,
    containerRef: RefObject<SelectedValuesHandle>,
  ) =>
  (props: MyContainerProps) => {
    const { children, className, ...commonProps } = props
    return (
      <components.SelectContainer
        {...props}
        className={clsx(
          className,
          'border-b-fv-blue h-full border border-b-2 bg-white pt-0',
        )}
      >
        <SelectedValuesContainer
          ref={containerRef}
          selectRef={selectRef}
          {...commonProps}
        />
        {children}
      </components.SelectContainer>
    )
  }

const customComponents: SelectComponentsConfig<Option, IsMulti, Group> = {
  DropdownIndicator: null,
  Input,
}

type Props = {
  value: string[]
  onChange: (val: string[]) => void
  options?: string[]
  autoFocus?: boolean
  required?: boolean
}
export const ContactListInput = forwardRef<ContactListInputHandle, Props>(
  ({ value, onChange, options, autoFocus, required }, ref) => {
    const localRef = useRef<MySelectInstance>(null)
    const containerRef = useRef<SelectedValuesHandle>(null)
    const [inputValue, setInputValue] = useState('')
    const optionValues = options?.map(createOption)

    const fireChange = (values: string[]) => {
      onChange(uniqBy(values, v => v.toLowerCase()).map(v => v.toLowerCase()))
    }

    const doScroll = () => {
      setTimeout(() => {
        containerRef.current?.scrollToBottom()
      }, 5)
    }
    const setValidity = (msg: string) => {
      localRef.current?.inputRef?.setCustomValidity(msg)
    }
    const doSubmit = () =>
      submitForm(localRef.current?.inputRef?.closest('form'))
    const reportValidity = (validity: boolean) => {
      setValidity(validity ? '' : 'Please enter a valid email address')
      if (!validity) {
        doSubmit()
      }
    }

    const validateAndAddValue = (report = false): ValidateAndAddResult => {
      let newValue = value
      if (required && !newValue.length && !inputValue && report) {
        setValidity('At least one email address is required')
        doSubmit()
        return { inputValue, success: false, value: newValue }
      }
      if (!inputValue) return { inputValue, success: true, value: newValue }

      const success = validateEmailList(inputValue)
      const cleaned = cleanEmail(inputValue).filter(v => !!v)
      if (success) {
        newValue = [...value, ...cleaned]
        fireChange(newValue)
        setInputValue('')
        doScroll()
      }
      if (report) {
        reportValidity(success)
      }
      return {
        success,
        inputValue: cleaned.join(),
        value: newValue,
      }
    }

    const handleKeyDown: KeyboardEventHandler = event => {
      if (!inputValue) return
      switch (event.key) {
        case ',':
        case ';':
        case ' ':
        case 'Enter':
        case 'Tab': {
          const { success } = validateAndAddValue()
          reportValidity(success)
          if (success) {
            event.preventDefault()
          }
        }
      }
    }

    useImperativeHandle(ref, () => ({
      validateAndAddValue,
    }))

    const SelectContainer = useMemo(() => {
      // have to generate this component to pass the ref to it
      return SelectContainerBuilder(localRef, containerRef)
    }, [localRef])
    return (
      <CreatableSelect
        components={{
          ...customComponents,
          SelectContainer,
        }}
        autoFocus={autoFocus}
        isClearable={false}
        ref={localRef}
        options={optionValues}
        isMulti
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        onChange={(newValue, meta) => {
          fireChange(
            newValue
              .map(o => o.label)
              .filter(v => validateEmailString(v, true)),
          )
          if (
            meta.action === 'create-option' ||
            meta.action === 'select-option'
          ) {
            doScroll()
          }
        }}
        placeholder="e.g. bill@example.com, ted@example.com"
        value={value.map(createOption)}
        isValidNewOption={e => validateEmailString(e, true)}
        formatCreateLabel={e => `Add ${e} to contact list`}
        inputValue={inputValue}
        onInputChange={(newValue, meta) => {
          if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
            setInputValue(newValue)
          }
        }}
        // TODO find way to keep inputValue on select ... would come in handy if you're searching by carrier and want to select
        // currently when you select it closes the menu & clears the search
        // closeMenuOnSelect={false}
        onKeyDown={handleKeyDown}
        {...(!options && {
          menuIsOpen: false,
        })}
        classNames={{
          control: () => 'border-fv-gray',
        }}
        styles={{
          control: base => ({
            ...base,
            borderRadius: 0,
            borderWidth: 0,
            borderTopWidth: 1,
            boxShadow: 'none',
          }),
          container: base => ({
            ...base,
            height: 'calc(100% - 200px)',
          }),
          menu: base => ({
            ...base,
            marginTop: 2,
          }),
          menuList: base => ({
            ...base,
            maxHeight: '200px',
          }),
          placeholder: base => ({
            ...base,
            fontStyle: 'italic',
            fontSize: '.8em',
            color: '#999',
            paddingLeft: '8px',
          }),
          valueContainer: base => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          input: base => ({
            ...base,
            display: 'flex',
            margin: 0,
            padding: 0,
            lineHeight: '1.9rem',
          }),
        }}
      />
    )
  },
)

const createOption = (label: string) => ({
  label,
  value: label,
})

const cleanEmail = (email: string) => email.split(separatorRegex)
