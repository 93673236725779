import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type AccountUserProfileUpdateDto } from '@fv/models'

import {
  appContextQueryKey,
  type AppUserContext,
} from '../../hooks/settings/useAppContext'
import { shipperFetch } from '../../utils/shipperFetch'

const updateUser = async (user: AccountUserProfileUpdateDto) => {
  return shipperFetch<AccountUserProfileUpdateDto>('/user/profile', {
    body: user,
    method: 'PUT',
  })
}

export const useUpdateProfile = (notify?: boolean) => {
  const queryClient = useQueryClient()
  return useMutation(updateUser, {
    onSuccess: result => {
      notify &&
        toast.success('User updated successfully.', {
          id: 'contact-information-update',
        })

      queryClient.setQueryData<AppUserContext>(appContextQueryKey, p => ({
        user: {
          ...p.user,
          ...result,
        },
      }))
      console.log('done')
    },
  })
}
