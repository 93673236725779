import clsx from 'clsx'
import toast from 'react-hot-toast'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

import { ListItem } from '../../components/List'
import { supportMessage } from '../../constants'
import {
  getFilterQueryString,
  isSavedFilterGroup,
  type SavedFilter,
} from '../../types/SavedFilter'
import { useRemoveSavedView } from './mutations'

type SavedViewProps = {
  onEdit: (filter: SavedFilter) => void
  filter: SavedFilter
  isActive: boolean
  isNested?: boolean
  onSelect?: () => void
  onRemoved?: () => void
}

export const FilterItem = ({
  onEdit,
  filter,
  isActive,
  isNested,
  className,
  onSelect,
  onRemoved,
}: PropsWithClassName<SavedViewProps>) => {
  const removeSavedView = useRemoveSavedView()

  const handleDeleteClick = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete the filter named "${filter.name}"?`,
      )
    ) {
      try {
        await removeSavedView.mutateAsync(filter._id)
        onRemoved?.()
        toast.success('Filter removed successfully.')
      } catch {
        toast.error(`Unable to remove filter, ${supportMessage}`)
      }
    }
  }

  return (
    <ListItem
      custom
      className={clsx('standard-list__action !border-fv-blue-150', className, {
        'bg-fv-beer-light': isActive,
      })}
    >
      <FvLinkButton
        className="limit-characters inline-block"
        title={filter.name}
        disabled={filter.isDisabled}
        onClick={() => {
          if (isActive) return
          onSelect?.()
        }}
        fw
      >
        {isNested && (
          <Icon
            icon="arrow-turn-down-right"
            fixedWidth
            className="mr-1 text-gray-400"
            transform="shrink-2"
          />
        )}
        <Icon
          fixedWidth
          icon={filter.icon ?? 'filter'}
          className={clsx({ 'text-inherit': isActive })}
        />
        <span>{filter.name}</span>
      </FvLinkButton>

      {isActive && !filter.isLocked && !isSavedFilterGroup(filter) && (
        <>
          <FvLinkButton
            icon="pen"
            className="filter-edit-action"
            onClick={() => {
              onEdit({
                ...filter,
                queryString: getFilterQueryString(filter),
              })
            }}
          />
          <FvLinkButton
            className="filter-delete-action"
            onClick={handleDeleteClick}
            icon={removeSavedView.isLoading ? 'spinner' : 'trash'}
            iconClass={clsx(!removeSavedView.isLoading && 'text-fv-orange')}
          />
        </>
      )}
    </ListItem>
  )
}
